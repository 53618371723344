//import logo from "./logo.svg";
import { BrowserRouter } from "react-router-dom";
import Routing from "./Routes/routers";
import React, { useEffect, useState } from "react";
import { UserProvider } from "./userContext";
import NoInternet from "./NoInternet";
import { useSelector } from "react-redux";
// access the pre-bundled global API functions
//const { invoke } = window.__TAURI__.tauri;

function App() {
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;

  const userRightsTemp = sessionStorage.getItem("rights");

  let userRightsSession = null;
  if (userRightsTemp !== "undefined") {
    //  console.log(userRightsTemp);
    try {
      userRightsSession =
        sessionStorage.getItem("rights") &&
        JSON.parse(sessionStorage.getItem("rights"));
      // Attempt to parse the rights as JSON
    } catch (error) {
      console.error("Error parsing rights:", error);
    }
  }

  const [userRights, setUserRights] = useState(userRightsSession);
  const [online, setOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    if (userData.token && userData.branch === "CLT") {
      const favicon = document.getElementById("favicon");
      if (favicon) {
        //v=2
        favicon.href = "/favicon_cutis.ico?v=2";
      }
      document.title = "Cutis International";
      const handleOnlineStatus = () => {
        setOnline(window.navigator.onLine);
      };
    }
  }, [userData]);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setOnline(window.navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  return (
    <BrowserRouter>
      <UserProvider>
        {!online && <NoInternet />}
        {online && (
          <Routing userRights={userRights} setUserRights={setUserRights} />
        )}
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
