import { useEffect, useState, React, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import ci from "../../../../../../images/cutis_new.jpeg";
import "../../../../CRM/styles/style.css";
import ReactToPrint from "react-to-print";
import axios from "axios";
import {
  getProcedures,
  getbranchData,
  searchCustomers,
  searchInvoices,
} from "../../../../../../Data/Customer/api";
import PrintBill from "./PrintBill";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import {
  getInventoryItems,
  getInventoryPurchase,
  getInventoryPurchaseRtn,
} from "../../../../../../redux/inventory";
import InventoryForm from "../../InventoryForm";
import { Plus } from "react-feather";

const PurchaseEntry = ({
  purchaseInvoiceFormData,
  selectedRtnDate,
  editInventoryData,
  clearSupplierForm,
  setShowModal,
  togglePurchaseEntry,
  mode,
}) => {
  const auth = useSelector((state) => state.authentication);
  const itemsList = useSelector((state) => state.itemsList);
  const { itemsData, purchaseData, supplierData } = itemsList;
  const dispatch = useDispatch();
  const { userData } = auth;
  let savedToggle = false;
  const token = sessionStorage.getItem("token")
    ? JSON.parse(sessionStorage.getItem("token"))
    : "";
  const [username] = useState(
    sessionStorage.getItem("username")
      ? JSON.parse(sessionStorage.getItem("username"))
      : ""
  );
  const componentRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const date = new Date();
  const [mrn, setMrn] = useState(data && data.otData.mrn);
  const dateNew = new Date();
  const datewithoutHours = new Date(dateNew.setHours(0, 0, 0, 0)).toISOString();
  const time = date.getTime();
  const branch = data ? data.branch : userData.branch;
  //const [payment_mode, setPayment_mode] = useState("cash");
  // const [amount_paid, setAmountPaid] = useState("");
  const invoice_id = `INV-${Date.now()}`;
  const status = "";
  const remarks = "";
  const [buttonHide, setButtonHide] = useState({ display: "normal" });
  const [printVisible, setPrintVisible] = useState(false);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [address, setAddress] = useState("");
  const [addressPrint, setAddressPrint] = useState("");
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [netAmountPay, setNetAmountPay] = useState("");
  const total_amount = total;
  const total_amount_paid = netAmountPay;
  const [procedures, setProcedures] = useState("");
  const [GSTIN, setGSTIN] = useState("");
  const [discount, setDiscount] = useState("");
  const [receivedAmt, setReceivedAmt] = useState("");
  const [balanceAmount, setBalanceAmount] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [formDataPurchase, setFormDataPurchase] = useState(
    purchaseInvoiceFormData
  );
  const [filteredSheets, setFilteredSheets] = useState("");
  const taxOptions = [
    { value: "", label: "" },
    { value: "none", label: "None" },
    { value: "exempted", label: "Exempted" },
    { value: "0", label: "GST @ 0%" },
    { value: "0.1", label: "GST @ 0.1%" },
    { value: "0.25", label: "GST @ 0.25%" },
    { value: "1.5", label: "GST @ 1.5%" },
    { value: "3", label: "GST @ 3%" },
    { value: "5", label: "GST @ 5%" },
    { value: "6", label: "GST @ 6%" },
    { value: "12", label: "GST @ 12%" },
    { value: "13.8", label: "GST @ 13.8%" },
    { value: "18", label: "GST @ 18%" },
    { value: "14_12", label: "GST @ 14% + cess @ 12%" },
    { value: "28", label: "GST @ 28%" },
    { value: "28_12", label: "GST @ 28% + cess @ 12%" },
    { value: "28_60", label: "GST @ 28% + cess @ 60%" },
  ];
  const [invoiceSheet, setInvoiceSheet] = useState([
    {
      Row1: { item: "", quantity: "", unitPrice: 0, tax: 0, mrp: 0 },
    },
    {
      Row2: { item: "", quantity: "", unitPrice: 0, tax: 0, mrp: 0 },
    },
    {
      Row3: { item: "", quantity: "", unitPrice: 0, tax: 0, mrp: 0 },
    },
    {
      Row4: { item: "", quantity: "", unitPrice: 0, tax: 0, mrp: 0 },
    },
    {
      Row5: { item: "", quantity: "", unitPrice: 0, tax: 0, mrp: 0 },
    },
    {
      Row6: { item: "", quantity: "", unitPrice: 0, tax: 0, mrp: 0 },
    },
  ]);
  const [rowIndex, setRowIndex] = useState("");
  const [totalQTY, setTotalQTY] = useState("");
  const [totalTax, setTotalTax] = useState("");
  const [taxableData, setTaxableData] = useState("");
  const invoice_sheet = invoiceSheet;
  const [formData, setFormData] = useState("");
  const [inventoryFormData, setInventoryFormData] = useState("");

  const clearForm = () => {
    setInvoiceSheet([
      {
        Row1: {
          item: "",
          quantity: "",
          unitPrice: 0,
          tax: 0,
          mrp: 0,
        },
      },
      {
        Row2: {
          item: "",
          quantity: "",
          unitPrice: 0,
          tax: 0,
          mrp: 0,
        },
      },
      {
        Row3: {
          item: "",
          quantity: "",
          unitPrice: 0,
          tax: 0,
          mrp: 0,
        },
      },
      {
        Row4: {
          item: "",
          quantity: "",
          unitPrice: 0,
          tax: 0,
          mrp: 0,
        },
      },
      {
        Row5: {
          item: "",
          quantity: "",
          unitPrice: 0,
          tax: 0,
          mrp: 0,
        },
      },
      {
        Row6: {
          item: "",
          quantity: "",
          unitPrice: 0,
          tax: 0,
          mrp: 0,
        },
      },
    ]);
  };

  useEffect(() => {
    if (userData.token) {
      dispatch(
        getInventoryItems({ token: userData.token, branch: userData.branch })
      );
    }
    console.log(supplierData);
  }, [userData.token]);

  useEffect(() => {
    console.log(data);
    // && userData.rights === "Accounts" removed it , check what is the need
    if (data && data.invoiceSheetData) {
      setInvoiceSheet(data.invoiceSheetData);
    }
    if (purchaseInvoiceFormData && purchaseInvoiceFormData.invoice) {
      const convertObjectToArray = (obj) => {
        return Object.values(obj);
      };

      // const invoiceArray = convertObjectToArray(purchaseInvoiceFormData.invoice);
      const invoice = Array.isArray(purchaseInvoiceFormData.invoice)
        ? purchaseInvoiceFormData.invoice
        : convertObjectToArray(purchaseInvoiceFormData.invoice);

      setInvoiceSheet(invoice);

      // for old entries
    } else if (
      purchaseInvoiceFormData?.paymentDetails &&
      purchaseInvoiceFormData.paymentDetails.invoice_id
    ) {
      const itemsSheet = itemsData.filter(
        (item) =>
          item.invoice_id === purchaseInvoiceFormData.paymentDetails.invoice_id
      );

      // const convertObjectToArray = (obj) => {
      //   return Object.keys(obj).map((key, index) => {
      //     return {
      //       [`Row${index + 1}`]: obj[key],
      //     };
      //   });
      // };
    }

    if (editInventoryData) {
      setInvoiceSheet([{ Row1: editInventoryData }]);
    }
  }, [data, purchaseInvoiceFormData?.invoice, editInventoryData]);

  useEffect(() => {
    if (receivedAmt) {
      setBalanceAmount(() =>
        (parseFloat(netAmountPay) - parseFloat(receivedAmt)).toFixed(2)
      );
    }
  }, [receivedAmt]);

  useEffect(() => {
    if (tax || total || discount) {
      // const taxTemp = ((Number(total)) / 100).toFixed(2);
      // const rowTotal = (parseFloat(taxTemp) + parseFloat(total)).toFixed(2);
      const netPay = (parseFloat(total) - parseFloat(discount)).toFixed(2);

      setNetAmountPay(() =>
        !isNaN(netPay) ? netPay : parseFloat(total).toFixed(2)
      );
    }
  }, [tax, total, discount]);

  useEffect(() => {
    if (branch && token) {
      getProcedures(token, branch, navigate).then((res) => {
        setProcedures(res.data);
      });
    }
    const fetchBranchData = async () => {
      if (
        (userData.rights === "Accounts" ||
          userData.rights === "Manager" ||
          userData.rights === "Admin") &&
        token
      ) {
        dispatch(getInventoryItems({ token, branch }));
      }
      if (branch && token) {
        try {
          const res = await getbranchData(token, branch, navigate);

          if (res.length) {
            const { first_line, second_line, third_line, fourth_line } =
              res[0].address;
            const { GSTIN } = res[0];
            setGSTIN(GSTIN);

            setAddressPrint({
              first_line,
              second_line,
              third_line,
              fourth_line,
            });
            setAddress(() => {
              return (
                <div className="ps-3">
                  <h5>{first_line}</h5>
                  <h6>
                    {second_line}, {third_line}
                  </h6>
                  <h6>{fourth_line}</h6>
                </div>
              );
            });
            sessionStorage.setItem("address", JSON.stringify(res));
          }
        } catch (error) {
          console.log(error.message);
        }
      }
    };

    fetchBranchData();
  }, [branch, token]);

  //useEffect(() => {}, []);

  useEffect(() => {
    if (printVisible) {
      setButtonHide({ display: "none" });
    }
    // setButtonHide({ display: "none" });
    // return () => setPrintVisible(false);
  }, [printVisible]);

  const updateInventory = (productId, quantity, failedUpdates) => {
    setShowModal(true);
    const postDataInventory = {
      quantity,
    };
    axios
      .post(
        `${process.env.REACT_APP_CRI_API}update-inventory-qty`,
        postDataInventory,
        {
          params: {
            branch: branch,
            productId: productId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log("Inventory Updated");
          //  setShowModal(false);
          //  dispatch(
          //    getInventoryPurchase({
          //      token: userData.token,
          //      branch: userData.branch,
          //    })
          //  );
          // togglePurchaseEntry();
        }
      })

      .catch((err) => {
        console.log(err);
        failedUpdates.push({ productId, quantity, error: err }); // Collect details of failed updates
      });
  };

  // updates inventory count for exsiting items one by one throuh promises - updateInventory
  const stockHandler = () => {
    const consolidatedQuantities = {};

    // Consolidate quantities for the same productId
    invoiceSheet.forEach((row, rowIndex) => {
      const {
        productId,
        batch,
        quantity = 1,
        stockQty,
      } = row[`Row${rowIndex + 1}`];
      const product_batch = itemsData.find(
        (item) => item.productId === productId && item.batch === batch
      );

      if (product_batch) {
        if (mode === "Return Mode") {
          consolidatedQuantities[productId] = {
            totalQty:
              Number(consolidatedQuantities[productId]?.totalQty || 0) +
              Number(quantity),
            stockQty: product_batch.quantity,
          };
        } else {
          consolidatedQuantities[productId] = {
            totalQty:
              Number(consolidatedQuantities[productId]?.totalQty || 0) +
              Number(quantity),
            stockQty: stockQty,
          };
        }
      }
    });
    const failedUpdates = []; // To track failed updates
    // Prepare promises for updating inventory
    const promises = Object.entries(consolidatedQuantities).map(
      ([productId, { totalQty, stockQty }]) => {
        const updtQty =
          mode === "Return Mode"
            ? Number(stockQty) - Number(totalQty)
            : Number(stockQty) + Number(totalQty);

        return updateInventory(productId, updtQty, failedUpdates);
        //   if (mode === "Return Mode") {
        //     const updtQty = Number(stockQty) - Number(totalQty);
        //     return updateInventory(productId, updtQty);
        //   } else {
        //     const updtQty = Number(stockQty) + Number(totalQty);
        //     return updateInventory(productId, updtQty);
        //   }
      }
    );

    // Execute promises and handle results
    Promise.all(promises)
      .then(() => {
        if (mode === "Return Mode") {
          saveRtnPurchaseHandler(invoiceSheet);
        }
        dispatch(
          getInventoryPurchase({
            token: userData.token,
            branch: userData.branch,
          })
        );
        // Perform any other actions (e.g., submit the invoice)
      })
      .catch((error) => {
        alert("error");
        console.error("Error updating inventory:", error);
      });
  };

  const addInventoryItems = (selectedItemsInvoiceSheet, productId) => {
    return axios.post(
      `${process.env.REACT_APP_CRI_API}add-inventory`,
      selectedItemsInvoiceSheet,
      {
        params: {
          branch: userData.branch,
          productId: productId,
        },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      }
    );
  };

  const inventoryAddHandler = (filteredData) => {
    const { supplier } = purchaseInvoiceFormData;
    const selectedSheets = [];
    const selectedSheetsProdId = [];
    filteredData.forEach((row) => {
      const { productId, batch, item } = row;

      // add sheets only if the productId and batch are not already in the inventory data
      // (it.item === item && it.batch === batch) this is added for old data entry where sheet is not captured productId
      const sheetExists = itemsData.some(
        (it) =>
          (it.productId === productId && it.batch === batch) ||
          (it.item === item && it.batch === batch)
      );

      if (!sheetExists && item) {
        const temp = { ...row, supplierId: supplier.supplierId };
        selectedSheets.push(temp);
      } else if (sheetExists && item) {
        const temp = { ...row, supplierId: supplier.supplierId };
        selectedSheetsProdId.push(temp);
      }
    });

    // creating new item entry into the inventory by creating new productId
    const processInvoices = async (invoices) => {
      const updatedInvoices = [];
      for (let i = 0; i < invoices.length; i++) {
        const invoice = invoices[i];
        try {
          const response = await addInventoryItems(invoice);
          const { productId } = response.data;

          // Update the invoice with the received productId

          invoice.productId = productId;
          //updatedInvoices.push(invoice);
          updatedInvoices[`Row${i + 1}`] = invoice;

          // Send the next request here
          // This will happen automatically as the loop iterates over invoices
        } catch (error) {
          console.error("Error processing invoice:", error);
        }
      }

      // Convert updatedInvoices to an array of objects , back Row structure
      const updatedInvoicesArray = Object.keys(updatedInvoices).map((key) => ({
        [key]: updatedInvoices[key],
      }));

      return updatedInvoicesArray;
    };
    //  console.log(selectedSheetsProdId);
    // sheets with new Item entry - selectedSheets
    // if (selectedSheets.length > 0)
    processInvoices(selectedSheets)
      .then((updatedInvoices) => {
        if (updatedInvoices.length > 0) {
          alert("New Inventory Items Saved ");
        }

        // for merging both sheets
        // Find the last Row number
        let lastRowNumber = 0;
        if (updatedInvoices.length > 0) {
          for (const item of updatedInvoices) {
            const rowNumber = parseInt(Object.keys(item)[0].replace("Row", ""));
            if (rowNumber > lastRowNumber) {
              lastRowNumber = rowNumber;
            }
          }
        }

        // Create new Rows with the filteredData existing products
        const newRowsfilteredData = selectedSheetsProdId.map((data, index) => {
          const newRowNumber = lastRowNumber + index + 1;
          const newRowKey = `Row${newRowNumber}`;
          return { [newRowKey]: data };
        });
        // save purchase invoices
        savePurchaseHandler([...updatedInvoices, ...newRowsfilteredData]);
        dispatch(
          getInventoryItems({
            token: userData.token,
            branch: userData.branch,
          })
        );
        togglePurchaseEntry();
      })
      .catch((error) => {
        console.error("Error processing invoices:", error);
      });
  };

  const inventoryEditHandler = (filteredData) => {
    const selectedSheets = [];

    // Consolidate quantities for the same productId
    filteredData.forEach((row, rowIndex) => {
      const { productId, batch, item } = row;

      // add sheets only if the productId and batch are not already in the purchase data - !sheet
      const sheetExistWithProductId_Batch = itemsData.some(
        (it) => it.item === item && it.batch === batch
      );

      if (sheetExistWithProductId_Batch && item) {
        selectedSheets.push(row);
      }
    });

    // Prepare promises for adding inventory
    const promises = selectedSheets.map((invoice_s) => {
      // previously formData
      return editInventoryHandler(invoice_s);
    });

    // Execute promises and handle results
    Promise.all(promises)
      .then((res) => {
        // console.log("All inventory updated successfully");
        // Perform any other actions (e.g., submit the invoice)

        //   console.log(res);
        if (res) {
          dispatch(
            getInventoryItems({
              token: userData.token,
              branch: userData.branch,
            })
          );
          alert("Edit success");
          //  editPurchaseHandler(filteredData);
          clearForm();
          // clearSupplierForm();
          togglePurchaseEntry();
        }
      })
      .catch((error) => {
        console.error("Error updating inventory:", error);
        alert("Failed adding inventory");
      })
      .finally(() => {
        setShowModal(false);
      });
  };

  const calculateTotal = () => {
    const amountValues = invoiceSheet
      .map((row, index) => row[`Row${index + 1}`]?.amount)
      .filter((i) => parseFloat(i));

    const qtyValues = invoiceSheet
      .map((row, index) => row[`Row${index + 1}`]?.quantity)
      .filter((i) => parseFloat(i));

    const taxValues = invoiceSheet
      .map((row, index) => row[`Row${index + 1}`]?.tax)
      .filter((i) => parseFloat(i));

    const taxableList = invoiceSheet
      .filter((row, index) => row[`Row${index + 1}`]?.tax)
      .map((row) => {
        const key = Object.keys(row)[0];

        return row[key];
        // return parseFloat(row[key].rate) * parseFloat(row[key].qty);
      });
    setTaxableData(taxableList);
    const totalTempTax = taxValues.reduce((acc, currentValue) => {
      const numericValue = parseFloat(currentValue);
      return acc + numericValue;
    }, 0);

    setTotalTax(totalTempTax.toFixed(2));

    const totalTempQty = qtyValues.reduce((acc, currentValue) => {
      const numericValue = parseFloat(currentValue);
      return acc + numericValue;
    }, 0);

    setTotalQTY(totalTempQty);

    //const values = Object.values(inputValues).map((value) => parseFloat(value));
    const totalTempAmt = amountValues.reduce((acc, currentValue) => {
      const numericValue = parseFloat(currentValue);
      return acc + numericValue;
    }, 0);
    //console.log(total);
    setTotal(parseFloat(totalTempAmt).toFixed(2));
    //  return total;
  };

  useEffect(() => {
    if (invoiceSheet) calculateTotal();
  }, [data, invoiceSheet]);

  const handleInputProcedure = (e, rowIndex, colName, row) => {
    const { name, value } = e.target;

    const trimmedValue = value.trim();
    const selectedOption = e.target.list.querySelector(
      `option[value="${trimmedValue}"]`
    );
    let cost = selectedOption ? selectedOption.getAttribute("data-cost") : null;

    const productId = selectedOption
      ? selectedOption.getAttribute("data-productid")
      : null;
    const unitPrice = selectedOption
      ? selectedOption.getAttribute("data-unitprice")
      : null;

    const tax = selectedOption ? selectedOption.getAttribute("data-tax") : null;

    const product = selectedOption
      ? selectedOption.getAttribute("data-product")
      : null;

    const stockQty = selectedOption
      ? selectedOption.getAttribute("data-qty")
      : null;
    const batch = selectedOption
      ? selectedOption.getAttribute("data-batch")
      : null;
    const expiry = selectedOption
      ? selectedOption.getAttribute("data-expiry")
      : null;
    const hsn = selectedOption ? selectedOption.getAttribute("data-hsn") : null;

    //setTaxOption([{ value: taxTemp, label: `${taxTemp} (18%)` }]);

    setInvoiceSheet((prevInvoiceSheet) => {
      return prevInvoiceSheet.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [`Row${rowIndex + 1}`]: {
              ...row[`Row${rowIndex + 1}`],
              [colName]: product,
              mrp: cost,
              unitPrice,
              quantity: product ? 0 : "",
              tax: tax,
              hsn,
              batch,
              productId,
              stockQty,
              expiry,
            },
          };
        }
        return row;
      });
    });
  };

  const handleInputChange = (e, rowIndex, colName, row) => {
    const { name, value } = e.target;

    setInvoiceSheet((prevInvoiceSheet) => {
      return prevInvoiceSheet.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [`Row${rowIndex + 1}`]: {
              ...row[`Row${rowIndex + 1}`],
              [colName]: value,
            },
          };
        }
        return row;
      });
    });
  };
  // 3535 ron bus
  // km 65728 km 28-aug-23
  const qtyStockCheck = (item) => {
    const product = itemsData.find((prod) => prod.productId === item.productId);
    return product.quantity;
  };

  const qtyHandler = (e, index, colName, row) => {
    const value = e.target.value;

    const num = validator.isNumeric(value, {
      no_symbols: false,
    });
    const decimal = validator.isFloat(value, {
      no_symbols: false,
    });
    if (value !== "-" && (decimal || num || value === "")) {
      // const taxTemp = ((Number(e.target.value) * 12) / 100).toFixed(2);
      //   const taxTemp = parseFloat(row[`Row${index + 1}`].tax);
      // const taxTemp = ((Number(e.target.value) * 12) / 100).toFixed(2);
      handleInputChange(e, index, colName);

      setInvoiceSheet((prevInvoiceSheet) => {
        return prevInvoiceSheet.map((row, rindex) => {
          if (rindex === index) {
            return {
              ...row,
              [`Row${index + 1}`]: {
                ...row[`Row${index + 1}`],
                quantity: parseFloat(e.target.value),
              },
            };
          }
          return row;
        });
      });
    } else {
      alert("Enter Numeric Value");
    }
  };

  useEffect(() => {
    // batch, expiry entry from on Click + leads to inventory form
    if (inventoryFormData) {
      setInvoiceSheet((prevInvoiceSheet) => {
        return prevInvoiceSheet.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              [`Row${rowIndex + 1}`]: {
                ...row[`Row${rowIndex + 1}`],
                ...inventoryFormData,
                //  productId,
                //  stockQty,
              },
            };
          }
          return row;
        });
      });
    }
  }, [inventoryFormData]);

  const handlePrintButtonClick = () => {
    //console.log("Button clicked");
    setPrintVisible(true);
  };

  const searchPurchaseHandler = (value) => {
    const invId =
      searchResult &&
      searchResult.find(
        (item) => item.invoice_id.toLowerCase() == value.toLowerCase()
      );

    if (value.length > 5 && !invId) {
      searchInvoices(userData.token, value, userData.branch, navigate).then(
        (res) => {
          setSearchResult(res);
        }
      );
    }
    if (invId) {
      //token, searchInput, branch, navigate
      searchCustomers(
        userData.token,
        invId.mrn,
        userData.branch,
        navigate
      ).then((res) => {
        if (res.length) {
          const [{ fname, sname = "", sex = "", contactno, nationality }] = res;

          if (fname || sname) setDiscount(invId.discount);
          setFormData({
            ...invId,
            patientName: `${fname} ${sname}`,
            sex: sex,
            contactNo: contactno,
            nationality,
          });
        }
      });
      //setMrn(invId.mrn);
      setInvoiceSheet(() => invId.invoice_sheet);
    }
  };
  const handleManualEntry = (e, rindex, field, rowData) => {
    const newValue = e.target.value;
    if (!itemsData.find((item) => item.item === newValue)) {
      // Handle the case of a manually entered value not in itemsData
      setInvoiceSheet((prevInvoiceSheet) => {
        return prevInvoiceSheet.map((row, index) => {
          if (index === rindex) {
            return {
              ...row,
              [`Row${rindex + 1}`]: {
                ...row[`Row${rindex + 1}`],
                item: newValue,
                //   rate: cost,
                //   qty: value ? 1 : "",
                //   tax: value ? taxTemp : "",
                //   amount: value ? rowTotal : "",
                //   productId,
                //   stockQty,
              },
            };
          }
          return row;
        });
      });
    }
  };

  const saveRtnPurchaseHandler = (updatedProductIdInvoiceSheet) => {
    let endPoint = "add-rtn-purchase";
    if (purchaseInvoiceFormData.returnPurchaseId) {
      alert("Edit Not Available");
      return;
      // endPoint = "edit-rtn-purchase";
      // alert("Edit");
      // should work om edit-rtn-purchase, postponed due to time
    }
    const { supplier, paymentDetails, additionalDetails, createdBy } =
      purchaseInvoiceFormData;
    const postPurchaseData = {
      supplier,
      paymentDetails,
      additionalDetails,
      createdBy,
      purchaseId: purchaseInvoiceFormData.purchaseId,
      invoice: updatedProductIdInvoiceSheet,
      //  .filter((row, index) => row[`Row${index + 1}`].item)
      //  .map((row) => {
      //    // Create a new object by filtering out any Row{n} without a valid item
      //    const filteredRow = Object.keys(row)
      //      .filter((key) => row[key]?.item)
      //      .reduce((acc, key, index) => {
      //        acc[`Row${index + 1}`] = row[key];
      //        return acc;
      //      }, {});

      //    return filteredRow;
      //  }),
    };

    // if (!purchaseInvoiceFormData.purchaseId) {
    axios
      .post(`${process.env.REACT_APP_CRI_API}${endPoint}`, postPurchaseData, {
        params: {
          branch: userData.branch,
          purchaseId: purchaseInvoiceFormData.returnPurchaseId,
        },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert("Save Successful");

          dispatch(
            getInventoryPurchaseRtn({
              token: userData.token,
              branch: userData.branch,
            })
          );
          togglePurchaseEntry();
          // savedToggle = true;
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          (err.response &&
            err.response.data &&
            err.response.data.err.name === "TokenExpiredError") ||
          err.response.data.message === "Unauthorized"
        ) {
          // navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          // navigate("/cri-crm");
          return err;
        }
        console.log(err);
        return;
      });
    setShowModal(false);
    return;
    //  }
  };

  const savePurchaseHandler = (updatedProductIdInvoiceSheet) => {
    if (mode !== "Return Mode") {
      const { supplier, paymentDetails, additionalDetails, createdBy } =
        purchaseInvoiceFormData;
      const postPurchaseData = {
        supplier,
        paymentDetails,
        additionalDetails,
        createdBy,
        invoice: updatedProductIdInvoiceSheet,
      };

      if (!purchaseInvoiceFormData.purchaseId) {
        axios
          .post(
            `${process.env.REACT_APP_CRI_API}add-purchase`,
            postPurchaseData,
            {
              params: {
                branch: userData.branch,
              },
              headers: {
                Authorization: `Bearer ${userData.token}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              alert("Save Successful");
              togglePurchaseEntry();
              dispatch(
                getInventoryPurchase({
                  token: userData.token,
                  branch: userData.branch,
                })
              );
              // savedToggle = true;
            }
          })
          .catch((err) => {
            console.log(err);
            if (
              (err.response &&
                err.response.data &&
                err.response.data.err.name === "TokenExpiredError") ||
              err.response.data.message === "Unauthorized"
            ) {
              // navigate("/");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("srchData");
              sessionStorage.removeItem("prescriptionHistory");
              sessionStorage.removeItem("rights");
              // navigate("/cri-crm");
              return err;
            }
            console.log(err);
            return;
          });
        setShowModal(false);
        return;
      }
      if (purchaseInvoiceFormData.purchaseId) {
        // while editing, any new item is entered it will save it into inventory and get product Id into purchase invoice sheet
        editPurchaseHandler(postPurchaseData);
        return;
      }
    }

    if (mode === "Return Mode" && formDataPurchase.invoice.length > 0) {
      return;
    }
  };

  // New save Purchase Invoice , stockHandler is used for updating count for existing Items
  // or new items are entered, both are done through filteredSheets.
  const submitPurchaseHandler = (filteredData) => {
    //const valid = formValid();
    setShowModal(true);
    if (mode === "Return Mode") {
      if (!purchaseInvoiceFormData.additionalDetails.returnReason) {
        alert("Please mention return reason");
        setShowModal(false);
        return;
      }
      if (purchaseInvoiceFormData.returnPurchaseId) {
        alert("Edit No Available");
        setShowModal(false);
        return;
        //  const userResponse = window.confirm(
        //    "Do you want to change the inventory count?"
        //  );
        //  // endPoint = "edit-rtn-purchase";
        //  if (userResponse) {
        //    alert("Editing Inventory");
        //  } else {
        //    setShowModal(false);
        //    return;
        //  }
      }
      stockHandler(filteredData);
      // should prepare sheet entry for return invoices
      setShowModal(false);
      return;
    }
    // should call api with invlice_id as param
    if (editInventoryData) {
      inventoryEditHandler(filteredData);
      // stockHandler(); not updating only count
      setShowModal(false);
      return;
    }
    const invoiceId = purchaseData.some((item) => {
      if (
        item.paymentDetails.invoice_id ===
          purchaseInvoiceFormData.paymentDetails.invoice_id &&
        item.paymentDetails.supplierId ===
          purchaseInvoiceFormData.paymentDetails.supplierId
      ) {
        return (
          item.paymentDetails.invoice_id ===
            purchaseInvoiceFormData.paymentDetails.invoice_id &&
          item.supplier.supplierId ===
            purchaseInvoiceFormData.supplier.supplierId
        );
      }
    });

    // if no purcahesId and no existing invoiceId
    if (!purchaseInvoiceFormData.purchaseId && !invoiceId) {
      // for adding quantity

      // valid invoice sheet - named filtereddata is there then only save inventory
      // for productId
      //, then save purchase with invoice items with productId
      if (filteredData && filteredData.length) {
        inventoryAddHandler(filteredData);
        // stock handler only update stock quantity
        stockHandler();
        dispatch(
          getInventoryPurchase({
            token: userData.token,
            branch: userData.branch,
            invoice_id: purchaseInvoiceFormData.paymentDetails.invoice_id,
          })
        );
      } else {
        alert("Please enter invoice item details +");
        setShowModal(false);
      }
      setShowModal(false);
    } else {
      setShowModal(false);
      alert("Duplicate Invoice Entry");
      if (purchaseInvoiceFormData.purchaseId) {
        if (window.confirm("Are you sure you want to Edit Purchase Invoice?")) {
          // User clicked OK, proceed with the action
          //  inventoryEditHandler(filteredData);

          //  editPurchaseHandler(filteredData);
          // if any items are new
          inventoryAddHandler(filteredData);
          // stockHandler();
        } else {
          // User clicked Cancel, do nothing or show a message
        }
      }
    }
  };

  const purchaseFormValid = () => {
    if (
      purchaseInvoiceFormData &&
      !purchaseInvoiceFormData.supplier.supplierId
    ) {
      alert("Please select supplier test");
      return false;
    }
    if (
      purchaseInvoiceFormData &&
      !purchaseInvoiceFormData.paymentDetails.invoice_date
    ) {
      alert("No Invoice Date selected");
      return false;
    }
    if (
      purchaseInvoiceFormData &&
      !purchaseInvoiceFormData.paymentDetails.invoice_id
    ) {
      alert("Enter Invoice Number");
      return false;
    }
    if (
      purchaseInvoiceFormData &&
      !purchaseInvoiceFormData.paymentDetails.amount_paid
    ) {
      alert("Please enter amount paid");
      return false;
    }

    if (branch && datewithoutHours) {
      if (
        (purchaseInvoiceFormData &&
          purchaseInvoiceFormData.paymentDetails.payment_mode) ||
        mode === "Return Mode"
      ) {
        return true;
      } else {
        alert("Select Payment Mode");
        return false;
      }
    } else {
      alert("Invalid Form");
      return false;
    }
  };

  const invoiceSheetValid = () => {
    let valid = true;
    // Function to check if any value is empty or zero
    const isEmptyOrZero = (value) =>
      value === "" || value === 0 || value === null || value === undefined;
    // qty is NaN
    const isEmptyOrZeroOrNaN = (value) =>
      value === "" || value === 0 || isNaN(Number(value));
    const filteredData = invoiceSheet
      .filter((rowObj) => {
        const rowData = Object.values(rowObj)[0];

        if (rowData.item) {
          if (isEmptyOrZeroOrNaN(rowData.quantity) && !editInventoryData) {
            alert("Item has empty, zero, or NaN qty: " + rowData.item);
            valid = false;
            return false;
          }
          //  console.log(Object.values(rowData));
          const hasAllFields = [
            "batch",
            "expiry",
            "hsn",
            "item",
            "mrp",
            "quantity",
            "tax",
            "unitPrice",
          ].every((field) => rowData[field]);
          if (!hasAllFields && !editInventoryData) {
            alert(
              "Item is missing one or more mandatory fields: " + rowData.item
            );
            valid = false;
            return false;
          }
          return true;
        }
        return false;
      })
      .map((rowObj) => ({
        ...Object.values(rowObj)[0],
        invoice_id: editInventoryData
          ? editInventoryData.invoice_id
          : purchaseInvoiceFormData.paymentDetails.invoice_id,
      })); // Extract object values;

    if (valid) {
      setFilteredSheets(filteredData);
    }
    return { filteredData, valid: valid };
  };

  const handleSubmit = () => {
    // alert("kindly wait, processing ...");
    setShowModal(true);
    // e.preventDefault();

    //   if (formValid()) {
    //     editSubmitHandler();
    //     return;
    //   }
    //checking mandatory fields are valid
    const { filteredData, valid } = invoiceSheetValid();
    // saving purchase based on either edit Inventory or new Invoice is valid, purchaseFormValid - supplier details valid
    if ((editInventoryData || purchaseFormValid()) && valid) {
      submitPurchaseHandler(filteredData);

      return;
    } else {
      setShowModal(false);
      alert("Invalid Invoice");
      return;
    }
  };

  const editPurchaseHandler = (filteredData) => {
    if (
      purchaseInvoiceFormData.purchaseId &&
      purchaseInvoiceFormData.paymentDetails.invoice_id
    ) {
      const { supplier, paymentDetails, additionalDetails, createdBy } =
        purchaseInvoiceFormData;
      const postPurchaseData = {
        supplier,
        paymentDetails,
        additionalDetails,
        createdBy,
        invoice: invoiceSheet,
      };

      axios
        .post(
          `${process.env.REACT_APP_CRI_API}edit-purchase`,
          postPurchaseData,
          {
            params: {
              branch: userData.branch,
              purchaseId: purchaseInvoiceFormData.purchaseId,
            },
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            //  inventoryAddHandler(filteredData.invoice);

            alert("Edit success");

            // clearForm();
          }
        })
        .catch((err) => console.log(err));
    } else {
      // submitPurchaseHandler(filteredData);
    }
  };

  // since return is kind of edit and save as a new entry in db , these end point is accessed from here
  const editInventoryHandler = (selectedItemsInvoiceSheet) => {
    const endpoint = "edit-inventory";

    return axios.post(
      `${process.env.REACT_APP_CRI_API}${endpoint}`,
      selectedItemsInvoiceSheet,
      {
        params: {
          branch: userData.branch,
          productId: selectedItemsInvoiceSheet.productId,
        },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      }
    );
  };

  return (
    <Container className="pb-0">
      <Table bordered>
        <thead>
          <tr>
            <th
              style={{
                //   backgroundColor: "#8b0f7a",
                width: "5%",
                textAlign: "center",
                border: "none",
                // color: "white",
              }}
            >
              Sl No.
            </th>
            <th
              style={{
                width: "25%",
                textAlign: "center",
                border: "none",
                // color: "white",
                // backgroundColor: "#8b0f7a",
              }}
            >
              ITEMS/SERVICES
            </th>
            <th
              style={{
                width: "10%",
                textAlign: "center",
                border: "none",
                // color: "white",
                //    backgroundColor: "#8b0f7a",
              }}
            >
              HSN
            </th>
            {
              <th
                style={{
                  width: "10%",
                  textAlign: "center",
                  border: "none",
                  // color: "white",
                  //    backgroundColor: "#8b0f7a",
                }}
              >
                QTY
              </th>
            }
            <th
              style={{
                width: "10%",
                textAlign: "center",
                border: "none",
                // color: "white",
                // backgroundColor: "#8b0f7a",
              }}
            >
              Unit Price
            </th>
            {
              <th
                style={{
                  width: "20%",
                  textAlign: "center",
                  border: "none",
                  //color: "white",
                  //  backgroundColor: "#8b0f7a",
                }}
              >
                TAX
              </th>
            }
            {
              <th
                style={{
                  width: "10%",
                  border: "none",
                  // color: "white",
                  //   backgroundColor: "#8b0f7a",
                  textAlign: "center",
                }}
              >
                {" "}
                MRP
              </th>
            }

            <th style={{ width: "5%" }}>Add</th>
          </tr>
        </thead>
        <tbody>
          {invoiceSheet.map((row, index) => {
            return (
              <tr key={index}>
                <td
                  style={{
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {row[`Row${index + 1}`]?.item && index + 1}
                </td>
                <td>
                  {
                    <Input
                      type="text"
                      list="data"
                      id="Row1item"
                      autoComplete="off"
                      //  readOnly={MRN !== ""}
                      value={row[`Row${index + 1}`]?.item}
                      onChange={(e) => {
                        handleInputProcedure(e, index, "item", row);
                      }}
                      onBlur={(e) => {
                        handleManualEntry(e, index, "item", row);
                      }}
                    />
                  }
                  {
                    <datalist id="data" style={{ width: "100%" }}>
                      {itemsData.length &&
                        itemsData
                          .filter(
                            (item, index, self) =>
                              index ===
                              self.findIndex((t) => t.item === item.item)
                          )
                          .map((item, id) => {
                            // dont remove this  value={`${item.item} ${item.productId}`} or it will effect item selection conflict
                            return (
                              <option
                                key={id}
                                value={`${item.item} ${item.quantity}`}
                                data-unitprice={item.unitPrice}
                                data-cost={item.mrp}
                                data-tax={item.tax}
                                data-productid={item.productId}
                                data-product={item.item}
                                data-qty={item.quantity}
                                data-batch={item.batch}
                                data-hsn={item.hsn}
                                data-expiry={item.expiry}
                              />
                            );
                          })}
                    </datalist>
                  }
                </td>
                <td>
                  <Input
                    id="Row1amount"
                    type="text"
                    name="amount"
                    value={
                      row[`Row${index + 1}`]?.hsn
                        ? row[`Row${index + 1}`].hsn
                        : ""
                    }
                    onChange={(e) => handleInputChange(e, index, "hsn")}
                  />
                </td>

                {
                  <td>
                    <Input
                      style={{ textAlign: "center" }}
                      id="Row1qty"
                      type="number"
                      min="0"
                      name="quantity"
                      value={
                        row[`Row${index + 1}`]?.quantity
                          ? row[`Row${index + 1}`].quantity
                          : ""
                      }
                      onChange={(e) => {
                        qtyHandler(e, index, "quantity", row);
                      }}
                    />
                  </td>
                }
                <td>
                  <Input
                    id="Row1rate"
                    type="text"
                    name="input1"
                    value={
                      row[`Row${index + 1}`]?.unitPrice
                        ? row[`Row${index + 1}`].unitPrice
                        : ""
                    }
                    onChange={(e) =>
                      handleInputChange(e, index, "unitPrice", row)
                    }
                  />
                </td>

                <td>
                  <Input
                    type="select"
                    name="tax"
                    id="taxInput"
                    value={
                      row[`Row${index + 1}`]?.tax
                        ? row[`Row${index + 1}`].tax
                        : ""
                    }
                    onChange={(e) => handleInputChange(e, index, "tax", row)}
                  >
                    {taxOptions.map((option, id) => (
                      <option key={id} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Input>
                </td>
                {
                  <td>
                    <Input
                      id="Row1amount"
                      type="text"
                      name="mrp"
                      value={
                        row[`Row${index + 1}`]?.mrp
                          ? row[`Row${index + 1}`].mrp
                          : ""
                      }
                      onChange={(e) => handleInputChange(e, index, "mrp")}
                    />
                  </td>
                }
                <td
                  onClick={() => {
                    const item = invoiceSheet.find(
                      () =>
                        row[`Row${index + 1}`].productId ===
                        itemsData.find(
                          (item) =>
                            item.productId === row[`Row${index + 1}`].productId
                        )?.productId
                    );

                    setRowIndex(index);
                    if (row[`Row${index + 1}`].item) {
                      setFormData(row[`Row${index + 1}`]);

                      toggle();
                      return;
                    }
                    alert("Please Enter Item");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Plus />
                </td>
              </tr>
            );
          })}

          <tr scope="row">
            <td colSpan={1}></td>

            <td
              style={{
                textAlign: "end",
                fontWeight: "bold",
                paddingTop: "15px",
              }}
            >
              Total
            </td>
            <td></td>

            <td style={{ textAlign: "center" }}>{totalQTY}</td>
            <td></td>
            <td></td>
            <td style={{ textAlign: "center" }}>{}</td>
            <td> {}</td>
          </tr>
          <tr>
            <td></td>
            <td
              colSpan={8}
              onClick={() => {
                const newIndex = invoiceSheet.length;
                const newRow = {
                  [`Row${newIndex + 1}`]: {
                    item: "",
                    quantity: "",
                    unitPrice: 0,
                    tax: 0,
                    mrp: 0,
                    hsn: "",
                  },
                };
                setInvoiceSheet([...invoiceSheet, newRow]);
              }}
              style={{ cursor: "pointer" }}
            >
              {mode !== "Return Mode" && <Plus />}
            </td>
          </tr>
          <tr></tr>
        </tbody>
      </Table>
      <Row>
        <Col className="d-flex justify-content-end">
          <Col sm={1}>
            {mode === "Return Mode" && (
              <ReactToPrint
                trigger={() => <button className="glass-button">Print</button>}
                content={() => componentRef.current}
              />
            )}
          </Col>

          <Button
            color="primary"
            onClick={() => {
              const x = window.confirm("Confirm to cancel the invoice entry");
              if (x === true) {
                clearForm();
              }
            }}
            className="me-2"
          >
            Clear
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            {editInventoryData
              ? "Update Inventory"
              : mode === "Return Mode"
              ? "Save Return"
              : "Save Purchase"}
          </Button>
        </Col>
      </Row>
      {
        //Inventory form starts with inventory batch, expiry etc.. acces by clicking +
      }
      <Modal isOpen={modal} toggle={toggle} style={{ minWidth: "800px" }}>
        <ModalHeader toggle={toggle}>Add Inventory</ModalHeader>

        <ModalBody>
          <InventoryForm
            formDataPurchase={formData}
            rowIndex={rowIndex}
            setInventoryFormData={setInventoryFormData}
            toggle={toggle}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <div style={{ display: "none" }}>
        <PrintBill
          ref={componentRef}
          data={purchaseInvoiceFormData}
          address={addressPrint}
          invoice_id={purchaseInvoiceFormData?.paymentDetails?.invoice_id}
          date={date}
          invoiceSheet={invoiceSheet}
          //     total={total}
          //     payment_mode={payments.map((payment) => payment.mode).join(", ")}
          //     branch={branch}
          GSTIN={GSTIN}
          //     formData={formData}
          supplier={supplierData.find(
            (sup) =>
              sup.supplierId === purchaseInvoiceFormData?.supplier?.supplierId
          )}
        />
      </div>
    </Container>
  );
};

export default PurchaseEntry;
