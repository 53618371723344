export const getCurrentISTTime = async () => {
  const dateIST = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  const timeIST = new Date(dateIST);
  const hours = timeIST.getHours();
  const minutes = timeIST.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert midnight (0 hours) to 12 AM
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${period}`;
};
