import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PaginatedList = ({
  setPaginatedList,
  searchInput,
  setShowSpinner,
  endPoint,
  endPoint2,
  limit = 100,
  setPurchasePaginatedList,
  purchaseLimit,
  refresh,
}) => {
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;
  // const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  // const [limit] = useState(100);

  // currentPage, pageSize
  const fetchData = async (page, startDate, endDate) => {
    setShowSpinner(true); //inventory-page
    try {
      if (endPoint) {
        const response = await axios.get(
          `${process.env.REACT_APP_CRI_API}${endPoint}`,
          {
            params: {
              branch: userData.branch,
              invoice_id: "",
              page,
              limit,
              searchInput,
              startDate,
              endDate,
            },
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          }
        );
        setPaginatedList(response.data.items);
        // setData(response.data.items);
        setTotalPages(response.data.totalPages);
      }

      // Fetch purchase invoices

      if (endPoint2) {
        const response = await axios.get(
          `${process.env.REACT_APP_CRI_API}${endPoint2}`,
          {
            params: {
              branch: userData.branch,
              invoice_id: "",
              page,
              limit: purchaseLimit,
              searchInput,
              startDate,
              endDate,
            },
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          }
        );
        if (!endPoint) {
          // console.log(response.data.totalPages);
          setTotalPages(response.data.totalPages);
        }
        setPurchasePaginatedList(response.data.items);
      }
      setShowSpinner(false);
    } catch (err) {
      console.error("Error fetching data:", err);

      setShowSpinner(false);
      if (
        err.response &&
        err.response.data &&
        (err.response.data.err?.name === "TokenExpiredError" ||
          err.response.data.message === "Unauthorized")
      ) {
        // alert("Login Again to Continue");
        navigate("/");

        sessionStorage.removeItem("token");
        sessionStorage.removeItem("srchData");
        sessionStorage.removeItem("prescriptionHistory");
        sessionStorage.removeItem("rights");
        navigate("/cri-crm");
      }
      return;
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, searchInput, refresh]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const maxVisiblePages = 4;
  // Calculate the range of visible pages
  let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
  let endPage = startPage + maxVisiblePages - 1;

  // Adjust if `endPage` exceeds total pages
  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(totalPages - maxVisiblePages + 1, 1);
  }

  // Generate visible page numbers
  const visiblePages = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );
  return (
    <div>
      <Pagination aria-label="Page navigation example">
        {/* First Page */}
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink first onClick={() => handlePageChange(1)} />
        </PaginationItem>

        {/* Previous Page */}
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            onClick={() => handlePageChange(currentPage - 1)}
          />
        </PaginationItem>

        {/* First Ellipsis */}
        {startPage > 1 && (
          <PaginationItem disabled>
            <PaginationLink>...</PaginationLink>
          </PaginationItem>
        )}

        {/* Visible Pages */}
        {visiblePages.map((page) => (
          <PaginationItem key={page} active={page === currentPage}>
            <PaginationLink onClick={() => handlePageChange(page)}>
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}

        {/* Last Ellipsis */}
        {endPage < totalPages && (
          <>
            {endPage + 1 < totalPages && (
              <PaginationItem disabled>
                <PaginationLink>...</PaginationLink>
              </PaginationItem>
            )}
            <PaginationItem active={currentPage === totalPages}>
              <PaginationLink onClick={() => handlePageChange(totalPages)}>
                {totalPages}
              </PaginationLink>
            </PaginationItem>
          </>
        )}

        {/* Next Page */}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink
            next
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </PaginationItem>

        {/* Last Page */}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink last onClick={() => handlePageChange(totalPages)} />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default PaginatedList;
