import axios from "axios";
import { useEffect, useState, useRef } from "react";
//import Calendar from "react-calendar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
  ToastHeader,
} from "reactstrap";
import {
  getAppointments,
  getDoctors,
  searchCustomers,
} from "../../../../Data/Customer/api";
//import Appointments from "./Appointments";
import { Search } from "react-feather";
import { getCurrentISTTime } from "./currentTime";
import { getUserRights } from "../../CRM-Calicut/utils/userRights";
import { useSelector } from "react-redux";

const Booking = () => {
  let savedToggle = false;
  const [userRights, setUserRights] = useState("");
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;
  //const [advancePayment, setAdvancePayment] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  //const [deleteResponseModal, setDeleteResponseModal] = useState(false);
  const location = useLocation();
  const data = location.state;
  const [selectedDate, setSelectedDate] = useState(new Date());

  //const [selectedCmData, setSelectedCmData] = useState([]);
  const nDate = new Date(selectedDate);
  const datewithoutHours = new Date(nDate.setHours(0, 0, 0, 0)).toISOString();
  const today = new Date();
  //const utcDate = selectedDate.toISOString(); // saving pupose
  const startDay = new Date(selectedDate);
  //startDay.setUTHours(0, 0, 0, 0);
  const date = new Date(selectedDate);
  //const startDayUTC = new Date(startDay.setUTCHours(0, 0, 0, 0)).toISOString(); // searching purpose
  // startDayUTC.setUTCHours(0, 0, 0, 0);
  //  const [utcDateString, setUTCDateString] = useState(startDay.toISOString());

  // const istOffset = 5.5 * 60 * 60 * 1000; // UTC offset for IST in milliseconds
  //const istDate = new Date(date.getTime() + istOffset);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");
  //const [saved, setSaved] = useState(false);
  const [appointmentData, setAppointmentData] = useState("");
  const [appointmentDataFilter, setAppointmentDataFilter] = useState("");
  const token = JSON.parse(sessionStorage.getItem("token"));
  const username = JSON.parse(sessionStorage.getItem("username"));
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setisSaving] = useState(false);
  // const [fname, setFname] = useState("");
  //const [sname, setSname] = useState("");
  const [newPatientFName, setNewPatientFName] = useState("");
  const [newPatientSName, setNewPatientSName] = useState("");
  const [newPatientMobile, setNewPatientMobile] = useState("");
  const [filteredTimeOptions, setFilteredTimeOptions] = useState([]);
  const [doctorsData, setDoctorsData] = useState(
    JSON.parse(localStorage.getItem("doctorsData"))
      ? JSON.parse(localStorage.getItem("doctorsData"))
      : ""
  );
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [customerSearchInput, setCustomerSearchInput] = useState("");
  //const [tempMRN, setTempMRN] = useState("");
  const [mrn, setMRN] = useState(`${Date.now()}`);
  //const [contactNo, setContactNo] = useState("");
  const visitTypeOptions = ["First Visit", "Follow Up", "Re Visit"];
  const [optionsDepartment, setOptionsDepartment] = useState("");
  const [edit, setEdit] = useState(false);
  const [_id, setId] = useState("");
  const [selectedNextTime, setSelectedNextTime] = useState("");
  const [bookedSlots, setBookedSlots] = useState("");
  const [selectedVisitType, setSelectedVisitType] = useState(
    visitTypeOptions[0]
  );
  const [reasonforVisit, setReasonforVisit] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const [additional, setAdditional] = useState({
    advancePayment: "",
    status: "",
  });

  const reasonforvisitOptions = [
    "Dermatology Consultaion",
    "Cosmetology Consultaion",
    "Hair Problems",
    "Weight Loss / Liposuction",
    "Hair Transplantation",
  ];

  // Create a new Date object for the current date and time in IST
  const currentDateIST = new Date(selectedDate);
  // Set the time components of the current date to represent the start of the day in IST
  currentDateIST.setHours(0, 0, 0, 0);

  // Get the ISO 8601 string representation of the start of the day in IST
  const startOfDayIST = currentDateIST.toISOString();

  // Log the resulting start of day strings to the console
  const verifyDateHandler = () => {
    if (isTodayGrt(selectedDate)) {
      alert("Please select future date");

      return true;
    }
    //  alert("Appointment already taken on the same date");
    const bookingDate = new Date(selectedDate);

    if (appointmentDataFilter.length) {
      // next to do , there are multiple dates and it is not correct--> appointmentDataFilter[0].appointmentdate
      // hence filter matching dates first and find out whether the selected date is matched
      const appointmentDates = appointmentDataFilter.filter((item) => {
        const appointmentDate = new Date(item.appointmentdate);
        return (
          bookingDate.getDate() === appointmentDate.getDate() &&
          bookingDate.getMonth() === appointmentDate.getMonth() &&
          bookingDate.getFullYear() === appointmentDate.getFullYear()
        );
      });

      //   if (appointmentDates.length) {
      //  alert("Appointment already taken on the same date");
      //    return true;
      //    }
    }
    return false;
  };
  // const formatTime = (time24Hour) => {
  //   const [hours, minutes] = time24Hour.split(":");
  //
  //   // Convert hours to 12-hour format
  //   let hours12 = parseInt(hours, 10);
  //   const ampm = hours12 >= 12 ? "PM" : "AM";
  //   hours12 %= 12;
  //   hours12 = hours12 || 12; // Handle midnight (0 hours)
  //
  //   // Format the time in 12-hour format
  //   const time12Hour = `${hours12}:${minutes} ${ampm}`;
  //   return time12Hour;
  // };

  const formatDate = (date) => {
    const ISTDate = new Date(
      date.toLocaleString("en-US", {
        timeZone: "Asia/Kolkata", // Indian Standard Time (IST)
        hour12: true, // Use 12-hour format
      })
    );

    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(ISTDate);
  };

  //const Today = formatDate(new Date().toISOString());
  const branch = sessionStorage.getItem("branch")
    ? JSON.parse(sessionStorage.getItem("branch"))
    : "";

  //const datefix = (selectedD) => {
  //  // Original UTC date string
  //  const originalUtcDateString = new Date(selectedD).toISOString();

  //  // Hours from another date (e.g., '2024-01-27T18:30:00.000Z')
  //  const otherDateHours = new Date().getUTCHours();

  //  // Convert original UTC date string to Date object
  //  const originalUtcDate = new Date(originalUtcDateString);

  //  // Create a new Date object with the same year, month, day, and time components as the original date,
  //  // but with the hours replaced by the hours from the other date
  //  const newDateWithReplacedHours = new Date(
  //    originalUtcDate.getUTCFullYear(),
  //    originalUtcDate.getUTCMonth(),
  //    originalUtcDate.getUTCDate(),
  //    otherDateHours, // Replace with hours from the other date
  //    originalUtcDate.getUTCMinutes(),
  //    originalUtcDate.getUTCSeconds(),
  //    originalUtcDate.getUTCMilliseconds()
  //  );

  //  return newDateWithReplacedHours;
  //};

  const generateTimeOptions = () => {
    const options = [];
    let startHour = 8;
    let endHour = 20;

    if (selectedDoctor === "Dr. Saranya") {
      startHour = 10;
      endHour = 17;
    }
    if (selectedDoctor === "Dr. Suraja") {
      startHour = 8;
      endHour = 22;
    }
    if (selectedDoctor === "Dr. Gokul") {
      startHour = 11;
      endHour = 20;
    }

    for (let hour = startHour; hour <= endHour; hour++) {
      // Start from 8 AM, end at 5 PM
      for (let minute = 0; minute < 60; minute += 15) {
        // Increment by 15 minutes
        const period = hour >= 12 ? "PM" : "AM";
        const formattedHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour; // Convert 24-hour format to 12-hour format
        const formattedMinute = minute.toString().padStart(2, "0"); // Ensure minutes are always 2 digits
        const time = `${formattedHour}:${formattedMinute} ${period}`;
        if (hour === endHour && minute >= 15) break; // Stop at 5:00 PM
        options.push(time);
      }
    }

    return options;
  };

  //startHour.split(" ");
  const timeOptions = generateTimeOptions();
  function convertTo24HourFormat(time) {
    const [hour, minute] = time.split(" ")[0].split(":");
    const period = time.split(" ")[1];
    let hour24 = parseInt(hour, 10);
    if (period === "PM" && hour24 < 12) {
      hour24 += 12;
    }
    return `${hour24.toString().padStart(2, "0")}:${minute}`;
  }

  const fdate = new Date(selectedDate);
  const ISTOffset = 330; // IST offset in minutes
  const ISTTime = new Date(fdate.getTime() + ISTOffset * 60000); // IST time
  const formattedDate = ISTTime.toISOString().split("T")[0];

  const getAppointmentsHandler = () => {
    setIsLoading(true);
    getAppointments(
      token,
      datewithoutHours,
      branch,
      "",
      selectedDoctor,
      selectedDepartment,
      navigate
    )
      .then((res) => {
        setIsLoading(false);
        // console.log(res);
        setAppointmentData(res);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (doctorsData && doctorsData.length && selectedDoctor) {
      if (selectedDoctor) {
        const optDepartment = doctorsData
          .map((dep) => {
            if (dep.doctorName === selectedDoctor) {
              return dep;
            }
          })
          .filter((item) => item);
        if (optDepartment && optDepartment.length === 1)
          setSelectedDepartment(optDepartment[0].department);
        // setAppointmentData(() => "");
        setOptionsDepartment(() => optDepartment);
      }
    }

    //console.log(userRights);
  }, [doctorsData, selectedDoctor]);
  useEffect(() => {
    if (selectedDoctor && selectedDate) {
      getAppointmentsHandler();
    }
  }, [selectedDoctor, selectedDate, selectedDepartment]);
  useEffect(() => {
    if (selectedAppointment) {
      setNewPatientFName(selectedAppointment.fname);
      setNewPatientSName(selectedAppointment.sname);
      setMRN(selectedAppointment.mrn);
      setNewPatientMobile(selectedAppointment.contactno);
      setSelectedVisitType(selectedAppointment.visitType);
      setReasonforVisit(selectedAppointment.visitReason);
    }
  }, [selectedAppointment]);

  const searchHandler = () => {
    if (selectedDoctor) {
      getAppointmentsHandler();
    } else {
      alert("Please select doctor");
    }

    if (customerSearchInput) {
      searchCustomers(token, customerSearchInput, branch, navigate).then(
        (res) => {
          if (res.length > 0) {
            //  setSelectedCmData(res);
            setNewPatientFName(res[0].fname);
            setNewPatientSName(res[0].sname);
            setMRN(res[0].mrn);
            setNewPatientMobile(res[0].contactno);
          } else {
            alert("Patient not registered");
          }
        }
      );
    }
  };

  useEffect(() => {
    if (!doctorsData.length && branch && token) {
      const fetchDoctorData = async () => {
        if (branch && token) {
          try {
            // or const respone = await getbranchData(branch);

            getDoctors(token, branch, navigate).then((res) => {
              setDoctorsData(res);
              localStorage.setItem("doctorsData", JSON.stringify(res));
            });
          } catch (error) {
            console.log(error.message);
          }
        }
      };

      fetchDoctorData();
    }

    if (appointmentData && data && data.otData) {
      const appDataFilter = appointmentData.filter(
        (item) => item.mrn === data.otData.mrn
      );
      setMRN(data.otData.mrn);
      setNewPatientFName(data.otData.fname);
      setNewPatientSName(data.otData.sname);
      setNewPatientMobile(data.otData.contactno);
      //   setAppointmentDataFilter(appDataFilter);

      //  verifyDateHandler();
    }
    // console.log(data);
    getUserRights().then((res) => {
      setUserRights(res);
      if (res === "Doctor") {
        if (username) setSelectedDoctor(userData.user);
        return;
      }
    });
    //console.log(appointmentData);
  }, [appointmentData, data]);

  const clearForm = () => {
    setMRN(() => `Tmp-${Date.now()}`);
    setNewPatientFName("");
    setNewPatientSName("");
    setNewPatientMobile("");
  };

  // const tileDisabled = ({ activeStartDate, date, view }) => {
  //   const newd = new Date();
  //   return date.setHours(0, 0, 0, 0) < newd.setHours(0, 0, 0, 0);
  // };

  const isTodayGrt = (date) => {
    const today = new Date();
    const selDate = new Date(date);

    if (selDate.getDate() < today.getDate()) {
      if (selDate.getMonth() === today.getMonth()) {
        return true;
      }
    }
    return (
      selDate.getMonth() < today.getMonth() &&
      selDate.getFullYear() < today.getFullYear()
    );
  };

  const isBookingAllowed = () => {
    if (
      appointmentData.some((apmnt, id) => {
        let x = 0;
        let y = 0;
        let over;
        if (selectedEndTime) {
          x = compareTimes(selectedEndTime, apmnt.endTime);
          over = compareTimes(selectedEndTime, apmnt.time);
        }
        if (selectedTime) {
          y = compareTimes(selectedTime, apmnt.time);
        }

        return (
          (apmnt.time === selectedTime && apmnt.mrn) ||
          (apmnt.endTime === selectedEndTime && apmnt.mrn) ||
          (apmnt.endTime === selectedEndTime && apmnt.mrn) ||
          (x === -1 && y === 1 && apmnt.mrn) ||
          (over > 0 && y < 0)
        );
      })
    ) {
      return false;
    }
    return true;
  };

  const bookingHandler = () => {
    setisSaving(true);
    const postData = {
      mrn: mrn || data.otData.mrn,
      date: datewithoutHours,
      time: selectedTime,
      endTime: selectedEndTime,
      doctor: selectedDoctor,
      createdBy: username,
      fname: newPatientFName,
      sname: newPatientSName,
      contactno: newPatientMobile,
      department: selectedDepartment,
      visitType: selectedVisitType,
      visitReason: reasonforVisit,
      additional,
    };
    if (savedToggle) {
      alert("Already Saved");
      setisSaving(false);
      return;
    }
    let x = 0;
    let y = 0;
    if (selectedNextTime && bookedSlots) {
      x = compareTimes(selectedNextTime, bookedSlots.endTime);
    }
    if (selectedTime && bookedSlots) {
      y = compareTimes(selectedTime, bookedSlots.time);
    }
    if (
      (bookedSlots.time === selectedTime && bookedSlots.mrn) ||
      (bookedSlots.endTime === selectedNextTime && bookedSlots.mrn) ||
      (bookedSlots.endTime === selectedNextTime && bookedSlots.mrn) ||
      (x === -1 && y === 1 && bookedSlots.mrn)
    ) {
      alert("Booking Not Allowed");
      toggle();
      setisSaving(false);
      return;
    }
    const book = isBookingAllowed();

    if (!book) {
      alert("Booking Not Allowed, time slot conflict");
      toggle();
      setisSaving(false);
      return;
    }

    if (!newPatientMobile || newPatientMobile.length < 10) {
      alert("Please enter Contact No");
      setisSaving(false);
      return;
    }
    if (!newPatientFName || newPatientMobile.length < 2) {
      alert("Please enter First Name");
      setisSaving(false);
      return;
    }
    if (!selectedDoctor) {
      alert("Select Doctor");
      setisSaving(false);
      return;
    }
    if (!selectedDepartment) {
      alert("Select Department");
      setisSaving(false);
      return;
    }
    if (!selectedDate) {
      alert("Select Date");
      setisSaving(false);
      return;
    }
    if (!selectedVisitType) {
      alert("Select Visit Type");
      setisSaving(false);
      return;
    }
    if (!reasonforVisit) {
      alert("Select Visit Reason");
      setisSaving(false);
      return;
    }
    if (selectedTime === selectedEndTime || !selectedEndTime) {
      alert("Please select Proper Slot End Time");
      setisSaving(false);
      return;
    }
    if (!selectedTime) {
      alert("Select Time");
      setisSaving(false);
      return;
    } else if (verifyDateHandler()) {
      setisSaving(false);
      return;
    } else {
      axios
        .post(`${process.env.REACT_APP_CRI_API}add-appointment`, postData, {
          params: {
            branch: branch,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          savedToggle = true;
          // for blocking further booking in the same slot
          setBookedSlots(res.data);
          //  setSaved(true);
          getAppointmentsHandler();
          alert("Appointment Successful");
          toggle();
          setisSaving(false);
        })
        .catch((err) => {
          setisSaving(false);
          alert(err.message);
          if (
            (err.response.data &&
              err.response.data.err.name === "TokenExpiredError") ||
            err.response.data.message === "Unauthorized"
          ) {
            navigate("/");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("srchData");
            sessionStorage.removeItem("prescriptionHistory");
            sessionStorage.removeItem("rights");
            navigate("/cri-crm");
            return err;
          }
        });
    }
  };

  const deleteAppointmentHandler = () => {
    //setDeleteResponseModal(true);
    //console.log(_id);
    axios
      .delete(`${process.env.REACT_APP_CRI_API}delete-appointment`, {
        params: { date: datewithoutHours, id: _id, branch: branch },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res) {
          getAppointmentsHandler();
          toggle();
          //   setAppointmentData(res.data);
          // setBookedSlots("");
        }
      })
      .catch((err) => {
        if (
          (err.response.data &&
            err.response.data.err.name === "TokenExpiredError") ||
          err.response.data.message === "Unauthorized"
        ) {
          navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          navigate("/cri-crm");
          return err;
        }
      });
  };
  const updateAppointmentHandler = () => {
    //setDeleteResponseModal(true);
    const postData = {
      mrn: mrn || data.otData.mrn,
      date: datewithoutHours,
      time: selectedTime,
      endTime: selectedEndTime,
      doctor: selectedDoctor,
      createdBy: username,
      fname: newPatientFName,
      sname: newPatientSName,
      contactno: newPatientMobile,
      department: selectedDepartment,
      visitType: selectedVisitType,
      visitReason: reasonforVisit,
      additional,
    };
    axios
      .post(`${process.env.REACT_APP_CRI_API}edit-appointment`, postData, {
        params: { id: _id, branch: branch },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res) {
          getAppointmentsHandler();
          toggle();
          //   setAppointmentData(res.data);
          // setBookedSlots("");
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          (err &&
            err.response.data &&
            err.response.data.err.name === "TokenExpiredError") ||
          err.response.data.message === "Unauthorized"
        ) {
          navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          navigate("/cri-crm");
          return err;
        }
      });
  };
  function compareTimes(time1, time2) {
    const time1_24h = convertTo24HourFormat(time1);
    const time2_24h = convertTo24HourFormat(time2);
    if (time1_24h < time2_24h) {
      return -1; // time1 is less than time2
    } else if (time1_24h > time2_24h) {
      return 1; // time1 is greater than time2
    } else {
      return 0; // time1 is equal to time2
    }
  }

  const filterTimeOptions = (startTime) => {
    const filteredOptions = timeOptions.filter((time, index) => {
      const startT = compareTimes(startTime, time);
      const nextTime = timeOptions[index + 1];
      //if (startT === 1)
      //console.log(startTime, startT);
      return startT === -1 && nextTime !== undefined;
    });

    return filteredOptions;
  };

  useEffect(() => {
    if (selectedTime) {
      const filteredOptions = filterTimeOptions(selectedTime);
      setFilteredTimeOptions(filteredOptions);
    }
  }, [selectedTime]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Container className="pt-2 pb-5" style={{ flex: 1 }}>
        <Row className="d-flex justify-content-center bg-primary text-white p-2 mb-2">
          <Col md={4}></Col>
          <Col md={4} className="text-center">
            <h4>Appointment</h4>
          </Col>
          <Col md={4} style={{ textAlign: "right" }} className="pt-2">
            <b>User : </b> {userData.user}
          </Col>
        </Row>

        <Row>
          <Col md="1" className="pb-4">
            <Button
              outline
              color="primary"
              onClick={() => {
                if (userRights === "Doctor") {
                  navigate("/trtmnt");
                  return;
                }
                navigate(`${branch === "CLT" ? "/crm-clt" : "/crm"}`);
              }}
            >
              Back
            </Button>
          </Col>
        </Row>
        {
          // header starts here
        }
        <Row>
          <Col md="6">
            <InputGroup className="mb-4" color="#54B4D3">
              <Input
                id="Date"
                name="date"
                value={formattedDate}
                placeholder="Date"
                type="date"
                onChange={(e) => {
                  if (e.target.value) {
                    setSelectedDate(e.target.value);
                    setAppointmentData([]);
                  }
                }}
              />
              {userData.rights === "Doctor" ? (
                <Input value={userData.user} disabled />
              ) : (
                <Input
                  color="primary"
                  placeholder="Doctor"
                  type="select"
                  value={selectedDoctor}
                  onChange={(e) => {
                    setSelectedDoctor(e.target.value);
                    setAppointmentData([]);
                  }}
                >
                  {}
                  <option value="" disabled>
                    Select Doctor
                  </option>
                  {doctorsData &&
                    doctorsData.length > 0 &&
                    doctorsData.map((doc, index) => {
                      return (
                        <option key={index} value={doc.doctorName}>
                          {doc.doctorName}
                        </option>
                      );
                    })}
                </Input>
              )}
              <Input
                color="primary"
                type="select"
                placeholder="Department"
                onChange={(e) => {
                  setSelectedDepartment(e.target.value);

                  //   setSearchInput(e.target.value);
                }}
              >
                {" "}
                {optionsDepartment &&
                  optionsDepartment.length > 0 &&
                  optionsDepartment.map((dep, index) => {
                    return (
                      <option key={index} value={dep.department}>
                        {dep.department}
                      </option>
                    );
                  })}
              </Input>

              {}

              <InputGroupText className="bg-primary">
                {" "}
                <Search
                  style={{ color: "white", cursor: "pointer" }}
                  onClick={searchHandler}
                />
              </InputGroupText>
            </InputGroup>
          </Col>
          <Col>
            {appointmentData && (
              <h5>
                Total <Badge color="warning">{appointmentData.length}</Badge>
              </h5>
            )}
          </Col>
        </Row>
        {
          // header ends here
        }
        <Row>
          <Col className="d-flex justify-content-center ">
            {isLoading && <Spinner color="primary" className="mb-2" />}
          </Col>
        </Row>

        {appointmentData && selectedDoctor && (
          <Table>
            <thead>
              <tr>
                <th style={{ width: "200px" }}>Time Slot</th>
                <th style={{ width: "200px" }}>Availablity</th>
                <th>Reason</th>
                <th>Visit Type</th>
                <th>Department</th>
                <th>Contact No</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {appointmentData &&
                timeOptions.map((time, index) => {
                  const nextTime = timeOptions[index + 1]; // Get the next time slot

                  const timeRange = nextTime ? `${time} - ${nextTime}` : time; // If there is a next time slot, display the range
                  let bookedSlotsTemp = "";
                  let xp = 0;
                  let yp = 0;
                  let overp;

                  const PatientAppointment =
                    appointmentData &&
                    appointmentData.some((apmnt) => {
                      if (nextTime) {
                        xp = compareTimes(nextTime, apmnt.endTime);
                        overp = compareTimes(nextTime, apmnt.time);
                      }
                      if (time) {
                        yp = compareTimes(time, apmnt.time);
                      }
                      return (
                        ((apmnt.time === time && apmnt.mrn) ||
                          (apmnt.endTime === nextTime && apmnt.mrn) ||
                          (apmnt.endTime === nextTime &&
                            apmnt.mrn.includes("Tmp")) ||
                          (xp === -1 && yp === 1) ||
                          (overp > 0 && yp < 0)) &&
                        apmnt.mrn
                      );
                    });

                  if (PatientAppointment) {
                    return (
                      <tr
                        key={index}
                        style={{
                          visibility: `${
                            index === timeOptions.length - 1
                              ? "hidden"
                              : "visible"
                          }`,
                          borderBottom:
                            index === timeOptions.length - 1
                              ? "solid transparent"
                              : "",
                        }}
                      >
                        <td>{timeRange}</td>
                        <td
                          className="table-primary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // console.log(bookedSlotsTemp);
                            if (appointmentData && appointmentData.length > 0) {
                              if (
                                appointmentData.some((apmnt, id) => {
                                  let x = 0;
                                  let y = 0;
                                  let over = 0;
                                  if (nextTime) {
                                    x = compareTimes(nextTime, apmnt.endTime);
                                    over = compareTimes(nextTime, apmnt.time);
                                  }
                                  if (time) {
                                    y = compareTimes(time, apmnt.time);
                                  }
                                  setId(apmnt._id);
                                  setSelectedAppointment(apmnt);
                                  return (
                                    ((apmnt.time === time && apmnt.mrn) ||
                                      (apmnt.endTime === nextTime &&
                                        apmnt.mrn) ||
                                      (apmnt.endTime === nextTime &&
                                        apmnt.mrn.includes("Tmp")) ||
                                      (over > 0 && y < 0) ||
                                      (x === -1 && y === 1)) &&
                                    apmnt.mrn
                                  );
                                })
                              ) {
                                setBookedSlots(bookedSlotsTemp);
                                alert("Booking Not Allowed");

                                setEdit(() => true);
                                setSelectedTime(time);
                                setSelectedEndTime(nextTime);

                                toggle();
                                return;
                              } else if (!selectedDoctor) {
                                alert("Please Select Doctor");
                                return;
                              } else if (selectedDoctor) {
                                setBookedSlots(bookedSlotsTemp);
                                setEdit(() => false);
                                setSelectedTime(time);
                                setSelectedEndTime(nextTime);

                                toggle();
                                return;
                              }
                            } else if (!appointmentData) {
                              alert("Please view appointment before booking");
                              return;
                            } else if (!selectedDoctor) {
                              alert("Please Select Doctor");
                              return;
                            } else if (selectedDoctor) {
                              setBookedSlots(bookedSlotsTemp);
                              setEdit(() => false);
                              setSelectedTime(time);
                              setSelectedEndTime(nextTime);

                              toggle();
                              return;
                            }
                          }}
                        >
                          {/* Content of the table cell */}
                          {appointmentData &&
                            appointmentData.length > 0 &&
                            appointmentData.map((apmnt, id) => {
                              let x = 0;
                              let y = 0;
                              let over;
                              //    if (nextTime) {
                              //  x = compareTimes(nextTime, apmnt.endTime);
                              //     over = compareTimes(nextTime, apmnt.time);
                              //    }

                              if (nextTime) {
                                x = compareTimes(nextTime, apmnt.endTime);
                                over = compareTimes(nextTime, apmnt.time);
                                //  x = compareTimes(selectedEndTime, apmnt.endTime);
                                // over = compareTimes(selectedEndTime, apmnt.time);
                              }
                              // include if endtime is === or less
                              // x = 1, x , nextTime is greater than end -- avoid
                              // x = -1, nextTime is less than end &&  y <= 1 time is less or -- include

                              if (time) {
                                y = compareTimes(time, apmnt.time);
                              }
                              if (
                                (apmnt.time === time ||
                                  apmnt.endTime === nextTime ||
                                  apmnt.endTime === nextTime ||
                                  (x === -1 && y === 1) ||
                                  (over > 0 && y < 0)) &&
                                apmnt.mrn.includes("Tmp")
                              ) {
                                bookedSlotsTemp = apmnt;

                                return (
                                  <ToastHeader icon="warning">
                                    {apmnt.fname}
                                  </ToastHeader>
                                );
                              } else if (
                                (apmnt.time === time ||
                                  apmnt.endTime === nextTime ||
                                  apmnt.endTime === nextTime ||
                                  (x === -1 && y === 1) ||
                                  (over > 0 && y < 0)) &&
                                apmnt.mrn
                              ) {
                                return (
                                  <ToastHeader icon="success">
                                    {apmnt.fname}
                                  </ToastHeader>
                                );
                              }
                            })}
                        </td>
                        {/* Content of the next <td>s */}
                        {appointmentData &&
                          appointmentData.length > 0 &&
                          appointmentData.map((apmnt, id) => {
                            let x = 0;
                            let y = 0;
                            if (nextTime) {
                              x = compareTimes(nextTime, apmnt.endTime);
                            }
                            if (time) {
                              y = compareTimes(time, apmnt.time);
                            }
                            if (
                              (apmnt.time === time && apmnt.mrn) ||
                              (apmnt.endTime === nextTime && apmnt.mrn) ||
                              (apmnt.endTime === nextTime && apmnt.mrn) ||
                              (x === -1 && y === 1 && apmnt.mrn)
                            ) {
                              bookedSlotsTemp = apmnt;
                              return (
                                <>
                                  <td>{apmnt.visitReason}</td>
                                  <td>{apmnt.visitType}</td>
                                  <td>{apmnt.department}</td>
                                  <td>{apmnt.contactno}</td>
                                  <td>{apmnt?.additional?.status}</td>
                                </>
                              );
                            } else if (apmnt.time === time && apmnt.mrn) {
                              return (
                                <>
                                  <td>{apmnt.visitReason}</td>
                                  <td>{apmnt.visitType}</td>
                                  <td>{apmnt.department}</td>
                                  <td>{apmnt.contactno}</td>
                                  <td>{apmnt?.additional?.status}</td>
                                </>
                              );
                            }
                          })}
                      </tr>
                    );
                  } else {
                    // for no appoinment row
                    return (
                      <tr
                        key={index}
                        style={{
                          visibility: `${
                            index === timeOptions.length - 1
                              ? "hidden"
                              : "visible"
                          }`,
                          borderBottom:
                            index === timeOptions.length - 1
                              ? "solid transparent"
                              : "",
                        }}
                      >
                        <td>{timeRange}</td>
                        <td
                          className="table-primary"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (!appointmentData) {
                              alert("Please view appointment before booking");
                              return;
                            } else if (!selectedDoctor) {
                              alert("Please Select Doctor");
                              return;
                            } else if (selectedDoctor) {
                              getCurrentISTTime().then((res) => {
                                const checkTime = compareTimes(time, res);

                                const tday = new Date(
                                  today.setHours(0, 0, 0, 0)
                                ).toISOString();
                                const matchDate = datewithoutHours === tday;

                                if (checkTime < 0 && matchDate) {
                                  alert("Please select future time");
                                  return;
                                } else if (verifyDateHandler(selectedDate)) {
                                  //  alert(selectedDate);
                                  return;
                                } else {
                                  setBookedSlots(bookedSlotsTemp);

                                  setEdit(false);
                                  setSelectedTime(time);
                                  setSelectedEndTime(nextTime);
                                  if (!data || !data.otData) {
                                    clearForm();
                                  }
                                  toggle();
                                  return;
                                }
                              });
                            }
                          }}
                        ></td>
                        {/* Empty <td> */}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </Table>
        )}

        <Modal isOpen={modal} toggle={toggle} style={{ width: "100%" }}>
          <ModalHeader>
            <Row>
              <Col>
                <div>{"Book Appointment"}</div>{" "}
                <div style={{ fontSize: "12px" }}>
                  {formatDate(selectedDate)}
                </div>
              </Col>
              <Col>
                {
                  <InputGroup>
                    <Input
                      type="text"
                      placeholder="MRN or Mobile No"
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setCustomerSearchInput(e.target.value);
                        }
                      }}
                    />
                    <InputGroupText className="bg-primary">
                      {" "}
                      <Search
                        style={{ color: "white", cursor: "pointer" }}
                        onClick={searchHandler}
                      />
                    </InputGroupText>
                  </InputGroup>
                }
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody>
            <>
              <Row>
                <Col md={7}>
                  <Label>Patient Name</Label>
                </Col>
                <Col md={5}>
                  {" "}
                  {
                    // (selectedCmData.length > 0 && " MRN - " + selectedCmData[0]) ||
                    //appointmentData[0].mrn
                  }
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <Input
                    type="text"
                    value={newPatientFName}
                    readOnly={(mrn === data && data.otData.mrn) || edit}
                    placeholder="First Name"
                    onChange={(e) => {
                      setNewPatientFName(e.target.value);
                    }}
                  />{" "}
                </Col>
                <Col md={4}>
                  <Input
                    type="text"
                    placeholder="Second Name"
                    value={newPatientSName}
                    readOnly={(mrn === data && data.otData.mrn) || edit}
                    onChange={(e) => {
                      setNewPatientSName(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row className="my-2">
                <Col md="5">
                  <Label sm="5">Mobile No</Label>
                  <Input
                    type="text"
                    placeholder="Mobile No."
                    readOnly={(mrn === data && data.otData.mrn) || edit}
                    value={newPatientMobile}
                    onChange={(e) => {
                      setNewPatientMobile(e.target.value);
                    }}
                  />{" "}
                </Col>

                <Col md="5" className="pt-2">
                  <Label>Doctor Name</Label>
                  <Input
                    color="primary"
                    readOnly
                    type="text"
                    value={selectedDoctor}
                  ></Input>
                </Col>
              </Row>
              <Row>
                <Label sm="3">Time Slot</Label>
                <Col md="3" className="mt-2 pt-1">
                  {selectedTime} <span className="ps-4"> - </span>{" "}
                </Col>
                <Col md="4" className="mt-1">
                  {selectedNextTime}
                  <Input
                    type="select"
                    disabled={edit}
                    value={selectedEndTime || selectedNextTime}
                    onChange={(e) => setSelectedEndTime(e.target.value)}
                  >
                    {filteredTimeOptions.map((time, index) => (
                      <option key={index} value={time}>
                        {time}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
              <Row className="py-2">
                <Label md={4}>Visit Type</Label>
                <Col md={4}>
                  <Input
                    type="select"
                    value={selectedVisitType}
                    disabled={edit}
                    onChange={(e) => setSelectedVisitType(e.target.value)}
                  >
                    {visitTypeOptions.map((vtype, id) => {
                      return <option>{vtype}</option>;
                    })}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Label md={4}>Visit Reason</Label>
                <Col md={6}>
                  <Input
                    type="text"
                    list="reasonforvisit"
                    value={reasonforVisit}
                    readOnly={edit}
                    onChange={(e) => {
                      setReasonforVisit(e.target.value);
                    }}
                  />
                  <datalist id="reasonforvisit">
                    {reasonforvisitOptions.map((item, key) => (
                      <option key={key} value={item} />
                    ))}
                  </datalist>
                </Col>
              </Row>

              <Row className="py-2">
                <Label md={4}>Status</Label>
                <Col md={4}>
                  <Input
                    type="select"
                    value={additional?.status || ""}
                    // disabled={edit}
                    onChange={(e) =>
                      setAdditional({
                        ...additional,
                        status: e.target.value, // Assuming you want to update the `status` field
                      })
                    }
                  >
                    <option value="">Select Status</option>{" "}
                    {/* Default placeholder */}
                    <option value="Confirmed">Confirmed</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Waiting">Waiting</option>
                  </Input>
                </Col>
                <Col>
                  <Button onClick={updateAppointmentHandler}>
                    Update Status
                  </Button>
                </Col>
              </Row>
            </>

            <ModalFooter className="p-0 pt-1">
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  setisSaving(false);
                  toggle();
                }}
              >
                Cancel
              </Button>
              {data && mrn === data.otData.mrn && !edit && (
                <Button onClick={clearForm} color="primary">
                  New Patient
                </Button>
              )}
              {isSaving && <Spinner color="warning" />}
              {!edit && !isSaving && (
                <Button color="primary" onClick={bookingHandler}>
                  Book
                </Button>
              )}
              {edit && (
                <Button color="danger" onClick={deleteAppointmentHandler}>
                  Delete
                </Button>
              )}
            </ModalFooter>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};

export default Booking;
