// NoInternet.js
import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";

const NoInternet = () => {
  const [online, setOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setOnline(window.navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  return (
    <div>
      {!online && (
        <>
          <div className="d-flex justify-content-center mt-5 pt-5">
            <Spinner
              color="primary"
              style={{
                height: "3rem",
                width: "3rem",
              }}
              type="grow"
            />
          </div>
          <div className="d-flex justify-content-center mt-5 pt-5">
            <h5>No Internet Connection, trying to connect ...</h5>
          </div>
        </>
      )}
      {online && <p>Connected to the internet</p>}
    </div>
  );
};

export default NoInternet;
