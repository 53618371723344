export const getUserRights = async () => {
  const userRightsTemp = sessionStorage.getItem("rights");
  let userRightsSession = null;
  if (userRightsTemp !== "undefined") {
    //  console.log(userRightsTemp);
    try {
      userRightsSession =
        sessionStorage.getItem("rights") &&
        JSON.parse(sessionStorage.getItem("rights"));
      // Attempt to parse the rights as JSON
    } catch (error) {
      console.error("Error parsing rights:", error);
    }
  }
  return userRightsSession;
};
