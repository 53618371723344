import React, { useEffect, useRef, useState, useMemo } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import PrintBill from "../../CRM/Bills/PrintBill"; // Assuming you have this component
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import { getbranchData, searchCustomers } from "../../../../Data/Customer/api";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../../CRM/Bills/Loading";

const PdfGenerator = ({ invoices }) => {
  const auth = useSelector((state) => state.authentication);
  const [addressPrint, setAddressPrint] = useState("");
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState([]);
  const { userData, branchData } = auth;
  const branch = userData.branch;
  const [GSTIN, setGSTIN] = useState("");
  const componentRefs = useRef([]);
  const [buttonHide, setButtonHide] = useState({ display: "none" });
  const [totalQTY, setTotalQTY] = useState("");
  const [totalTax, setTotalTax] = useState("");
  const [taxableData, setTaxableData] = useState("");
  let payments = [{ mode: "Cash", amount: "" }];
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => setModal(!modal);

  const generatePDFs = async () => {
    setModal(true); // Show loading modal
    const zip = new JSZip();

    for (let i = 0; i < invoices.length; i++) {
      try {
        const input = componentRefs.current[i];
        if (!input) continue;

        const canvas = await html2canvas(input);
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        const pdfBlob = pdf.output("blob");

        zip.file(`invoice_${invoices[i].invoice_id}.pdf`, pdfBlob);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "invoices.zip");
      setModal(false); // Hide loading modal after download
    });
  };

  //useEffect(() => {
  const fetchCustomerData = async () => {
    setLoading(true);
    try {
      const fetchedData = await Promise.all(
        invoices.map(async (invoice) => {
          try {
            const res = await searchCustomers(
              userData.token,
              invoice.mrn,
              userData.branch,
              navigate
            );
            if (res.length) {
              const [
                {
                  fname,
                  sname = "",
                  sex = "",
                  contactno,
                  nationality,
                  doctorName,
                },
              ] = res;
              return {
                ...invoice,
                fname,
                sname,
                sex,
                contactno,
                nationality,
                doctorName,
              };
            }
          } catch (error) {
            console.error(
              `Error fetching data for invoice MRN: ${invoice.mrn}`,
              error
            );
          }
          return invoice; // Return original invoice in case of failure
        })
      );
      setCustomerData(fetchedData);
    } catch (error) {
      console.error("Error fetching customer data:", error);
    } finally {
      setLoading(false);
    }
  };

  //   if (userData.token && userData.branch && invoices) fetchCustomerData();
  // }, [invoices, userData.token, userData.branch]);

  useEffect(() => {
    if (customerData && customerData.length) {
      generatePDFs();
    }
  }, [customerData]);

  const fetchBranchData = async () => {
    if (userData?.branch && userData?.token && !GSTIN) {
      try {
        const res = await getbranchData(
          userData.token,
          userData.branch,
          navigate
        );

        if (res.length) {
          const { first_line, second_line, third_line, fourth_line } =
            res[0].address;
          const { GSTIN } = res[0];
          setGSTIN(GSTIN);

          setAddressPrint({
            first_line,
            second_line,
            third_line,
            fourth_line,
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  useEffect(() => {
    fetchBranchData();
  }, [userData.branch, userData.token]);

  const calculateTotal = (invoiceSheet) => {
    const amountValues = invoiceSheet
      .map((row, index) => row[`Row${index + 1}`].amount)
      .filter((i) => parseFloat(i));

    const qtyValues = invoiceSheet
      .map((row, index) => row[`Row${index + 1}`].qty)
      .filter((i) => parseFloat(i));

    const taxValues = invoiceSheet
      .map((row, index) => row[`Row${index + 1}`].tax)
      .filter((i) => parseFloat(i));

    const taxableList = invoiceSheet
      .filter((row, index) => row[`Row${index + 1}`].tax)
      .map((row) => {
        const key = Object.keys(row)[0];
        return row[key];
      });

    const totalTempTax = taxValues
      .reduce((acc, currentValue) => {
        const numericValue = parseFloat(currentValue);
        return acc + numericValue;
      }, 0)
      .toFixed(2);

    const totalTempQty = qtyValues.reduce((acc, currentValue) => {
      const numericValue = parseFloat(currentValue);
      return acc + numericValue;
    }, 0);

    const totalTempAmt = amountValues.reduce((acc, currentValue) => {
      const numericValue = parseFloat(currentValue);
      return acc + numericValue;
    }, 0);

    if (Array.isArray(invoiceSheet.payment_mode)) {
      payments = invoiceSheet.payment_mode;
    } else if (typeof invoiceSheet.payment_mode === "string") {
      payments = [{ mode: invoiceSheet.payment_mode, amount: "" }];
    }

    return { totalTempAmt, totalTempQty, totalTempTax, taxableList, payments };
  };

  const memoizedTotals = useMemo(() => {
    return customerData.map((invoice) => {
      return calculateTotal(invoice.invoice_sheet);
    });
  }, [customerData]);

  return (
    <Container>
      <Row className="d-flex justify-content-between mt-1">
        <Col>
          {loading ? (
            <Spinner />
          ) : (
            <button onClick={fetchCustomerData} className="mt-4">
              Download All Invoices as ZIP
            </button>
          )}

          {customerData.length > 0 &&
            customerData.map((invoice, index) => {
              const otData = {
                fname: invoice.fname || "",
                sname: invoice.sname || "",
                sex: invoice.sex || "",
                contactno: invoice.contactno || "",
                nationality: invoice.nationality || "",
                doctorName: invoice.doctorName || "",
                ...invoice,
              };
              const {
                totalTempAmt,
                totalTempQty,
                totalTempTax,
                taxableList,
                payments,
              } = memoizedTotals[index];

              return (
                <div
                  key={invoice.invoice_id}
                  ref={(el) => (componentRefs.current[index] = el)}
                  className="hidden-for-pdf"
                >
                  <PrintBill
                    data={{ otData }}
                    address={addressPrint}
                    invoice_id={invoice.invoice_id}
                    date={invoice.invoice_date}
                    invoiceSheet={invoice.invoice_sheet}
                    total={invoice.total_amount}
                    payment_mode={payments
                      .map((payment) => payment.mode)
                      .join(", ")}
                    branch={branch}
                    buttonHide={buttonHide}
                    tax={invoice.tax}
                    netAmountPay={invoice.total_amount}
                    GSTIN={GSTIN}
                    discount={invoice.discount}
                    receivedAmt={invoice.total_amount_paid}
                    balanceAmount={invoice.balanceAmount}
                    totalQTY={totalTempQty}
                    totalTax={totalTempTax}
                    taxableData={taxableList}
                    formData={invoice.formData}
                  />
                </div>
              );
            })}
        </Col>
      </Row>
      <LoadingModal isOpen={modal} toggle={toggleModal} />
    </Container>
  );
};

export default PdfGenerator;
