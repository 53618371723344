import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  CardBody,
  Table,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { ReactComponent as Rupees } from "../../../../images/currency-rupee.svg";
import { Eye, Trash } from "react-feather";
//import { getInventoryItems } from "../../../../redux/inventory";
//import PdfGenerator from "./PdfGenerator";
const LabResultsList = ({}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;
  const itemsList = useSelector((state) => state.itemsList);
  const { itemsData } = itemsList;
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState(
    JSON.parse(sessionStorage.getItem("filterTypeLab")) || "date"
  );
  const [selectedDate, setSelectedDate] = useState(
    JSON.parse(sessionStorage.getItem("selectedDateLab")) || ""
  );
  const [fromDate, setFromDate] = useState(
    JSON.parse(sessionStorage.getItem("fromDateLab")) || ""
  );
  const [toDate, setToDate] = useState(
    JSON.parse(sessionStorage.getItem("toDateLab")) || ""
  );
  const [dateError, setDateError] = useState("");
  const [fromDateError, setFromDateError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [labResults, setlabResults] = useState(
    JSON.parse(sessionStorage.getItem("labResults")) || []
  );
  const [totallabResults, setTotallabResults] = useState(
    JSON.parse(sessionStorage.getItem("totallabResults")) || 0
  );

  const [totalExcellabResults, setTotalExcellabResults] = useState("");

  const [showSpinner, setShowSpinner] = useState(false);
  const [showBillLoading, setShowBillLoading] = useState(false);
  const [spinnerId, setSpinnerId] = useState("");
  const [patientMobile, setpatientMobile] = useState("");
  const [mrn, setMrn] = useState("");
  const [modal, setModal] = useState(false);
  const [patientList, setPatientList] = useState("");
  const toggle = () => setModal(!modal);
  // Validate date inputs
  const validateDate = (date) => {
    const pattern = /^\d{4}-\d{2}-\d{2}$/;
    return pattern.test(date);
  };
  function validateMobileNumber(mobile) {
    // Regex for Indian mobile numbers (10 digits, starting with 6-9)
    const mobileRegex = /^[6-9]\d{9}$/;

    return mobileRegex.test(mobile);
  }

  const validate = () => {
    let isValid = true;
    if (patientMobile && !validateMobileNumber(patientMobile)) {
      setMobileError("Please Enter Correct Mobile No.");
      isValid = false;
    }
    if (!validateDate(fromDate) && !validateDate(selectedDate)) {
      setFromDateError("Please enter a valid From Date (YYYY-MM-DD).");

      isValid = false;
    } else {
      setFromDateError("");
      setDateError("");
      setMobileError("");
    }

    if (!validateDate(toDate) && !validateDate(selectedDate)) {
      setToDateError("Please enter a valid To Date (YYYY-MM-DD).");

      isValid = false;
    } else {
      setToDateError("");
    }

    return isValid;
  };

  const clearError = () => {
    setDateError("");
    setFromDateError("");
    setToDateError("");
    setMobileError("");
  };

  useEffect(() => {
    if (userData.token) {
      // dispatch(
      //   getInventoryItems({ token: userData.token, branch: userData.branch })
      // );
    }
  }, [userData.token]);

  useEffect(() => {
    if (selectedDate || (fromDate && toDate)) {
      filterHandler();
    } else if (patientMobile) {
      filterHandler();
    }
  }, []);

  // useEffect(() => {
  //   console.log(selectedDate);
  //   if (filterType === "date") {
  //     const today = new Date();
  //     const formattedDate = today.toISOString().split("T")[0];
  //     setSelectedDate(formattedDate);
  //     setFromDate("");
  //     setToDate("");
  //   } else if (filterType === "") {
  //     setSelectedDate("");
  //   }
  // }, [filterType]);

  const filterTypeHandler = (e) => {
    setFilterType(e.target.value);

    const type = e.target.value;
    if (type === "") {
      setSelectedDate("");
    }
    if (type === "date") {
      setFromDate("");
      setToDate("");
    }
  };

  const filterHandler = () => {
    setTotallabResults("");
    setShowSpinner(true);
    setlabResults("");

    if (validate()) {
      let newSelectedDate;
      let date;
      let fromDateWithoutHours;
      let toDateWithoutHours;
      if (selectedDate) {
        newSelectedDate = new Date(selectedDate);
        date = new Date(newSelectedDate.toISOString());
      }

      if (fromDate) {
        newSelectedDate = new Date(fromDate);
        fromDateWithoutHours = new Date(
          newSelectedDate.setHours(0, 0, 0, 0)
        ).toISOString();
      }
      if (toDate) {
        newSelectedDate = new Date(toDate);
        toDateWithoutHours = new Date(
          newSelectedDate.setHours(0, 0, 0, 0)
        ).toISOString();
      }

      axios
        .get(`${process.env.REACT_APP_CRI_API}lab-results`, {
          params: {
            branch: userData.branch,
            date: date,
            fromDate: fromDateWithoutHours,
            toDate: toDateWithoutHours,
            mobile: patientMobile,
            sid: "",
          },
          headers: { Authorization: `Bearer ${userData.token}` },
          "Content-Type": "application/json",
        })
        .then((res) => {
          setShowSpinner(false);

          setlabResults(res.data);
          sessionStorage.setItem("labResults", JSON.stringify(res.data));
          sessionStorage.setItem("filterTypeLab", JSON.stringify(filterType));
          sessionStorage.setItem(
            "selectedDateLab",
            JSON.stringify(selectedDate)
          );
          sessionStorage.setItem("fromDateLab", JSON.stringify(fromDate));
          sessionStorage.setItem("toDateLab", JSON.stringify(toDate));

          if (res.data) {
            if (res.data.length === 0) {
              setShowSpinner(false);
              alert("No Data");
            }
          }
        })

        .catch((err) => {
          setShowSpinner(false);
          console.log(err);
          if (
            (err.response &&
              err.response.data &&
              err.response.data.err.name === "TokenExpiredError") ||
            err.response.data.message === "Unauthorized"
          ) {
            navigate("/");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("srchData");
            sessionStorage.removeItem("prescriptionHistory");
            sessionStorage.removeItem("rights");
            navigate("/cri-crm");
            return err;
          }
          console.log(err);
          return err;
        });
    } else {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    if (labResults.length) {
      const data = labResults;
      // console.log(data);
      // setExcelFilteredSales(() =>
      //   data.map((item) => ({
      //     invoice_date: new Date(item.invoice_date).toLocaleDateString(),
      //     invoice_id: item.invoice_id,
      //     amount: item.total_amount,
      //     amount_paid: item.total_amount_paid,
      //     payment_mode: item.payment_mode,
      //     items: item.invoice_sheet
      //       .map((obj) => {
      //         const key = Object.keys(obj)[0]; // Assuming there's only one key in obj
      //         const itemData = obj[key];
      //         if (itemData.item) {
      //           return {
      //             item: itemData.item,
      //             rate: itemData.rate,
      //             tax: itemData.tax,
      //             mrp: itemData.mrp || itemData.amount,
      //             quantity: itemData.quantity || itemData.qty,
      //           };
      //         }
      //       })
      //       .filter((item) => item)
      //       .map(
      //         (x) =>
      //           `${x.item} - Rate : ${x.rate}, Qty: ${x.quantity} - Tax: ${x.tax} Amount: ${x.mrp},`
      //       )
      //       .join(", "),
      //   }))
      // );
    }
  }, [labResults]);

  // const getCustomerName = (mrn) => {
  //   let pname = "";

  //   getCustomers(userData.token, userData.branch, mrn, "", "", navigate).then(
  //     (res) => {
  //       console.log(res);
  //       const { fname, sname } = res[0];
  //       pname = `${fname} ${sname}`;
  //       console.log(pname);
  //     }
  //   );

  //   return pname;
  // };

  const deleteHandler = (item) => {
    const SID = prompt("Please enter SID to confirm delete");
    if (SID && SID.toLocaleLowerCase() === item.SID.toLocaleLowerCase()) {
      axios
        .delete(`${process.env.REACT_APP_CRI_API}delete-lab-results`, {
          params: { id: item._id, branch: userData.branch },
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((res) => {
          if (res) {
            filterHandler();
            //  console.log(res);
          }
        })
        .catch((err) => {
          if (
            (err.response.data &&
              err.response.data.err &&
              err.response.data.err.name === "TokenExpiredError") ||
            err.response.data.message === "Unauthorized"
          ) {
            navigate("/");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("srchData");
            sessionStorage.removeItem("prescriptionHistory");
            sessionStorage.removeItem("rights");
            navigate("/cri-crm");
            return err;
          }
        });
    } else if (SID) {
      alert("Inalid SID");
    }
  };

  const setLabResultEditViewHandler = (labResultsData) => {
    setShowBillLoading(true);
    //token, searchInput, branch, navigate
    // searchCustomers(
    //   userData.token,
    //   invoice.mrn,
    //   userData.branch,
    //   navigate
    // ).then((res) => {
    //   if (res.length) {
    //     const [
    //       { fname, sname = "", sex = "", contactno, nationality, doctorName },
    //     ] = res;
    //
    navigate("/lab-admin/lab-report", {
      //replace: true,
      state: labResultsData,
    });
    setSpinnerId("");
    setShowBillLoading(false);
    //}
    //  });
  };

  return (
    <>
      <Row>
        <Col className="text-center" md={12}>
          <Card className="mt-2 pt-4 m-2">
            <CardTitle>
              <b>Lab Results</b>

              <Row>
                <Col md="1" className="pb-4">
                  {
                    //   <button
                    //     onClick={() => {
                    //       navigate(-1);
                    //     }}
                    //   >
                    //     Back
                    //   </button>
                  }
                </Col>
              </Row>
              <div className="d-flex ps-3">
                {" "}
                <FormGroup className="me-3 ps-2">
                  <Label for="filterType">Filter Type:</Label>
                  <Input
                    type="select"
                    name="filterType"
                    id="filterType"
                    value={filterType}
                    onChange={filterTypeHandler}
                  >
                    <option value="">Select</option>
                    <option value="yearly">Yearly</option>
                    <option value="monthly">Monthly</option>
                    <option value="date">Date</option>
                  </Input>
                </FormGroup>
                {filterType === "date" && (
                  <FormGroup>
                    <Label for="selectedDate">Select Date:</Label>
                    <Input
                      type="date"
                      name="selectedDate"
                      id="selectedDate"
                      value={selectedDate}
                      onChange={(e) => {
                        clearError();
                        setSelectedDate(e.target.value);
                        setFromDate("");
                        setToDate("");
                      }}
                      invalid={dateError !== ""}
                    />
                  </FormGroup>
                )}
                {filterType !== "date" && (
                  <>
                    <FormGroup>
                      <Label for="fromDate">From Date:</Label>
                      <Input
                        type="date"
                        name="fromDate"
                        id="fromDate"
                        value={fromDate}
                        onChange={(e) => {
                          setFromDate(e.target.value);
                          clearError();
                        }}
                        invalid={fromDateError !== ""}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="toDate">To Date:</Label>
                      <Input
                        type="date"
                        name="toDate"
                        id="toDate"
                        value={toDate}
                        onChange={(e) => {
                          setToDate(e.target.value);
                          clearError();
                        }}
                        invalid={toDateError !== ""}
                      />
                    </FormGroup>
                  </>
                )}
                <FormGroup className="ms-3">
                  <Label for="patientMobile">Patient Mobile:</Label>
                  <Input
                    type="text"
                    name="patientMobile"
                    id="patientMobile"
                    value={patientMobile}
                    onChange={(e) => {
                      setPatientList("");
                      setpatientMobile(e.target.value);
                      setMrn("");
                      clearError();
                    }}
                    invalid={mobileError !== ""}
                  />
                </FormGroup>
                <button
                  onClick={filterHandler}
                  className=" ms-3"
                  style={{
                    width: "auto",
                    height: "40px",
                    borderRadius: "10px",
                    marginTop: "30px",
                  }}
                >
                  Search
                </button>
              </div>

              {showSpinner && <Spinner color="primary" />}
            </CardTitle>
            <CardBody>
              {labResults && labResults.length > 0 && (
                <>
                  {
                    //     <Row className="p-2">
                    //    <Col md={2}>
                    //      <Card className="p-2">
                    //        <div>Total Sales </div>
                    //        <div className=" d-flex justify-content-center ">
                    //          <div style={{ width: "20px" }}> </div>
                    //          <div>{totalExcellabResults}</div>
                    //        </div>
                    //      </Card>
                    //    </Col>
                    //    <Col md={2}>
                    //      <Card className="p-2">
                    //        {" "}
                    //        Total Paid
                    //        <div className=" d-flex justify-content-center ">
                    //          <div style={{ width: "20px" }}> </div>
                    //          <div> </div>
                    //        </div>
                    //      </Card>
                    //    </Col>
                    //    <Col md={2}>
                    //      <Card className="p-2">Pending</Card>
                    //    </Col>
                    //  </Row>
                  }
                  <Table color="secondary" bordered className="m-2 mb-5">
                    <thead>
                      <tr>
                        <th style={{ width: "10%" }}>Collection Date</th>
                        <th style={{ width: "10%" }}>Results Date</th>
                        <th style={{ width: "10%" }}>Patient</th>
                        <th style={{ width: "10%" }}>Contact</th>
                        <th style={{ width: "10%" }}>SID No</th>
                        <th style={{ width: "10%" }}>Lab Technician</th>
                        <th>Created By</th>
                        <th style={{ width: "5%" }}>View/Edit</th>
                        <th style={{ width: "5%" }}>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {labResults.map((item, id) => {
                        return (
                          <tr key={id}>
                            <td>
                              {new Date(
                                item.patientData.collDateTime
                              ).toLocaleDateString()}
                            </td>
                            <td>
                              {" "}
                              {new Date(
                                item.patientData.reportDateTime
                              ).toLocaleDateString()}
                            </td>
                            <td>{item.patientData.name || item.mrn}</td>
                            <td>{item.patientData.contact}</td>
                            <td>{item.SID}</td>

                            <td>{item.labTechnician}</td>
                            <td style={{ width: "10%" }}>{item.createdBy}</td>
                            <td>
                              {showBillLoading && spinnerId === id && (
                                <Spinner />
                              )}
                              {spinnerId !== id && (
                                <Eye
                                  color="green"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setSpinnerId(id);
                                    setLabResultEditViewHandler(item);
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              <Trash
                                color="red"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  deleteHandler(item);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Select Patient</ModalHeader>
        <ModalBody>
          <Table color="secondary" bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Patient Name</th>
                <th>MRN</th>
                <th>Select</th>
              </tr>
            </thead>
            <tbody>
              {patientList &&
                patientList.map((item, id) => {
                  return (
                    <tr>
                      <td>{id + 1}</td>
                      <td>{`${item.fname} ${item.sname}`}</td>
                      <td>{item.mrn}</td>
                      <td>
                        <Input
                          type="radio"
                          onChange={() => {
                            setMrn(item.mrn);
                            toggle();
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default LabResultsList;
