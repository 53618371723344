import "bootstrap/dist/css/bootstrap.min.css";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";

const PatientDetailsForm = forwardRef((props, ref) => {
  const [patientDetails, setPatientDetails] = useState({
    name: "",
    contact: "",
    age: "",
    sex: "",
    referrer: "",
    branch: "",
    sid: "",
    regDateTime: "",
    collDateTime: "",
    reportDateTime: "",
  });

  const [errors, setErrors] = useState({});

  useImperativeHandle(ref, () => ({
    clearHandler() {
      console.log("Child function called");
      setPatientDetails({
        name: "",
        contact: "",
        age: "",
        sex: "",
        referrer: "",
        branch: "",
        sid: "",
        regDateTime: "",
        collDateTime: "",
        reportDateTime: "",
      });
      setErrors({});
      sessionStorage.removeItem("patientDetails");
    },
  }));

  const validateField = (name, value) => {
    let errorMsg = "";
    if (name === "contact" && value && !/^\d{10}$/.test(value)) {
      errorMsg = "Contact number must be 10 digits.";
    } else if (name === "age" && value && (isNaN(value) || value <= 0)) {
      errorMsg = "Age must be a positive number.";
    }
    return errorMsg;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const errorMsg = validateField(name, value);

    setPatientDetails({ ...patientDetails, [name]: value });
    setErrors({ ...errors, [name]: errorMsg });
    props.setPatientData({ ...patientDetails, [name]: value });
  };

  useEffect(() => {
    if (patientDetails.name) {
      sessionStorage.setItem("patientDetails", JSON.stringify(patientDetails));
      // session will be updated with patient details
    }
    if (patientDetails.sid) {
      props.setPatientData({ ...patientDetails });
    }
    console.log(patientDetails);
  }, [patientDetails]);

  useEffect(() => {
    if (sessionStorage.getItem("patientDetails")) {
      setPatientDetails(JSON.parse(sessionStorage.getItem("patientDetails")));
      props.setPatientData(
        JSON.parse(sessionStorage.getItem("patientDetails"))
      );
    }
  }, [props.editMode]);

  useEffect(() => {
    //if saved data is there then update patient Details
    if (props.savedResults) {
      setPatientDetails((prev) => ({
        ...prev,
        ...props.savedResults.patientData,
        sid: props.savedResults.SID,
      }));
    }
    console.log(props.savedResults, patientDetails);
  }, [props.savedResults]);

  return (
    <Container className="p-3 pb-0">
      <Row>
        {props.savedResults && (
          <Col sm={2}>
            <Button
              color={props.editMode ? "danger" : "success"}
              onClick={() =>
                props.setEditMode(() => (props.editMode ? false : true))
              }
            >
              {`${props.editMode ? "Edit Mode On" : "Edit Mode Off"}`}
            </Button>
          </Col>
        )}
      </Row>
      <Form>
        <FormGroup>
          <Row className="border-bottom py-1">
            <Col xs="4" className="font-weight-bold">
              <Label for="name" className="m-0">
                Patient Name / Contact:
              </Label>
            </Col>
            <Col xs="4">
              <Input
                type="text"
                name="name"
                readOnly={!props.editMode && props.savedResults}
                id="name"
                placeholder="Patient Name"
                value={patientDetails.name}
                onChange={handleChange}
                className="mb-1"
                required
              />
              <FormFeedback>{errors.name}</FormFeedback>
            </Col>
            <Col>
              <Input
                type="text"
                name="contact"
                readOnly={!props.editMode && props.savedResults}
                id="contact"
                placeholder="Contact No"
                value={patientDetails.contact}
                onChange={handleChange}
                required
              />
              <FormFeedback>{errors.contact}</FormFeedback>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row className="border-bottom py-1">
            <Col xs="4" className="font-weight-bold">
              <Label for="age" className="m-0">
                Age / Sex:
              </Label>
            </Col>
            <Col xs="4">
              <Input
                type="text"
                name="age"
                id="age"
                readOnly={!props.editMode && props.savedResults}
                placeholder="Age"
                value={patientDetails.age}
                onChange={handleChange}
                required
              />
              <FormFeedback>{errors.age}</FormFeedback>
            </Col>
            <Col xs="4">
              <Input
                type="text"
                name="sex"
                id="sex"
                placeholder="Sex"
                readOnly={!props.editMode && props.savedResults}
                value={patientDetails.sex}
                onChange={handleChange}
                required
              />
              <FormFeedback>{errors.sex}</FormFeedback>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row className="border-bottom py-1">
            <Col xs="4" className="font-weight-bold">
              <Label for="referrer" className="m-0">
                Referrer:
              </Label>
            </Col>
            <Col xs="8">
              <Input
                type="text"
                name="referrer"
                id="referrer"
                readOnly={!props.editMode && props.savedResults}
                placeholder="Referrer"
                value={patientDetails.referrer}
                onChange={handleChange}
                required
              />
              <FormFeedback>{errors.referrer}</FormFeedback>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row className="border-bottom py-1">
            <Col xs="4" className="font-weight-bold">
              <Label for="branch" className="m-0">
                Branch:
              </Label>
            </Col>
            <Col xs="8">
              <Input
                type="text"
                name="branch"
                id="branch"
                placeholder="CLT"
                value={patientDetails.branch}
                readOnly={!props.editMode && props.savedResults}
                onChange={handleChange}
                required
              />
              <FormFeedback>{errors.branch}</FormFeedback>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row className="border-bottom py-1 right-align">
            <Col xs="4" className="font-weight-bold">
              <Label for="sid" className="m-0">
                SID No.:
              </Label>
            </Col>
            <Col xs="8" className="text-right">
              <Input
                type="text"
                name="sid"
                id="sid"
                value={patientDetails.sid}
                readOnly
                // onChange={handleChange}
              />
              <FormFeedback>{errors.sid}</FormFeedback>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row className="border-bottom py-1 right-align">
            <Col xs="4" className="font-weight-bold">
              <Label for="regDateTime" className="m-0">
                Reg Date & Time:
              </Label>
            </Col>
            <Col xs="8" className="text-right">
              <Input
                type="datetime-local"
                name="regDateTime"
                id="regDateTime"
                readOnly={!props.editMode && props.savedResults}
                value={patientDetails.regDateTime}
                onChange={handleChange}
                required
              />
              <FormFeedback>{errors.regDateTime}</FormFeedback>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row className="border-bottom py-1 right-align">
            <Col xs="4" className="font-weight-bold">
              <Label for="collDateTime" className="m-0">
                Coll Date & Time:
              </Label>
            </Col>
            <Col xs="8" className="text-right">
              <Input
                type="datetime-local"
                name="collDateTime"
                id="collDateTime"
                readOnly={!props.editMode && props.savedResults}
                value={patientDetails.collDateTime}
                onChange={handleChange}
                required
              />
              <FormFeedback>{errors.collDateTime}</FormFeedback>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row className="border-bottom py-1 right-align">
            <Col xs="4" className="font-weight-bold">
              <Label for="reportDateTime" className="m-0">
                Report Date & Time:
              </Label>
            </Col>
            <Col xs="8" className="text-right">
              <Input
                type="datetime-local"
                name="reportDateTime"
                id="reportDateTime"
                value={patientDetails.reportDateTime}
                readOnly={!props.editMode && props.savedResults}
                onChange={handleChange}
                required
              />
              <FormFeedback>{errors.reportDateTime}</FormFeedback>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </Container>
  );
});

export default PatientDetailsForm;
