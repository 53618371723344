// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialUser = () => {
  const item = window.sessionStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};
const initialBranch = () => {
  const item = window.sessionStorage.getItem("branchData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};
const initialApproval = () => {
  const item = window.localStorage.getItem("approvalData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const getbranchData = createAsyncThunk(
  "authentication/getbranchData",
  async (obj) => {
    const res = await axios.get(`${process.env.REACT_APP_CRI_API}branch`, {
      params: {
        branch: obj.branch,
      },
      headers: {
        Authorization: `Bearer ${obj.token}`,
      },
    });
    //console.log(res);
    return res.data;
  }
);

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
    branchData: initialBranch(),
    approvalData: initialApproval(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
      sessionStorage.removeItem("itemsData");
      sessionStorage.setItem("userData", JSON.stringify(action.payload));
    },
    handleLogout: (state) => {
      state.userData = {};
      sessionStorage.removeItem("itemsData");
      // ** Remove user, accessToken & refreshToken from sessionStorage
      sessionStorage.removeItem("userData");
    },
    handleApproval: (state, action) => {
      state.approvalData = action.payload;
      localStorage.setItem("approvalData", JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getbranchData.fulfilled, (state, action) => {
      state.branchData = action.payload;
      sessionStorage.setItem("branchData", JSON.stringify(action.payload));
      // state.bookmarks = action.payload.bookmarks
    });
  },
});

export const { handleLogin, handleLogout, handleBranch, handleApproval } =
  authSlice.actions;

export default authSlice.reducer;
