import React, { useState } from "react";
import { Table } from "reactstrap";
import { searchCustomers } from "../../../../Data/Customer/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { Eye, Trash2 } from "react-feather";
import axios from "axios";

const InvoiceTable = ({ invoices, getInvoicesHandler, selectedCM }) => {
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;
  const navigate = useNavigate();

  const [showBillLoading, setShowBillLoading] = useState(false);
  const [spinnerId, setSpinnerId] = useState("");

  const deleteHandler = (item) => {
    const invoiceId = prompt("Please enter the invoice Id to confirm delete");
    if (
      invoiceId &&
      invoiceId.toLocaleLowerCase() === item.invoice_id.toLocaleLowerCase()
    ) {
      axios
        .delete(`${process.env.REACT_APP_CRI_API}delete-invoice`, {
          params: { id: item._id, branch: userData.branch },
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then(() => {
          getInvoicesHandler();
        })
        .catch((err) => {
          if (
            (err.response.data &&
              err.response.data.err &&
              err.response.data.err.name === "TokenExpiredError") ||
            err.response.data.message === "Unauthorized"
          ) {
            navigate("/");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("srchData");
            sessionStorage.removeItem("prescriptionHistory");
            sessionStorage.removeItem("rights");
            navigate("/cri-crm");
            return err;
          }
        });
    } else if (invoiceId) {
      alert("Invoice Id not matching");
    }
  };

  const searchCustomerHandler = (invoice) => {
    setShowBillLoading(true);
    //token, searchInput, branch, navigate
    searchCustomers(
      userData.token,
      invoice.mrn,
      userData.branch,
      navigate
    ).then((res) => {
      if (res.length) {
        const [
          { fname, sname = "", sex = "", contactno, nationality, doctorName },
        ] = res;

        navigate("/billentry", {
          //replace: true,
          state: {
            otData: {
              ...invoice,
              fname: `${fname} ${sname}`,
              sex: sex,
              contactno: contactno,
              nationality,
              doctorName,
            },
            invoiceSheetData: invoice,
          },
        });
        setSpinnerId("");
        setShowBillLoading(false);
      }
    });
  };

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th colSpan="7">
            <spam style={{ fontWeight: "normal" }}> Patient:</spam>{" "}
            {selectedCM && `${selectedCM.fname} ${selectedCM.sname}`}
          </th>
        </tr>
        <tr>
          <th>Invoice Date</th>
          <th>Invoice ID</th>
          <th>Payment Mode</th>
          <th>Total Amount</th>
          <th>Total Amount Paid</th>
          <th>View/Edit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice, id) => (
          <tr key={id}>
            <td>{new Date(invoice.invoice_date).toLocaleDateString()}</td>
            <td>{invoice.invoice_id}</td>
            <td>
              {Array.isArray(invoice.payment_mode)
                ? invoice.payment_mode
                    .map((pm) => `${pm.mode}: ${pm.amount}`)
                    .join(", ")
                : invoice.payment_mode}
            </td>
            <td>{invoice.total_amount}</td>
            <td>{invoice.total_amount_paid}</td>
            <td>
              {showBillLoading && spinnerId === id && <Spinner />}
              {spinnerId !== id && (
                <Eye
                  color="green"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSpinnerId(id);
                    searchCustomerHandler(invoice);
                  }}
                />
              )}
            </td>
            <td>
              <Trash2
                color="red"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  deleteHandler(invoice);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default InvoiceTable;
