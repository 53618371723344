import axios from "axios";
import React, { useEffect, useState } from "react";
import { Edit, Search, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Table,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { getInventorySupplier } from "../../../../redux/inventory";
import { useNavigate } from "react-router-dom";
import SupplierForm from "./SupplierForm";

const Suppliers = () => {
  const itemsList = useSelector((state) => state.itemsList);
  const dispatch = useDispatch();
  const { supplierData } = itemsList;
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;

  const [formData, setFormData] = useState("");
  const [modal, setModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();

  const toggle = () => setModal(!modal);

  const editSupplierHandler = (Data, toggle) => {
    setFormData(Data);
    toggle();
  };

  const deleteHandler = (item) => {
    const supplierContact = prompt(
      "Please enter the supplier contact no. to confirm delete"
    );
    if (
      supplierContact &&
      supplierContact.trim() === item.supplierContact.trim()
    ) {
      axios
        .delete(`${process.env.REACT_APP_CRI_API}delete-supplier`, {
          params: { id: item._id, branch: userData.branch },
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((res) => {
          if (res) {
            dispatch(
              getInventorySupplier({
                token: userData.token,
                branch: userData.branch,
              })
            );
          }
        })
        .catch((err) => {
          if (
            (err.response.data &&
              err.response.data.err &&
              err.response.data.err.name === "TokenExpiredError") ||
            err.response.data.message === "Unauthorized"
          ) {
            navigate("/");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("srchData");
            sessionStorage.removeItem("prescriptionHistory");
            sessionStorage.removeItem("rights");
            navigate("/cri-crm");
            return err;
          }
        });
    } else if (supplierContact) {
      alert("Contact No. not matching");
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={2} className="pt-4">
          <Button
            outline
            color="primary"
            onClick={() => {
              setFormData("");
              toggle();
            }}
          >
            {" "}
            New Supplier
          </Button>
        </Col>
        <Col md={3} className="pt-4">
          {" "}
          <InputGroup className="mb-4">
            <Input
              // color="primary"
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search supplier Name"
            />
            <InputGroupText>
              {" "}
              <Search />
            </InputGroupText>
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {!supplierData.length && <Spinner color="primary" />}
          {supplierData && supplierData.length && (
            <Table responsive striped>
              <thead>
                <tr className="table-secondary">
                  <th>Sl No.</th>
                  <th style={{ width: "20%" }}>Supplier Name</th>
                  {
                    //<th>Brand Name</th>
                  }
                  <th>Contact No.</th>
                  <th>Address</th>
                  <th>State</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {supplierData
                  .filter(
                    (item) =>
                      !searchInput ||
                      item.supplierName
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                  )
                  .map((item, id) => {
                    return (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td>{item.supplierName}</td>
                        {
                          //<td>{item.brandName}</td>
                        }
                        <td>{item.supplierContact}</td>
                        <td>{item.supplierAddress}</td>
                        <td>{item.state}</td>
                        <td>
                          <Edit
                            color="green"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              // console.log(item);
                              editSupplierHandler(item, toggle);
                            }}
                          />
                        </td>
                        <td>
                          <Trash2
                            color="red"
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle} fullscreen>
        <ModalHeader toggle={toggle}>{formData && "Edit Supplier"}</ModalHeader>
        <ModalBody>
          <SupplierForm editFormData={formData} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default Suppliers;
