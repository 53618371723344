import axios from "axios";
import { useEffect, useState } from "react";
import { CreditCard, Plus, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { getDoctors, searchCustomers } from "../../../../Data/Customer/api";
import { useNavigate } from "react-router-dom";
import { getInventoryInPatient } from "../../../../redux/inventory";

const InPatient = (args) => {
  const itemsList = useSelector((state) => state.itemsList);
  const { itemsData, inPatientData } = itemsList;
  const auth = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const { userData } = auth;
  const [otData, setOTData] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const navigate = useNavigate();
  const [MRNlist, setMRNlist] = useState("");
  const [doctorsList, setDoctorsList] = useState("");
  const [patientName, setPatientName] = useState("");
  const [invoiceSheet, setInvoiceSheet] = useState([
    {
      Row1: { item: "", qty: "", rate: 0, tax: 0, amount: 0, cgst: 9, sgst: 9 },
    },
    {
      Row2: { item: "", qty: "", rate: 0, tax: 0, amount: 0, cgst: 9, sgst: 9 },
    },
    {
      Row3: { item: "", qty: "", rate: 0, tax: 0, amount: 0, cgst: 9, sgst: 9 },
    },
    {
      Row4: { item: "", qty: "", rate: 0, tax: 0, amount: 0, cgst: 9, sgst: 9 },
    },
    {
      Row5: { item: "", qty: "", rate: 0, tax: 0, amount: 0, cgst: 9, sgst: 9 },
    },
    {
      Row6: { item: "", qty: "", rate: 0, tax: 0, amount: 0, cgst: 9, sgst: 9 },
    },
  ]);

  const [formData, setFormData] = useState({
    mrn: "",
    doctor: "",
    date: new Date(),
    item: "",
    productId: "",
    quantity: "",
    createdBy: userData.user,
  });

  const nDate = new Date(formData.date);
  const datewithoutHours = new Date(nDate.setHours(0, 0, 0, 0)).toISOString();

  useEffect(() => {
    const fetchDoctorData = async () => {
      if (userData.branch && userData.token) {
        try {
          // or const respone = await getbranchData(branch);

          getDoctors(userData.token, userData.branch, navigate).then((res) => {
            setDoctorsList(res);
            localStorage.setItem("doctorsData", JSON.stringify(res));
          });
        } catch (error) {
          console.log(error.message);
        }
      }
    };
    if (!userData.token) {
      navigate("/");
    }
    fetchDoctorData();
  }, [userData.branch, userData.token]);

  useEffect(() => {
    if (formData.mrn && userData.token && userData.branch) {
      dispatch(
        getInventoryInPatient({
          token: userData.token,
          branch: userData.branch,
          mrn: formData.mrn,
        })
      );
    }
  }, [formData.mrn, userData.branch, userData.token]);

  useEffect(() => {
    if (
      MRNlist &&
      MRNlist.find((item) => item.mrn === formData.mrn) &&
      formData.mrn
    ) {
      setPatientName(() => {
        const obj = MRNlist.find((item) => item.mrn === formData.mrn);
        setOTData(obj);
        return `${obj.fname} ${obj.sname}`;
      });
    }
  }, [MRNlist, formData.mrn]);

  useEffect(() => {
    if (inPatientData.length) {
      setInvoiceSheet((prevInvoiceSheet) => {
        return prevInvoiceSheet.map((row, index) => {
          const item = inPatientData[index]; // Get the corresponding item from inPatientData
          if (item)
            return {
              ...row,
              [`Row${index + 1}`]: {
                item: item.item,
                rate: item.rate,
                qty: item.quantity,
                tax: item.tax,
                amount: item.amount,
                productId: item.productId,
                stockQty: item.stockQty,
              },
            };
          return row;
        });
      });

      //    setInvoiceSheet(() => {
      //      return inPatientData.map((item, index) => {
      //        console.log({
      //          [`Row${index + 1}`]: {
      //            item: item.item,
      //            rate: item.rate,
      //            qty: item.quantity,
      //            productId: item.productId,
      //            stockQty: item.stockQty,
      //          },
      //        });
      //        return {
      //          [`Row${index + 1}`]: {
      //            item: item.item,
      //            rate: item.rate,
      //            qty: item.quantity,
      //            productId: item.productId,
      //            stockQty: item.stockQty,
      //          },
      //        };
      //      });
      //    });
    }
  }, [inPatientData]);

  const handleInputProcedure = (e, rowIndex, colName, row) => {
    const { name, value } = e.target;
    const selectedOption = e.target.list.querySelector(
      `option[value="${value}"]`
    );
    const cost = selectedOption
      ? selectedOption.getAttribute("data-cost")
      : null;

    const productId = selectedOption
      ? selectedOption.getAttribute("data-productId")
      : null;

    const stockQty = selectedOption
      ? selectedOption.getAttribute("data-qty")
      : null;

    const word = "consult";
    let taxToggle = true;
    if (value.toLowerCase().includes(word.toLowerCase())) {
      taxToggle = false;
    } else {
      taxToggle = true;
    }

    //console.log(inputValues);
    const taxCal = ((Number(cost) * 18) / 100).toFixed(2);
    let taxTemp = taxToggle ? taxCal : 0;

    const rowTotal = (parseFloat(taxTemp) + parseFloat(cost)).toFixed(2);
    taxTemp = parseFloat(taxTemp) > 0 ? taxTemp : "";

    //setTaxOption([{ value: taxTemp, label: `${taxTemp} (18%)` }]);

    setInvoiceSheet((prevInvoiceSheet) => {
      return prevInvoiceSheet.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [`Row${rowIndex + 1}`]: {
              ...row[`Row${rowIndex + 1}`],
              [colName]: value,
              rate: cost,
              qty: value ? 1 : "",
              tax: value ? taxTemp : "",
              amount: value ? rowTotal : "",
              productId,
              stockQty,
            },
          };
        }
        return row;
      });
    });
  };

  const qtyHandler = (e, index) => {
    setInvoiceSheet((prevInvoiceSheet) => {
      return prevInvoiceSheet.map((row, rindex) => {
        if (rindex === index) {
          return {
            ...row,
            [`Row${index + 1}`]: {
              ...row[`Row${index + 1}`],
              qty: parseFloat(e.target.value),
            },
          };
        }
        return row;
      });
    });
  };

  const searchMRNHandler = (e) => {
    //token, searchInput, branch, navigate
    if (e.target.value.trim().length >= 5) {
      searchCustomers(
        userData.token,
        e.target.value,
        userData.branch,
        navigate
      ).then((res) => {
        if (res.length) {
          setMRNlist(res);
        } else {
          alert("No Matching MRN found");
        }
      });
    } else {
      //  alert("Enter minimun 5 char for MRN to search");
    }
  };

  const handleChange = (e, colName) => {
    const { name, value } = e.target;

    if (colName === "item") {
      const selectedOption = e.target.list.querySelector(
        `option[value="${value}"]`
      );
      const cost = selectedOption
        ? selectedOption.getAttribute("data-cost")
        : null;

      const productId = selectedOption
        ? selectedOption.getAttribute("data-productid")
        : null;

      const stockQty = selectedOption
        ? selectedOption.getAttribute("data-qty")
        : null;

      //   rate: cost,
      //   qty: value ? 1 : "",
      //   tax: value ? taxTemp : "",
      //   amount: value ? rowTotal : "",
      //   productId,
      //   stockQty,
      setFormData((prevFormData) => ({
        ...prevFormData,
        item: value,
        rate: cost,
        productId,
        stockQty,
      }));
      return;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  function isValidDate(dateString) {
    // Try to create a new Date object from the input string
    const date = new Date(dateString);

    // Check if the date is valid and the input string was successfully parsed
    return !isNaN(date.getTime());
  }

  const validateForm = () => {
    let isValid = true;

    //  doctor: "",
    //  date: new Date(),
    //  item:"",
    //  productId:"",
    if (formData.doctor.trim() === "") {
      isValid = false;
      // Handle validation error for item field
    }

    if (formData.mrp && formData.doctor.trim() === "") {
      isValid = false;
      // Handle validation error for unitPrice field
    }
    if (formData.quantity.trim() === "" || isNaN(formData.quantity)) {
      isValid = false;
    }

    if (formData.productId.trim() === "") {
      isValid = false;
    }

    // Add more validation logic for other fields

    return isValid;
  };

  const submitFormHandler = () => {
    if (validateForm()) {
      axios
        .post(`${process.env.REACT_APP_CRI_API}add-inpatient`, formData, {
          params: {
            branch: userData.branch,
          },
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            alert("save success");
            toggle();
            dispatch(
              getInventoryInPatient({
                token: userData.token,
                branch: userData.branch,
                mrn: formData.mrn,
              })
            );
            // clearForm();
          }
        })
        .catch((err) => console.log(err));
    } else {
      alert("Invalid Form");
      return;
    }
  };
  const deleteHandler = (id) => {
    // setId(apmnt._id);
    axios
      .delete(`${process.env.REACT_APP_CRI_API}delete-inpatient`, {
        params: { id: id, branch: userData.branch },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        if (res) {
          dispatch(
            getInventoryInPatient({
              token: userData.token,
              branch: userData.branch,
              mrn: formData.mrn,
            })
          );
          // toggle();
          //   setAppointmentData(res.data);
          // setBookedSlots("");
        }
      });
  };

  const findItemValue = (v, item) => {
    const product = itemsData.find((prod) => prod.productId === item.productId);
    if (!product) return null;

    if (v === "expiry") {
      return product.expiry;
    } else if (v === "mrp") {
      return product.mrp;
    }

    return null;
  };

  return (
    <Container>
      <Row>
        <Col>
          <Card className="mb-5 mt-2">
            <CardHeader>
              <Row className="d-flex justify-content-center">
                <Col md={4}>
                  <Input
                    type="text"
                    //  id="MRN"
                    list="mrnlist"
                    name="mrn"
                    placeholder="Search MRN"
                    // value={formData.mrn}
                    onChange={(e) => {
                      searchMRNHandler(e);
                      handleChange(e);
                    }}
                    //    onBlur={searchMRNHandler}
                    style={{ display: "inline-block", width: "150px" }}
                  />
                  <datalist id="mrnlist">
                    {MRNlist &&
                      MRNlist.map((item, id) => {
                        return <option key={id} value={item.mrn} />;
                      })}
                  </datalist>
                  <span className="ps-3">
                    <b>{patientName}</b>
                  </span>
                </Col>
                <Col md={4} style={{ textAlign: "center" }}>
                  <h4>In Patient Record</h4>
                </Col>
                <Col md={4} className="d-flex ">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (formData.mrn && MRNlist.length) {
                        if (MRNlist.find((item) => item.mrn === formData.mrn)) {
                          const obj = MRNlist.find(
                            (item) => item.mrn === formData.mrn
                          );
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            patientName: `${obj.fname} ${obj.sname}`,
                          }));
                          toggle();
                          return;
                        }
                        alert("Pls select MRN");
                        return;
                      }
                      alert("Pls select MRN");
                    }}
                  >
                    <Plus color="green" /> Add
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="ms-4"
                    onClick={() => {
                      navigate("/billentry", {
                        //replace: true,
                        state: {
                          otData,
                          token: userData.token,
                          // customerData: props.customerData,
                          invoiceSheetData: invoiceSheet,
                          branch: userData.branch,
                        },
                      });
                    }}
                  >
                    <CreditCard className="me-2" color="blue" />
                    Invoice
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Table>
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Doctor</th>
                    <th>Item</th>
                    <th>Route</th>
                    <th>Dosage</th>
                    <th>Qty</th>
                    <th>Expiry</th>
                    <th>MRP</th>
                  </tr>
                </thead>
                <tbody>
                  {inPatientData.length > 0 &&
                    formData.mrn &&
                    inPatientData.map((item, id) => {
                      return (
                        <tr>
                          <td>{id + 1}</td>
                          <td>{item.doctor}</td>
                          <td>{item.item}</td>
                          <td></td>
                          <td></td>
                          <td>{item.quantity}</td>
                          <td>{findItemValue("expiry", item)}</td>
                          <td
                            style={{ textAlign: "right" }}
                            className="d-flex justify-content-between"
                          >
                            <span> {findItemValue("mrp", item)}</span>
                            <Trash2
                              color="red"
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteHandler(item._id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={modal}
        toggle={toggle}
        {...args}
        style={{ maxWidth: "800px", margin: "0 auto" }}
      >
        <ModalHeader toggle={toggle}>Add Item</ModalHeader>
        <ModalBody>
          <Row>
            <Label for="MRN" style={{ width: "100px" }}>
              MRN
            </Label>
            <Col md={4}>
              <Input
                id="MRN"
                name="mrn"
                value={formData.mrn}
                readOnly
                style={{ display: "inline-block" }}
              />
            </Col>
            <Label for="doctor" style={{ width: "100px" }}>
              Doctor
            </Label>
            <Col md={4}>
              <Input
                type="select"
                id="doctor"
                name="doctor"
                value={formData.doctor}
                onChange={handleChange}
                style={{ display: "inline-block" }}
              >
                {" "}
                <option value="">Select Doctor</option>
                {doctorsList.length &&
                  doctorsList.map((item, key) => (
                    <option key={key} value={item.doctorName}>
                      {item.doctorName}
                    </option>
                  ))}
              </Input>
            </Col>
          </Row>
          <Row className="pt-3">
            <Label for="item" style={{ width: "100px" }}>
              Item
            </Label>
            <Col md={4}>
              <Input
                type="text"
                list="data"
                id="Row1item"
                //  readOnly={MRN !== ""}
                //  value={row[`Row${index + 1}`].item}
                onChange={(e) => {
                  handleChange(e, "item");
                }}
              />

              <datalist id="data">
                {itemsData.length &&
                  itemsData.map((item, id) => {
                    return (
                      <option
                        key={id}
                        value={item.item}
                        data-cost={item.mrp}
                        data-productid={item.productId}
                        data-qty={item.quantity}
                      />
                    );
                  })}
              </datalist>
            </Col>

            <Label for="doctor" style={{ width: "100px" }}>
              Qty
            </Label>
            <Col md={4}>
              <Input
                id="qty"
                type="number"
                name="quantity"
                value={formData.quantity}
                onChange={(e) => {
                  if (Number(e.target.value) > Number(formData.stockQty)) {
                    alert("Insufficient stock");
                    return;
                  }
                  handleChange(e);

                  qtyHandler(e, 0, "qty");
                }}
                style={{ display: "inline-block" }}
              />
            </Col>
          </Row>
          <Row className="pt-3">
            <Label for="notes" style={{ width: "100px" }}>
              Date
            </Label>
            <Col md={3}>
              <Input
                type="date"
                id="notes"
                name="date"
                value={formData.date}
                onChange={handleChange}
                style={{ display: "inline-block" }}
              />
            </Col>
          </Row>
          <Row className="pt-3">
            <Label for="notes" style={{ width: "100px" }}>
              Notes
            </Label>
            <Col md={9}>
              <Input
                id="notes"
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                style={{ display: "inline-block" }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={submitFormHandler}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};
export default InPatient;
