import axios from "axios";
import { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { Table, Button, Input } from "reactstrap";

const LabEntry = ({ getLabItemsHandler, toggle, editData }) => {
  const auth = useSelector((state) => state.authentication);
  const lab = useSelector((state) => state.lab);

  const { labCategory } = lab;
  const { userData } = auth;

  const [errors, setErrors] = useState([]);
  const [category, setCategory] = useState([
    {
      categoryName: "",
      tests: [
        {
          testName: "",
          subTests: [
            {
              subTestName: "",
              specimen: "",
              referenceRange: "",
              units: "",
              method: "",
              notes: "",
            },
          ],
        },
      ],
      notes: "",
      createdBy: userData.user,
    },
  ]);

  useEffect(() => {
    if (editData) {
      setCategory([editData]);
    }
  }, [editData]);

  const handleInputChange = (
    categoryIndex,
    testIndex,
    subTestIndex,
    field,
    value
  ) => {
    const updatedCategory = [...category];
    updatedCategory[categoryIndex].tests[testIndex].subTests[subTestIndex][
      field
    ] = value;
    setCategory(updatedCategory);
  };

  const handleCategoryChange = (categoryIndex, field, value) => {
    const updatedCategory = [...category];
    updatedCategory[categoryIndex][field] = value;
    setCategory(updatedCategory);
  };

  const handleTestChange = (categoryIndex, testIndex, field, value) => {
    const updatedCategory = [...category];
    updatedCategory[categoryIndex].tests[testIndex][field] = value;
    setCategory(updatedCategory);
  };

  const addRow = () => {
    setCategory([
      ...category,
      {
        categoryName: "",
        tests: [
          {
            testName: "",
            subTests: [
              {
                subTestName: "",
                specimen: "",
                referenceRange: "",
                units: "",
                method: "",
                notes: "",
              },
            ],
          },
        ],
        createdBy: userData.user,
      },
    ]);
  };

  const validateCategory = () => {
    const newErrors = [];

    category.forEach((categoryItem, categoryIndex) => {
      const categoryErrors = {};

      if (!categoryItem.categoryName.trim()) {
        categoryErrors.categoryName = "Category name is required";
      }

      categoryItem.tests.forEach((test, testIndex) => {
        const testErrors = {};

        if (!test.testName.trim()) {
          testErrors.testName = "Test name is required";
        }

        test.subTests.forEach((subTest, subTestIndex) => {
          const subTestErrors = {};

          if (!subTest.subTestName.trim()) {
            subTestErrors.subTestName = "Sub-test name is required";
          }
          if (!subTest.specimen.trim()) {
            subTestErrors.specimen = "Specimen is required";
          }
          if (!subTest.referenceRange.trim()) {
            subTestErrors.referenceRange = "Reference range is required";
          }
          if (!subTest.units.trim()) {
            subTestErrors.units = "Units are required";
          }
          if (!subTest.method.trim()) {
            subTestErrors.method = "Method is required";
          }

          if (Object.keys(subTestErrors).length > 0) {
            if (!testErrors.subTests) testErrors.subTests = [];
            testErrors.subTests[subTestIndex] = subTestErrors;
          }
        });

        if (Object.keys(testErrors).length > 0) {
          if (!categoryErrors.tests) categoryErrors.tests = [];
          categoryErrors.tests[testIndex] = testErrors;
        }
      });

      if (Object.keys(categoryErrors).length > 0) {
        newErrors[categoryIndex] = categoryErrors;
      }
    });

    setErrors(newErrors);

    return newErrors.length === 0;
  };

  const submitHandler = () => {
    if (!validateCategory()) return;

    const apiUrl = editData
      ? `${process.env.REACT_APP_CRI_API}edit-lab`
      : `${process.env.REACT_APP_CRI_API}add-lab`;

    const requests = category.map((categoryItem) =>
      axios.post(apiUrl, categoryItem, {
        params: {
          branch: userData.branch,
          ...(editData && { id: categoryItem._id }),
        },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
    );

    axios
      .all(requests)
      .then((responses) => {
        responses.forEach(() => {
          getLabItemsHandler();
          toggle();
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "15%" }}>Category Name</th>
            <th style={{ width: "17%" }}>Test Name</th>
            <th style={{ width: "15%" }}>Sub Test Name</th>
            <th style={{ width: "10%" }}>Specimen</th>
            <th style={{ width: "10%" }}>Reference Range</th>
            <th style={{ width: "10%" }}>Units</th>
            <th style={{ width: "10%" }}>Method</th>
            <th style={{ width: "20%" }}>Notes</th>
          </tr>
        </thead>
        <tbody>
          {category.map((cat, categoryIndex) => (
            <Fragment key={cat._id || categoryIndex}>
              {cat.tests.map((test, testIndex) => (
                <Fragment key={testIndex}>
                  {test.subTests.map((subTest, subTestIndex) => (
                    <tr key={subTestIndex}>
                      {subTestIndex === 0 && (
                        <td rowSpan={test.subTests.length}>
                          <Input
                            type="select"
                            value={cat.categoryName}
                            onChange={(e) =>
                              handleCategoryChange(
                                categoryIndex,
                                "categoryName",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Select a category</option>
                            {labCategory.map((labCat) => (
                              <option
                                key={labCat._id}
                                value={labCat.categoryName}
                              >
                                {labCat.categoryName}
                              </option>
                            ))}
                          </Input>
                          {errors[categoryIndex]?.categoryName && (
                            <div className="text-danger">
                              {errors[categoryIndex].categoryName}
                            </div>
                          )}
                        </td>
                      )}
                      {subTestIndex === 0 && (
                        <td rowSpan={test.subTests.length}>
                          <Input
                            type="text"
                            value={test.testName}
                            onChange={(e) =>
                              handleTestChange(
                                categoryIndex,
                                testIndex,
                                "testName",
                                e.target.value
                              )
                            }
                          />
                          {errors[categoryIndex]?.tests?.[testIndex]
                            ?.testName && (
                            <div className="text-danger">
                              {errors[categoryIndex].tests[testIndex].testName}
                            </div>
                          )}
                        </td>
                      )}
                      <td>
                        <Input
                          type="text"
                          value={subTest.subTestName}
                          onChange={(e) =>
                            handleInputChange(
                              categoryIndex,
                              testIndex,
                              subTestIndex,
                              "subTestName",
                              e.target.value
                            )
                          }
                        />
                        {errors[categoryIndex]?.tests?.[testIndex]?.subTests?.[
                          subTestIndex
                        ]?.subTestName && (
                          <div className="text-danger">
                            {
                              errors[categoryIndex].tests[testIndex].subTests[
                                subTestIndex
                              ].subTestName
                            }
                          </div>
                        )}
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={subTest.specimen}
                          onChange={(e) =>
                            handleInputChange(
                              categoryIndex,
                              testIndex,
                              subTestIndex,
                              "specimen",
                              e.target.value
                            )
                          }
                        />
                        {errors[categoryIndex]?.tests?.[testIndex]?.subTests?.[
                          subTestIndex
                        ]?.specimen && (
                          <div className="text-danger">
                            {
                              errors[categoryIndex].tests[testIndex].subTests[
                                subTestIndex
                              ].specimen
                            }
                          </div>
                        )}
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={subTest.referenceRange}
                          onChange={(e) =>
                            handleInputChange(
                              categoryIndex,
                              testIndex,
                              subTestIndex,
                              "referenceRange",
                              e.target.value
                            )
                          }
                        />
                        {errors[categoryIndex]?.tests?.[testIndex]?.subTests?.[
                          subTestIndex
                        ]?.referenceRange && (
                          <div className="text-danger">
                            {
                              errors[categoryIndex].tests[testIndex].subTests[
                                subTestIndex
                              ].referenceRange
                            }
                          </div>
                        )}
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={subTest.units}
                          onChange={(e) =>
                            handleInputChange(
                              categoryIndex,
                              testIndex,
                              subTestIndex,
                              "units",
                              e.target.value
                            )
                          }
                        />
                        {errors[categoryIndex]?.tests?.[testIndex]?.subTests?.[
                          subTestIndex
                        ]?.units && (
                          <div className="text-danger">
                            {
                              errors[categoryIndex].tests[testIndex].subTests[
                                subTestIndex
                              ].units
                            }
                          </div>
                        )}
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={subTest.method}
                          onChange={(e) =>
                            handleInputChange(
                              categoryIndex,
                              testIndex,
                              subTestIndex,
                              "method",
                              e.target.value
                            )
                          }
                        />
                        {errors[categoryIndex]?.tests?.[testIndex]?.subTests?.[
                          subTestIndex
                        ]?.method && (
                          <div className="text-danger">
                            {
                              errors[categoryIndex].tests[testIndex].subTests[
                                subTestIndex
                              ].method
                            }
                          </div>
                        )}
                      </td>
                      <td>
                        <Input
                          type="textarea"
                          value={subTest.notes}
                          onChange={(e) =>
                            handleInputChange(
                              categoryIndex,
                              testIndex,
                              subTestIndex,
                              "notes",
                              e.target.value
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </Fragment>
          ))}
        </tbody>
      </Table>
      <Button color="primary" onClick={addRow}>
        Add Row
      </Button>
      <Button color="success" onClick={submitHandler}>
        Save
      </Button>
    </div>
  );
};

export default LabEntry;
