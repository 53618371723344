// ** Reducers Imports
import itemsList from "./inventory";
import authentication from "./authentication";
import lab from "./lab";

const rootReducer = {
  authentication,
  itemsList,
  lab,
};

export default rootReducer;
