import React from "react";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import "../styles/style.css";

const LoadingModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Downloading PDFs Zip</ModalHeader>
      <ModalBody
        className="d-flex justify-content-center align-items-center"
        style={{ height: "200px" }}
      >
        <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
      </ModalBody>
    </Modal>
  );
};

export default LoadingModal;
