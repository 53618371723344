import { Calendar, Search } from "react-feather";
import "./styles/style.css";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, React, version, Fragment } from "react";
import { useSelector } from "react-redux";
import { searchInvoices } from "../../../Data/Customer/api";
import InvoiceTable from "./NavLeft/InvoiceTable";

const NavLeft = (props, args) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState("");
  const [modal, setModal] = useState(false);
  const [selectedCM, setSelectedCM] = useState("");
  const [otData, setOTData] = useState("");
  const [modalName, setModalName] = useState("search");
  const [paymentHistory, setPaymentHistory] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const toggleLoadingModal = () => setLoadingModal(!loadingModal);
  //const token =
  //sessionStorage.getItem("token")
  // ? JSON.parse(sessionStorage.getItem("token"))
  //: "";
  const branch = sessionStorage.getItem("branch")
    ? JSON.parse(sessionStorage.getItem("branch"))
    : props.branch;

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (sessionStorage.getItem("srchData")) {
      // console.log(JSON.parse(sessionStorage.getItem("srchData")));
      setSelectedCM(() =>
        sessionStorage.getItem("srchData")
          ? JSON.parse(sessionStorage.getItem("srchData"))
          : ""
      );
      setOTData(() =>
        sessionStorage.getItem("srchData")
          ? JSON.parse(sessionStorage.getItem("srchData"))
          : ""
      );
    }
  }, [sessionStorage.getItem("srchData")]);

  const searchHandler = (searchInput) => {
    axios
      .get(`${process.env.REACT_APP_CRI_API}searchcustomers`, {
        params: {
          branch: branch,
          search: searchInput,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      })
      .then((res) => {
        //console.log(res);
        setSearchResults(res.data);
        props.customerDataHandler(res.data);
        if (props.mrn) {
          const temp = res.data.filter((item) => item.mrn === props.mrn);
          //  console.log(temp);
          setOTData(temp[0]);
        }
      })
      .catch((err) => {
        if (
          (err.response?.data &&
            err.response.data?.err.name === "TokenExpiredError") ||
          err.response.data.message === "Unauthorized"
        ) {
          navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          navigate("/cri-crm");
          return err;
        }
      });
  };

  useEffect(() => {
    if (props.mrn && searchResults.length) {
      const temp = searchResults.filter((item) => item.mrn === props.mrn);
      setOTData(temp[0]);
    }
  }, [props.mrn, searchResults]);

  useEffect(() => {
    const mobileNo = props.contactno;
    if (mobileNo && mobileNo.length > 9 && !props.mrn) {
      searchHandler(mobileNo);
      setModalName("search");
      toggle();
    }
  }, [props.contactno]); // added recently for repeated mobile number entry

  useEffect(() => {
    if (sessionStorage.getItem("srchData")) {
      try {
        setOTData(JSON.parse(sessionStorage.getItem("srchData")));
      } catch (err) {
        console.log(err);
      }
    }
  }, [sessionStorage.getItem("srchData")]);

  useEffect(() => {
    if (selectedCM) {
      props.searchUpdateHandler(selectedCM);
      setOTData(selectedCM);
    }
    //console.log(props);
  }, [selectedCM]);

  const ModalContent = () => {
    if (modalName === "search") {
      return (
        <Fragment>
          <ModalHeader toggle={toggle}>Search Results</ModalHeader>
          <ModalBody>
            <Table>
              <thead>
                <tr>
                  <th>Patient Name</th>
                  <th>Contact No</th>
                  <th>Address</th>
                  <th>Treatment Type</th>
                  <th>MRN</th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>
                {searchResults &&
                  searchResults.map((cm, id) => {
                    return (
                      <tr key={id}>
                        <td>
                          {cm.fname} {cm.sname}
                        </td>
                        <td>{cm.contactno}</td>
                        <td>{cm.address}</td>
                        <td>{cm.treatmenttype}</td>
                        <td>{cm.mrn}</td>
                        <td>
                          {
                            <Input
                              type="radio"
                              name="select"
                              checked={selectedCM?.mrn === cm?.mrn}
                              // checked={selectedCM?.mrn}
                              onChange={() => {
                                setSelectedCM(cm);
                                sessionStorage.setItem(
                                  "srchData",
                                  JSON.stringify(cm)
                                );
                              }}
                            />
                          }
                        </td>
                      </tr>
                    );
                  })}
                {!searchResults && !paymentHistory && (
                  <tr>
                    <td>
                      <Spinner />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <button className="glass-button" onClick={toggle}>
              Ok
            </button>
            <button className="glass-button" onClick={toggle}>
              Cancel
            </button>
          </ModalFooter>
        </Fragment>
      );
    } else if (modalName === "otBooking") {
      return (
        <Fragment>
          <ModalHeader toggle={toggle}>OT Booking</ModalHeader>
          <ModalBody>
            <Row>
              <Col></Col>
              <Col></Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <button className="glass-button" onClick={toggle}>
              Ok
            </button>
            <button className="glass-button" onClick={toggle}>
              Cancel
            </button>
          </ModalFooter>
        </Fragment>
      );
    } else if (modalName === "Payment History") {
      return (
        <Fragment>
          <ModalHeader toggle={toggle}>{modalName}</ModalHeader>
          <ModalBody>
            {paymentHistory && (
              <InvoiceTable
                invoices={paymentHistory}
                getInvoicesHandler={getInvoicesHandler}
                selectedCM={selectedCM}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <button className="glass-button" onClick={toggle}>
              Ok
            </button>
            <button className="glass-button" onClick={toggle}>
              Cancel
            </button>
          </ModalFooter>
        </Fragment>
      );
    } else {
      return "";
    }
  };

  const getInvoicesHandler = () => {
    setLoadingModal(true);
    //searchInvoices = async (token, searchInput, branch, navigate)
    searchInvoices(
      userData.token,
      selectedCM.mrn,
      userData.branch,
      navigate
    ).then((res) => {
      setPaymentHistory(res);
      setModalName("Payment History");
      toggle();
      setLoadingModal(false);
    });
  };

  return (
    <Col md="2" className="l-nav-bg">
      <Row>
        <Col md="12">
          <InputGroup className="mb-4 ">
            <Input
              placeholder="search patient"
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
            <InputGroupText style={{ background: "rgba(50, 0, 100, 0.7)" }}>
              {" "}
              <Search
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  if (searchInput) {
                    searchHandler(searchInput);
                    setModalName("search");
                    toggle();
                  }
                }}
              />
            </InputGroupText>
          </InputGroup>
          <Row
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
            className="pb-3"
          >
            <button
              className="glass-button"
              onClick={() => {
                if (otData && otData.mrn) {
                  navigate("/billentry", {
                    //replace: true,
                    state: {
                      otData,
                      token: props.token,
                      customerData: props.customerData,
                      branch: props.branch,
                    },
                  });
                } else {
                  alert("please select a customer");
                }
              }}
            >
              Generate Invoice
            </button>
          </Row>
          <Row
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
            className="pb-3"
          >
            <button
              className="glass-button"
              onClick={() => {
                navigate("/invoices");
              }}
            >
              View Invoices
            </button>
          </Row>
          <Row
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
            className="pb-3"
          >
            <button
              className="glass-button"
              onClick={() => {
                navigate("/rtn-invoices");
              }}
            >
              View Return Invoices
            </button>
          </Row>
          <Row
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
            className="pb-3"
          >
            <button className="glass-button" onClick={getInvoicesHandler}>
              Patient Invoice History
            </button>
          </Row>

          <Row
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
            className="pb-3"
          >
            <button
              className="glass-button"
              onClick={() => {
                navigate("/otBooking", {
                  //replace: true,
                  state: {
                    otData,
                    token: props.token,
                    customerData: props.customerData,
                    doctorsList: props.doctorsList,
                    branch: props.branch,
                  },
                });
              }}
            >
              OT Booking
            </button>
          </Row>

          {
            // (userData.rights === "Admin" ||
            // userData.rights === "Accounts" ||
            // userData.rights === "Manager") &&
            <Row
              style={{ paddingLeft: "12px", paddingRight: "12px" }}
              className="pb-3"
            >
              <button
                className="glass-button"
                onClick={() => {
                  navigate("/phmcy-admin", {
                    //replace: true,
                    state: {
                      otData,
                      token: props.token,
                      customerData: props.customerData,
                      branch: props.branch,
                    },
                  });
                }}
              >
                Pharmacy
              </button>
            </Row>
          }
          <Row
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
            className="pb-3"
          >
            <button
              className="glass-button"
              onClick={() => {
                navigate("/lab-admin", {
                  //replace: true,
                  state: {
                    otData,
                    token: props.token,
                    customerData: props.customerData,
                    branch: props.branch,
                  },
                });
              }}
            >
              Lab
            </button>
          </Row>

          <Row
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
            className="pb-3"
          >
            <button
              className="glass-button"
              onClick={() => {
                navigate("/ccare", {
                  //replace: true,
                  state: {
                    otData,
                    token: props.token,
                    customerData: props.customerData,
                    branch: props.branch,
                  },
                });
              }}
            >
              Patient Care
            </button>
          </Row>
          <Row
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
            className="pb-3"
          >
            <button
              className="glass-button"
              onClick={() => {
                navigate("/customer_form", {
                  //replace: true,
                  state: {
                    otData,
                    token: props.token,
                    customerData: props.customerData,
                    branch: props.branch,
                  },
                });
              }}
            >
              Patient Form
            </button>
          </Row>
          <Row
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
            className="pb-3"
          >
            {" "}
            {
              //(userData.rights === "Admin" || userData.rights === "Manager") &&
              <button
                className="glass-button"
                onClick={() =>
                  navigate("/reports", {
                    replace: true,
                    state: { branch: props.branch, token: props.token },
                  })
                }
              >
                Daily Reports
              </button>
            }
          </Row>
          <Row
            style={{ paddingLeft: "12px", paddingRight: "12px" }}
            className="pb-3"
          >
            {" "}
            {
              //(userData.rights === "Admin" || userData.rights === "Manager") &&
              <button
                className="glass-button"
                onClick={() =>
                  navigate("/queue-management", {
                    // replace: true,
                    state: { branch: props.branch, token: props.token },
                  })
                }
              >
                Queue Management
              </button>
            }
          </Row>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle} style={{ minWidth: "70%" }}>
        {<ModalContent />}
      </Modal>
      <Modal
        isOpen={loadingModal}
        toggle={toggleLoadingModal}
        centered
        {...args}
      >
        <ModalHeader toggle={toggleLoadingModal}>loading</ModalHeader>
        <ModalBody className="text-center">
          <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
          <p className="mt-2">Please wait while your data is loading...</p>
        </ModalBody>
        <ModalFooter>
          <button className="glass-button" onClick={toggleLoadingModal}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </Col>
  );
};

export default NavLeft;
