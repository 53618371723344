// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

const initialLabData = () => {
  const lab = window.sessionStorage.getItem("labData");
  //** Parse stored json or if none return initialValue
  return lab ? JSON.parse(lab) : {};
};

const initialLabCategory = () => {
  const lab = window.sessionStorage.getItem("labCategory");
  //** Parse stored json or if none return initialValue
  return lab ? JSON.parse(lab) : {};
};

export const getLabItems = createAsyncThunk("lab/getLabItems", async (obj) => {
  const response = await axios
    .get(`${process.env.REACT_APP_CRI_API}lab`, {
      params: {
        branch: obj.branch,
      },
      headers: { Authorization: `Bearer ${obj.token}` },
      "Content-Type": "application/json",
    })
    .then((res) => {
      // console.log(res);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
  return response;
});
export const getLabCategory = createAsyncThunk(
  "lab/getLabCategory",
  async (obj) => {
    const response = await axios
      .get(`${process.env.REACT_APP_CRI_API}lab-cat`, {
        params: {
          branch: obj.branch,
        },
        headers: { Authorization: `Bearer ${obj.token}` },
        "Content-Type": "application/json",
      })
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return response;
  }
);

export const labSlice = createSlice({
  name: "lab",
  initialState: {
    labData: initialLabData(),
    labCategory: initialLabCategory(),
    error: null,
  },
  // reducers: {
  //   handleLabItems: (state, action) => {
  //     state.labData = action.payload;
  //     sessionStorage.setItem("lab", JSON.stringify(action.payload));
  //   },
  //   handleError: (state) => {
  //     state.error = "";
  //   },
  // },
  extraReducers: (builder) => {
    builder
      .addCase(getLabItems.fulfilled, (state, action) => {
        state.labData = action.payload;
        sessionStorage.setItem("labData", JSON.stringify(action.payload));
        // state.bookmarks = action.payload.bookmarks
      })
      .addCase(getLabItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error; // You can customize how you handle the error message here
      })
      .addCase(getLabCategory.fulfilled, (state, action) => {
        state.labCategory = action.payload;
        sessionStorage.setItem("labCategory", JSON.stringify(action.payload));
        // state.bookmarks = action.payload.bookmarks
      })
      .addCase(getLabCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error; // You can customize how you handle the error message here
      });

    //  .addCase(updateBookmarked.fulfilled, (state, action) => {
    //    let objectToUpdate
    //    // ** find & update object
    //    state.suggestions.find(item => {
    //      if (item.id === action.payload) {
    //        item.isBookmarked = !item.isBookmarked
    //        objectToUpdate = item
    //      }
    //    })

    // ** Get index to add or remove bookmark from array
    //  const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.payload)

    //  if (bookmarkIndex === -1) {
    //    state.bookmarks.push(objectToUpdate)
    //  } else {
    //    state.bookmarks.splice(bookmarkIndex, 1)
    //  }
    //})
  },
});

export const { handleLabItems, handleError } = labSlice.actions;

export default labSlice.reducer;
