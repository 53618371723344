import axios from "axios";
import { useEffect, useState } from "react";
import validator from "validator";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { getCustomerCareList } from "../../../Data/Customer/api";
import { useNavigate } from "react-router-dom";

const Ccare = () => {
  const navigate = useNavigate();
  const token = JSON.parse(sessionStorage.getItem("token"));
  const branch = JSON.parse(sessionStorage.getItem("branch"));
  const [username] = useState(
    JSON.parse(sessionStorage.getItem("username")) || ""
  );
  const [customerName, setCustomerName] = useState("");
  const [contactNo, setContactno] = useState("");
  const [location, setLocation] = useState("");
  const [title, setTitle] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [emailId, setEmailId] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const [customerHistory, setCustomerHistory] = useState("");
  const [source, setSource] = useState("");
  const date = new Date();
  const agentName = username;
  let savedToggle = false;

  function formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      "  " +
      strTime
    );
  }

  let postData = {
    customerName,
    contactNo,
    location,
    title,
    addressLine1,
    addressLine2,
    pinCode,
    state,
    country,
    emailId,
    enquiry,
    date,
    agentName,
    source,
    createdBy: username,
  };
  const isValidForm = () => {
    if (customerName && customerName.length > 2) {
      if (contactNo && contactNo.length > 9) {
        if (enquiry && enquiry.length > 5) {
          return true;
        } else {
          alert("Please mention enquiry");
          return false;
        }
      } else {
        alert("Invalid Form");
        return false;
      }
    } else {
      alert("Invalid Form");

      return false;
    }
  };
  const submitHandler = () => {
    const valid = isValidForm();
    if (savedToggle) {
      alert("Already Saved");
      return;
    }
    if (valid) {
      axios
        .post(`${process.env.REACT_APP_CRI_API}add-customercare`, postData, {
          params: {
            branch: branch,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.status == 200);
          if (res.status == 200) {
            alert("Save Success");
            savedToggle = true;
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          if (
            (err.response.data &&
              err.response.data.err.name === "TokenExpiredError") ||
            err.response.data.message === "Unauthorized"
          ) {
            navigate("/");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("srchData");
            sessionStorage.removeItem("prescriptionHistory");
            sessionStorage.removeItem("rights");
            navigate("/cri-crm");
            return err;
          }
        });
    } else {
      return;
    }
  };
  useEffect(() => {
    if (contactNo) {
      const contactIsValid = validator.isMobilePhone(contactNo);
      if (contactIsValid) {
        // token, branch, contactno, date, navigate
        getCustomerCareList(token, branch, contactNo, "", navigate).then(
          (res) => {
            setCustomerHistory(res);
            console.log(res);
          }
        );
      }
    }
  }, [contactNo]);

  return (
    <Container>
      <Card>
        <CardHeader className="bg-primary mt-2 text-white">
          <Row>
            <Col md={3}> </Col>
            <Col md={6} style={{ textAlign: "center" }}>
              <h4>Patient Care</h4>
            </Col>
            <Col md={3} style={{ textAlign: "right" }}>
              <p>User: {username}</p>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row className="pb-4">
            <Col sm={1}>
              <Label htmlFor="title" className="fw-bold">
                Title
              </Label>
              <Input
                color="primary"
                placeholder="Doctor"
                type="select"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              >
                {}
                <option value="" disabled>
                  Title
                </option>
                <option value="Ms">Ms</option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
              </Input>
            </Col>
            <Col sm={3}>
              <Label htmlFor="cname" className="fw-bold">
                Patient Name *
              </Label>
              <Input
                id="cname"
                type="text"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </Col>

            <Col sm={3}>
              <Label htmlFor="ccn" className="fw-bold">
                Contact No *
              </Label>
              <Input
                id="ccn"
                type="text"
                value={contactNo}
                onChange={(e) => setContactno(e.target.value)}
              />
            </Col>
            <Col sm={3}>
              <Label htmlFor="loc" className="fw-bold">
                Location
              </Label>
              <Input
                id="loc"
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="pb-4">
            <Col sm={3}>
              <Label htmlFor="addrs1" className="fw-bold">
                Address Line 1
              </Label>
              <Input
                id="addrs1"
                type="text"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
              />
            </Col>
            <Col sm={3}>
              <Label htmlFor="addrs2" className="fw-bold">
                Address Line 2
              </Label>
              <Input
                id="addrs2"
                type="text"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
              />
            </Col>
            <Col sm={2}>
              <Label htmlFor="pincode" className="fw-bold">
                PIN Code
              </Label>
              <Input
                id="pincode"
                type="text"
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
              />
            </Col>
            <Col sm={2}>
              <Label htmlFor="state" className="fw-bold">
                State
              </Label>
              <Input
                id="state"
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </Col>
            <Col sm={2}>
              <Label htmlFor="country" className="fw-bold">
                Country
              </Label>
              <Input
                id="country"
                type="text"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="pb-4">
            <Col sm={3}>
              <Label htmlFor="email" className="fw-bold">
                Email ID
              </Label>
              <Input
                id="email"
                type="text"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
              />
            </Col>
            <Col sm={3}>
              <Label htmlFor="source" className="fw-bold">
                How did you hear about us?
              </Label>
              <Input
                list="sources"
                id="source"
                name="source"
                value={source}
                placeholder="Start typing or select an option"
                onChange={(e) => setSource(e.target.value)}
              />

              <datalist id="sources">
                <option value="Word of Mouth" />
                <option value="Online Search" />
                <option value="Social Media" />
                <option value="Advertising" />
                <option value="Referral" />
              </datalist>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col sm={6}>
              <Label htmlFor="enquiry" className="fw-bold">
                Enquiry Details *
              </Label>
              <Input
                id="enquiry"
                type="textarea"
                value={enquiry}
                onChange={(e) => setEnquiry(e.target.value)}
                rows="3"
              />
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button onClick={submitHandler} color="primary">
            Submit
          </Button>
        </CardFooter>
      </Card>
      {/* Customer History Section */}
      {customerHistory && (
        <Card className="mt-3">
          {" "}
          {/* Add margin-top for separation */}
          <CardHeader>
            <h5>Customer History</h5>
          </CardHeader>
          <CardBody>
            {/* Add content to display customer history */}
            <Table>
              <thead>
                <tr className="table-info ">
                  <th style={{ width: "50px" }}>Sl.No</th>
                  <th style={{ width: "200px", border: "0px 1px solid" }}>
                    Date
                  </th>
                  <th style={{ width: "150px" }}>Customer Name</th>
                  <th style={{ width: "150px" }}>Contact No</th>
                  <th style={{ width: "350px" }}>Address</th>
                  <th>Enquiry</th>
                </tr>
                {customerHistory.map((item, id) => {
                  const dt = new Date(item.date);
                  return (
                    <tr
                      key={id}
                      className={
                        id % 2 === 0 ? "table-success" : "table-secondary"
                      }
                    >
                      <td>{id + 1}</td>
                      <td>{formatDate(dt)}</td>
                      <td>{item.customerName}</td>
                      <td>{item.contactNo}</td>
                      <td>
                        {item.addressLine1}, {item.addressLine2}
                      </td>
                      <td>{item.enquiry}</td>
                    </tr>
                  );
                })}
              </thead>
            </Table>
          </CardBody>
        </Card>
      )}
    </Container>
  );
};

export default Ccare;
