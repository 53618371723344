export function numberToWords(number) {
  const units = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  if (number < 10) {
    return units[number];
  } else if (number < 20) {
    return teens[number - 10];
  } else if (number < 100) {
    return (
      tens[Math.floor(number / 10) - 2] +
      (number % 10 !== 0 ? " " + units[number % 10] : "")
    );
  } else if (number < 1000) {
    return (
      units[Math.floor(number / 100)] +
      " Hundred" +
      (number % 100 !== 0 ? " " + numberToWords(number % 100) : "")
    );
  } else if (number < 100000) {
    return (
      numberToWords(Math.floor(number / 1000)) +
      " Thousand" +
      (number % 1000 !== 0 ? " " + numberToWords(number % 1000) : "")
    );
  } else if (number < 10000000) {
    return (
      numberToWords(Math.floor(number / 100000)) +
      " Lakh" +
      (number % 100000 !== 0 ? " " + numberToWords(number % 100000) : "")
    );
  } else {
    return "Number out of range";
  }
}
