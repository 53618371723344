import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { getInventorySupplier } from "../../../../redux/inventory";

const SupplierForm = ({ editFormData }) => {
  const auth = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const { userData, branchData } = auth;
  const [formData, setFormData] = useState({
    supplierName: "",
    supplierContact: "",
    supplierAddress: "",
    country: "",
    state: "",
    bankName: "",
    accountName: "",
    ifsc: "",
    accountNumber: "",
    branchName: "",
    accountType: "",
    bankAddress: "",
    PANorIT: "",
    GSTINorUIN: "",
    AlterGST: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (editFormData && editFormData) {
      setFormData(editFormData);
      //console.log(editFormData);
    }
  }, [editFormData]);

  const clearForm = () => {
    setFormData({
      supplierName: "",
      supplierContact: "",
      supplierAddress: "",
      country: "",
      state: "",
      bankName: "",
      accountName: "",
      ifsc: "",
      accountNumber: "",
      branchName: "",
      accountType: "",
      bankAddress: "",
      PANorIT: "",
      GSTINorUIN: "",
      AlterGST: "",
    });
  };

  const validateForm = () => {
    let isValid = true;

    if (formData.supplierName.trim() === "") {
      isValid = false;
      // Handle validation error for item field
    }

    if (formData.supplierContact.trim() === "") {
      isValid = false;
      // Handle validation error for unitPrice field
    }

    // Add more validation logic for other fields

    return isValid;
  };
  const editSubmitHandler = () => {
    axios
      .post(`${process.env.REACT_APP_CRI_API}edit-supplier`, formData, {
        params: {
          branch: userData.branch,
          supplierId: formData.supplierId,
        },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        //   console.log(res);
        if (res.status === 200) {
          dispatch(
            getInventorySupplier({
              token: userData.token,
              branch: userData.branch,
            })
          );
          alert("Edit success");
          clearForm();
        }
      })
      .catch((err) => console.log(err));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to handle form submission (e.g., send data to backend)

    if (validateForm() && editFormData) {
      editSubmitHandler();
      return;
    }
    // Reset form fields after submission
    if (validateForm()) {
      axios
        .post(`${process.env.REACT_APP_CRI_API}add-supplier`, formData, {
          params: {
            branch: userData.branch,
          },
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((res) => {
          //   console.log(res);
          if (res.status === 200) {
            alert("save success");
            clearForm();
            dispatch(
              getInventorySupplier({
                token: userData.token,
                branch: userData.branch,
              })
            );
          }
        })
        .catch((err) => console.log(err));
    } else {
      alert("Invalid Form");
      return;
    }
    setFormData({
      supplierName: "",
      supplierContact: "",
      supplierAddress: "",
      country: "",
      state: "",
      bankName: "",
      accountName: "",
      ifsc: "",
      accountNumber: "",
      branchName: "",
      accountType: "",
      bankAddress: "",
      PANorIT: "",
      GSTINorUIN: "",
      AlterGST: "",
    });
  };
  return (
    <Card>
      <CardHeader>
        <Row className="d-flex justify-content-center">
          <Col md={3} style={{ textAlign: "center" }}>
            <h4>Supplier Form</h4>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="supplierName">Supplier Name</Label>
              <Input
                type="text"
                name="supplierName"
                id="supplierName"
                value={formData.supplierName}
                onChange={handleChange}
                placeholder="Enter supplier name"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="supplierContact">Supplier Contact</Label>
              <Input
                type="text"
                name="supplierContact"
                id="supplierContact"
                value={formData.supplierContact}
                onChange={handleChange}
                placeholder="Enter supplier contact"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="supplierAddress">Supplier Address</Label>
              <Input
                type="textarea"
                name="supplierAddress"
                id="supplierAddress"
                value={formData.supplierAddress}
                onChange={handleChange}
                placeholder="Enter supplier address"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="country">Country</Label>
              <Input
                type="text"
                name="country"
                id="country"
                value={formData.country}
                onChange={handleChange}
                placeholder="Enter supplier name"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="state">State</Label>
              <Input
                type="text"
                name="state"
                id="state"
                value={formData.state}
                onChange={handleChange}
                placeholder="Enter supplier state"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col style={{ borderBottom: "solid thin grey" }} className="mx-2">
            <h5>Banking Details</h5>
          </Col>
          <h5></h5>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="bankName">Bank Name</Label>
              <Input
                type="text"
                name="bankName"
                id="bankName"
                value={formData.bankName}
                onChange={handleChange}
                placeholder="Enter Bank name"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="state">Account Name</Label>
              <Input
                type="text"
                name="accountName"
                id="accountName"
                value={formData.accountName}
                onChange={handleChange}
                placeholder="Enter Account Name"
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="bankName">IFSC</Label>
              <Input
                type="text"
                name="ifsc"
                id="ifsc"
                value={formData.ifsc}
                onChange={handleChange}
                placeholder="Enter IFSC"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="state">Account Number</Label>
              <Input
                type="text"
                name="accountNumber"
                id="accountNumber"
                value={formData.accountNumber}
                onChange={handleChange}
                placeholder="Enter Account Number"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="bankName">Branch Name</Label>
              <Input
                type="text"
                name="branchName"
                id="branchName"
                value={formData.branchName}
                onChange={handleChange}
                placeholder="Enter Branch Name"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="state">Account Type</Label>
              <Input
                type="text"
                name="accountType"
                id="accountType"
                value={formData.accountType}
                onChange={handleChange}
                placeholder="Enter Account Type"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="bankName">Bank Address</Label>
              <Input
                type="text"
                name="bankAddress"
                id="bankAddress"
                value={formData.bankAddress}
                onChange={handleChange}
                placeholder="Enter Bank Address"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col style={{ borderBottom: "solid thin grey" }} className="mx-2">
            <h5>Tax Registration</h5>
          </Col>
          <h5></h5>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="PANorIT">PAN/IT Number</Label>
              <Input
                type="text"
                name="PANorIT"
                id="PANorIT"
                value={formData.PANorIT}
                onChange={handleChange}
                placeholder="Enter PAN/IT"
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="PANorIT">GSTIN Number</Label>
              <Input
                type="text"
                name="GSTIN"
                id="GSTIN"
                value={formData.GSTIN}
                onChange={handleChange}
                placeholder="Enter GSTIN"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="bankName">Registration Type</Label>
              <Input
                type="select"
                name="GSTINorUIN"
                id="GSTINorUIN"
                value={formData.GSTINorUIN}
                onChange={handleChange}
                placeholder="Enter GSTIN/UIN"
              >
                <option value="">Select Registration Type</option>
                <option value="GSTIN">GSTIN</option>
                <option value="UIN">UIN</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="AlterGST">Set/Alter GST Details</Label>
              <Input
                type="text"
                name="AlterGST"
                id="AlterGST"
                value={formData.AlterGST}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-end pt-3">
          <Col md={2} style={{ textAlign: "right" }}>
            <Button color="primary" onClick={handleSubmit}>
              {" "}
              Submit
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SupplierForm;
