// PrintBill.js
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, Row, Table } from "reactstrap";
import ci from "../../../../images/cutis_new.jpeg";
import { ReactComponent as Rupees } from "../../../../images/currency-rupee.svg";
import { numberToWords } from "../../CRM-Calicut/utils/numberWords";
import { CheckSquare } from "react-feather";

const PrintDemography = React.forwardRef(
  ({ data, address, date, buttonHide, branch }, ref) => {
    const printDate = (
      <span style={{ fontSize: "10px" }}>
        {`${new Date(date).getDate()} / ${
          new Date(date).getMonth() + 1
        } /  ${new Date(date).getFullYear()} `}{" "}
        {
          //new Date(date).toLocaleTimeString("en-US", options)
        }
      </span>
    );

    //  useEffect(() => {
    //   if (buttonHide.display === "none") {
    // setPrintVisible(true);
    // }
    //  console.log(address);
    //  }, [buttonHide]);
    const commonStyle = {
      borderBottom: "dotted 1px",
      width: "100%",
      paddingBottom: "1px",
      marginBottom: "0px",
      lineHeight: "1",
      display: "flex",
      alignItems: "center",
    };
    const textStyle = {
      marginLeft: "10px", // Add spacing to the left of the text
      lineHeight: "1", // Set line-height to minimize space between text and border
      paddingBottom: "1px",
      marginBottom: "0px",
    };

    return (
      <Container className="p-3 pt-2 mt-2">
        <div
          ref={ref}
          style={{
            //    border: "solid thin black",
            margin: "25px",
            padding: "20px",
            fontFamily: "Arial, sans-serif",
            fontSize: "14px", // Increased font size
            lineHeight: "1.6", // Increased line spacing
          }}
        >
          <Row
            style={{
              // borderBottom: "solid thin black",
              paddingBottom: "10px",
            }}
            className="d-flex justify-content-end"
          >
            <Col
              md={5}
              sm={5}
              style={{
                //   borderRight: "solid thin black",
                width: "270px",
                padding: "0px",
                marginBottom: "10px",
              }}
            >
              {branch && branch === "CLT" && (
                <img
                  src={ci}
                  alt="image"
                  style={{
                    width: "100%",
                    height: "auto",
                    // opacity: "0.6",
                  }}
                />
              )}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center mt-3 mb-4">
            <Col
              sm={5}
              className="text-center mt-2"
              style={{ fontSize: "17px" }}
            >
              PATIENT REGISTRATION
              {/* Increased header font size */}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={5} sm={5} className="d-flex align-items-center">
              <Label>Name:</Label>
              <div style={commonStyle}>
                <span style={textStyle}>
                  {data.fname} {data.sname}
                </span>
              </div>
            </Col>

            <Col md={3} sm={3} className="d-flex align-items-center">
              <Label>Gender</Label>
              <div style={commonStyle}>
                <span style={textStyle}>{data.sex}</span>
              </div>
            </Col>
            <Col className="d-flex align-items-center">
              <Label>Age:</Label>
              <div style={commonStyle}>
                <span style={textStyle}>{data.age}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex align-items-center">
              <Label>Address:</Label>
              <div style={commonStyle}>
                <span style={textStyle}>{data.address}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6} sm={6} className="d-flex align-items-center">
              <Label style={{ width: "95px" }}>Contact No:</Label>
              <div style={commonStyle}>
                <span style={textStyle}>{data.contactno}</span>
              </div>
            </Col>
            <Col className="d-flex align-items-center">
              <Label style={{ width: "75px" }}>Email ID:</Label>
              <div style={commonStyle}>
                <span style={textStyle}>{data.emailId}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6} sm={6} className="d-flex align-items-center">
              <Label>Occupation:</Label>
              <div style={commonStyle}>
                <span style={textStyle}>{data.occupation}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Label>
                Where did you hear about CUTIS INTERNATIONAL COSMETIC CLINIC
              </Label>
              <div>
                <span style={textStyle}>
                  <CheckSquare className="me-1" />
                  {data.source}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Label>Reason for Visiting:</Label>
              <div>
                <span style={textStyle}>
                  {" "}
                  <CheckSquare className="me-1" />
                  {data.reasonforVisit}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Label>Prescription of Medicine taken in the past:</Label>
              <div style={commonStyle}>
                <span style={textStyle}>
                  {data.togglePastPrescript ? data.pastPrescription : "No"}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Label>Any reaction or allergies to medicine in the past:</Label>
              <span style={textStyle}>
                {data.toggleAllergy ? data.reactionAlergies : "No"}
              </span>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Label>
                Would you like to receive Updates from CUTIS INTERNATIONAL
                COSMETIC CLINIC:
              </Label>
              <span style={textStyle}>
                {data.dnd === "false" ? "Yes" : "No"}
              </span>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <p>
                The information provided by me is true and to the best of my
                knowledge{" "}
              </p>
            </Col>
          </Row>

          <Row className="mt-3 d-flex justify-content-between mb-5">
            <Col md={2} sm={2} className="d-flex align-items-center">
              <Label>Date</Label>
              <div style={commonStyle}>
                <span style={textStyle}>{printDate}</span>
              </div>
            </Col>

            <Col md={3} sm={3}>
              <div style={commonStyle} className="pt-4">
                <span style={textStyle}></span>
              </div>
              <Label style={{ fontSize: "12px" }}>(Patient Signature)</Label>
            </Col>
          </Row>
          <Row className="mt-5">
            <div
              style={{ borderTop: "1px solid rgba(144, 0, 160, 0.9)" }}
              className="d-flex justify-content-center"
            >
              <Col
                md={8}
                sm={8}
                className="pt-2 pe-1 pb-2"
                style={{
                  fontSize: "12px", // Increased font size
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                {branch && branch === "CLT" && (
                  <>
                    <span style={{ fontSize: "17px" }}>
                      <b>Cutis International Cosmetic Clinics Pvt Ltd</b>
                    </span>
                    <br />
                    <span>
                      Ground Floor, Lillis Tower, Near Girls School, Nadakkavu,
                      Kozhikode, PIN 673011
                    </span>
                    <br />
                    <span>
                      Mob: +91 7994 233 344 | info@cutisinternational.com | www.
                      cutisinternational.com
                    </span>
                  </>
                )}
              </Col>
            </div>
          </Row>
        </div>
      </Container>
    );
  }
);

export default PrintDemography;
