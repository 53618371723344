// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

const initialConsumptionData = () => {
  const item = window.sessionStorage.getItem("consumption");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

const initialPurchaseData = () => {
  const item = window.sessionStorage.getItem("purchase");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

const initialPurchaseRtnData = () => {
  const item = window.sessionStorage.getItem("purchaseRtn");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

const initialItemsData = () => {
  const item = window.sessionStorage.getItem("itemsData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

const initialSupplierData = () => {
  const item = window.sessionStorage.getItem("supplier");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

const initialInPatientData = () => {
  const item = window.sessionStorage.getItem("inpatient");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const getInventoryConsumption = createAsyncThunk(
  "itemsList/getInventoryConsumption",
  async (obj) => {
    const response = await axios
      .get(`${process.env.REACT_APP_CRI_API}consumption`, {
        params: {
          branch: obj.branch,
        },
        headers: { Authorization: `Bearer ${obj.token}` },
        "Content-Type": "application/json",
      })
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return response;
  }
);

export const getInventoryItems = createAsyncThunk(
  "itemsList/getInventoryItems",
  async (obj) => {
    const response = await axios
      .get(`${process.env.REACT_APP_CRI_API}inventory`, {
        params: {
          branch: obj.branch,
        },
        headers: { Authorization: `Bearer ${obj.token}` },
        "Content-Type": "application/json",
      })
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return response;
  }
);

export const getInventoryPurchase = createAsyncThunk(
  "itemsList/getInventoryPurchase",
  async (obj) => {
    const response = await axios
      .get(`${process.env.REACT_APP_CRI_API}purchase`, {
        params: {
          branch: obj.branch,
          invoice_id: obj.invoice_id,
        },
        headers: { Authorization: `Bearer ${obj.token}` },
        "Content-Type": "application/json",
      })
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return response;
  }
);

export const getInventoryPurchaseRtn = createAsyncThunk(
  "itemsList/getInventoryPurchaseRtn",
  async (obj) => {
    const response = await axios
      .get(`${process.env.REACT_APP_CRI_API}rtn-purchase`, {
        params: {
          branch: obj.branch,
          invoice_id: obj.returnPurchaseId,
        },
        headers: { Authorization: `Bearer ${obj.token}` },
        "Content-Type": "application/json",
      })
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return response;
  }
);

export const getInventorySupplier = createAsyncThunk(
  "itemsList/getInventorySupplier",
  async (obj) => {
    const response = await axios
      .get(`${process.env.REACT_APP_CRI_API}supplier`, {
        params: {
          branch: obj.branch,
        },
        headers: { Authorization: `Bearer ${obj.token}` },
        "Content-Type": "application/json",
      })
      .then((res) => {
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return response;
  }
);

export const getInventoryInPatient = createAsyncThunk(
  "itemsList/getInventoryInPatient",
  async (obj) => {
    const response = await axios
      .get(`${process.env.REACT_APP_CRI_API}inpatient`, {
        params: {
          branch: obj.branch,
          mrn: obj.mrn,
        },
        headers: { Authorization: `Bearer ${obj.token}` },
        "Content-Type": "application/json",
      })
      .then((res) => {
        //  console.log(res);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return response;
  }
);

export const itemsSlice = createSlice({
  name: "itemsList",
  initialState: {
    itemsData: initialItemsData(),
    supplierData: initialSupplierData(),
    purchaseData: initialPurchaseData(),
    purchaseRtnData: initialPurchaseRtnData(),
    consumptionData: initialConsumptionData(),
    inPatientData: initialInPatientData(),
    error: null,
  },
  reducers: {
    handleInventoryItems: (state, action) => {
      state.itemsData = action.payload;
      sessionStorage.setItem("itemsData", JSON.stringify(action.payload));
    },
    handleError: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInventoryItems.fulfilled, (state, action) => {
        state.itemsData = action.payload;
        sessionStorage.setItem("itemsData", JSON.stringify(action.payload));
        // state.bookmarks = action.payload.bookmarks
      })
      .addCase(getInventoryItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error; // You can customize how you handle the error message here
      })
      .addCase(getInventorySupplier.fulfilled, (state, action) => {
        state.supplierData = action.payload;
        sessionStorage.setItem("supplier", JSON.stringify(action.payload));
        // state.bookmarks = action.payload.bookmarks
      })
      .addCase(getInventorySupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error; // You can customize how you handle the error message here
      })
      .addCase(getInventoryInPatient.fulfilled, (state, action) => {
        state.inPatientData = action.payload;
        sessionStorage.setItem("inpatient", JSON.stringify(action.payload));
        // state.bookmarks = action.payload.bookmarks
      })
      .addCase(getInventoryInPatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error; // You can customize how you handle the error message here
      })
      .addCase(getInventoryPurchase.fulfilled, (state, action) => {
        state.purchaseData = action.payload;
        sessionStorage.setItem("purchase", JSON.stringify(action.payload));
        // state.bookmarks = action.payload.bookmarks
      })
      .addCase(getInventoryPurchase.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error; // You can customize how you handle the error message here
      })
      .addCase(getInventoryConsumption.fulfilled, (state, action) => {
        state.consumptionData = action.payload;
        sessionStorage.setItem("consumption", JSON.stringify(action.payload));
        // state.bookmarks = action.payload.bookmarks
      })
      .addCase(getInventoryConsumption.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error; // You can customize how you handle the error message here
      })
      .addCase(getInventoryPurchaseRtn.fulfilled, (state, action) => {
        state.purchaseRtnData = action.payload;
        sessionStorage.setItem("purchaseRtn", JSON.stringify(action.payload));
      })
      .addCase(getInventoryPurchaseRtn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error; // You can customize how you handle the error message here
      });

    //  .addCase(updateBookmarked.fulfilled, (state, action) => {
    //    let objectToUpdate
    //    // ** find & update object
    //    state.suggestions.find(item => {
    //      if (item.id === action.payload) {
    //        item.isBookmarked = !item.isBookmarked
    //        objectToUpdate = item
    //      }
    //    })

    // ** Get index to add or remove bookmark from array
    //  const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.payload)

    //  if (bookmarkIndex === -1) {
    //    state.bookmarks.push(objectToUpdate)
    //  } else {
    //    state.bookmarks.splice(bookmarkIndex, 1)
    //  }
    //})
  },
});

export const { handleInventoryItems, handleError } = itemsSlice.actions;

export default itemsSlice.reducer;
