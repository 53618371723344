import axios from "axios";
import { useState, useMemo, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";

import {
  Table,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
  Spinner,
} from "reactstrap";
const ProcedureEntry = ({ getProcedureHandler, toggle, editData }) => {
  const auth = useSelector((state) => state.authentication);
  const { userData, branchData } = auth;
  const [procedure, setProcedure] = useState([
    { procedure: "", cost: "", remarks: "" },
  ]);
  useEffect(() => {
    if (editData) {
      setProcedure([editData]);
    }
  }, [editData]);
  const handleProcedureChange = (index, value) => {
    const newProcedure = [...procedure];
    newProcedure[index].procedure = value;
    setProcedure(newProcedure);
  };

  const handleCostChange = (index, value) => {
    const newProcedure = [...procedure];
    newProcedure[index].cost = value;
    setProcedure(newProcedure);
  };

  const handleRemarksChange = (index, value) => {
    const newProcedure = [...procedure];
    newProcedure[index].remarks = value;
    setProcedure(newProcedure);
  };

  const addRow = () => {
    setProcedure([...procedure, { procedure: "", cost: "", remarks: "" }]);
  };

  const submitHandler = () => {
    if (!editData) {
      const apiUrl = `${process.env.REACT_APP_CRI_API}add-procedure`;
      const postRequests = procedure
        .filter((itm) => itm.procedure)
        .map((item) =>
          axios.post(apiUrl, item, {
            params: {
              branch: userData.branch,
            },
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          })
        );

      axios
        .all(postRequests)
        .then((responses) => {
          responses.forEach((response) => {
            getProcedureHandler();
            toggle();
            // Handle the response from each request
          });
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle the error case
        });
    } else if (editData) {
      const apiUrl = `${process.env.REACT_APP_CRI_API}edit-procedure`;
      axios
        .post(apiUrl, procedure, {
          params: {
            branch: userData.branch,
          },
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((res) => {
          if (res) toggle();
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "350px" }}>Procedure</th>
            <th>Cost</th>
            <th style={{ width: "250px" }}>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {procedure.map((pr, index) => (
            <tr key={index}>
              <td>
                <Input
                  type="text"
                  value={pr.procedure}
                  onChange={(e) => handleProcedureChange(index, e.target.value)}
                />
              </td>
              <td>
                <Input
                  type="text"
                  value={pr.cost}
                  onChange={(e) => handleCostChange(index, e.target.value)}
                />
              </td>
              <td>
                <Input
                  type="text"
                  value={pr.remarks}
                  onChange={(e) => handleRemarksChange(index, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        <Col md={10}>
          {!editData && <button onClick={addRow}>Add Row</button>}
        </Col>
        <Col md={2}>
          <button onClick={submitHandler}>Submit</button>
        </Col>
      </Row>
    </div>
  );
};

export default ProcedureEntry;
