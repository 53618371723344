import axios from "axios";
import React, { useEffect, useState } from "react";
import { Edit, Search, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Badge,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { getInventoryItems } from "../../../../../redux/inventory";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
//import PurchaseEntry from "./Purchase/Bills/PurchaseEntry";
import { getProcedures } from "../../../../../Data/Customer/api";
import ProcedureEntry from "./ProcedureEntry";

const ProceduresManagement = (props) => {
  const itemsList = useSelector((state) => state.itemsList);
  const [editData, setEditData] = useState("");
  const dispatch = useDispatch();
  const { itemsData, supplierData, purchaseData } = itemsList;
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;
  const [itemName, setItemName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [alertCount, setAlertCount] = useState("");
  const [formData, setFormData] = useState("");
  const [modal, setModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [procedureDataDownload, setProcedureDataDownload] = useState("");
  const [procedures, setProcedures] = useState("");
  const toggle = () => setModal(!modal);

  const getProcedureHandler = () => {
    getProcedures(userData.token, userData.branch, navigate).then((res) => {
      // console.log(res);
      setProcedures(res.data);
    });
  };
  useEffect(() => {
    if (userData.branch && userData.token) getProcedureHandler();
  }, [userData.branch, userData.token]);

  useEffect(() => {
    // what format , check
    if (procedures && procedures.length) {
      setProcedureDataDownload(() =>
        procedures.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) => t.procedure === item.procedure && t.cost === item.cost
            )
        )
      );
    }
  }, [procedures]);

  const exportToExcel = (data) => {
    // console.log(data);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "my-file.xlsx");
  };

  const editProcedureHandler = (Data, toggle) => {
    setFormData(Data);
    setEditData(Data);
    toggle();
  };

  const deleteHandler = (item) => {
    const cost = prompt("Please enter the cost to confirm delete");
    if (cost && cost.toLocaleLowerCase() === item.cost.toLocaleLowerCase()) {
      axios
        .delete(`${process.env.REACT_APP_CRI_API}delete-procedure`, {
          params: { id: item._id, branch: userData.branch },
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((res) => {
          if (res) {
            getProcedureHandler();
          }
        })
        .catch((err) => {
          if (
            (err.response.data &&
              err.response.data.err &&
              err.response.data.err.name === "TokenExpiredError") ||
            err.response.data.message === "Unauthorized"
          ) {
            navigate("/");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("srchData");
            sessionStorage.removeItem("prescriptionHistory");
            sessionStorage.removeItem("rights");
            navigate("/cri-crm");
            return err;
          }
        });
    } else if (cost) {
      alert("Batch not matching");
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={3} className="pt-4">
          <button
            onClick={() => {
              setEditData(""); //  to avoid conflicts for new entry process
              toggle();
            }}
          >
            New Procedure
          </button>
        </Col>
        <Col md={3} className="pt-4">
          {" "}
          <InputGroup className="mb-4">
            <Input
              // color="primary"
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search Medicine Name"
            />
            <InputGroupText>
              {" "}
              <Search />
            </InputGroupText>
          </InputGroup>
        </Col>
        <Col className="pt-4">
          <button onClick={() => exportToExcel(procedureDataDownload)}>
            Download
          </button>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {!procedures && <Spinner color="primary" />}
          {procedures && procedures.length && (
            <Table responsive striped>
              <thead>
                <tr className="table-secondary">
                  <th>Sl No.</th>
                  <th style={{ width: "20%" }}>Item Name</th>
                  {
                    //<th>Brand Name</th>
                  }

                  <th>Cost</th>
                  <th>Remarks</th>

                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {
                  // remove redudunt stocks display here
                }
                {procedures
                  .filter(
                    (item) =>
                      !searchInput ||
                      item.procedure
                        .toLowerCase()
                        .includes(searchInput.toLowerCase()) ||
                      item.remarks
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                  )
                  .map((item, id) => {
                    return (
                      <tr key={id}>
                        <td>{id + 1}</td>
                        <td>{item.procedure}</td>
                        {
                          //<td>{item.brandName}</td>
                        }
                        <td>{item.cost}</td>
                        <td>{item.remarks}</td>

                        <td>
                          {
                            // (userData.rights === "Admin" ||
                            // userData.rights === "Manager" ||
                            // userData.rights === "Accounts")
                            <Edit
                              color="green"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                editProcedureHandler(item, toggle);
                              }}
                            />
                          }
                        </td>
                        <td>
                          {
                            //(userData.rights === "Admin" ||
                            // userData.rights === "Manager" ||
                            // userData.rights === "Accounts") &&
                            <Trash2
                              color="red"
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteHandler(item)}
                            />
                          }
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle} style={{ maxWidth: "800px" }}>
        <ModalHeader toggle={toggle}>
          {" "}
          {editData ? "Edit" : "New Procedure"}{" "}
        </ModalHeader>
        <ModalBody>
          <ProcedureEntry
            formData={formData}
            setFormData={setFormData}
            getProcedureHandler={getProcedureHandler}
            toggle={toggle}
            editData={editData}
          />
        </ModalBody>
        <ModalFooter>
          <button onClick={toggle}>Close</button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showModal} centered>
        <ModalBody style={{ textAlign: "center", padding: "20px" }}>
          <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
          <p
            style={{ marginTop: "10px", fontSize: "16px", fontWeight: "bold" }}
          >
            Saving...
          </p>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default ProceduresManagement;
