import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getbranchData,
  handleLogout,
} from "../../../../../redux/authentication";
import { Outlet, useNavigate } from "react-router-dom";
import {
  getInventoryItems,
  getInventoryPurchase,
  getInventorySupplier,
  handleError,
} from "../../../../../redux/inventory";

const Lab = () => {
  const itemsList = useSelector((state) => state.itemsList);
  const auth = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const { userData, branchData } = auth;
  const navigate = useNavigate();

  useEffect(() => {
    if (userData.token) {
      dispatch(
        getInventoryPurchase({
          token: userData.token,
          branch: userData.branch,
        })
      );
      dispatch(
        getInventorySupplier({
          token: userData.token,
          branch: userData.branch,
        })
      );

      dispatch(
        getInventoryItems({ token: userData.token, branch: userData.branch })
      );
    }
  }, [userData.token]);

  useEffect(() => {
    if (itemsList) {
      if (
        itemsList.error &&
        itemsList.error.message === "Request failed with status code 403"
      ) {
        dispatch(handleError());
        dispatch(handleLogout());
        navigate("/");
      }
    }
  }, [itemsList]);

  return (
    <Container fluid>
      <Card className="mb-5 mt-2">
        <CardHeader
          className=" text-white"
          style={{
            borderRadius: "3px",
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)", // For Safari
            backgroundColor: "rgba(50, 0, 100, 0.7)", // Darker purple with opacity
          }}
        >
          <Row>
            <Col md={4}></Col>
            <Col md={4} style={{ textAlign: "center" }}>
              <h4>Lab Management</h4>
            </Col>
            <Col md={4} style={{ textAlign: "right" }} className="pt-2">
              <b>User</b> {userData.user}
            </Col>
          </Row>
        </CardHeader>
        <div className="d-flex justify-content-end pe-2 pt-2">
          <Col md="1">
            {(userData.rights === "Admin" || userData.rights === "Manager") && (
              <button
                onClick={() => {
                  // if (userData.rights === "Admin") navigate(-1);
                  navigate("/crm-clt");
                }}
              >
                Back
              </button>
            )}
          </Col>
          <button
            onClick={() => {
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("srchData");
              sessionStorage.removeItem("prescriptionHistory");
              sessionStorage.removeItem("rights");
              dispatch(handleLogout());
              navigate("/cri-crm");
            }}
            color="primary"
          >
            Log Out
          </button>
        </div>
        <CardBody>
          <Row>
            <Col md={2}>
              <Row>
                <Col className="p-3">
                  <button
                    style={{ width: "170px" }}
                    onClick={() => navigate("/lab-admin/lab-report")}
                  >
                    Prepare Lab Report
                  </button>
                </Col>
              </Row>
              {
                // <Row>
                //    <Col className="p-3">
                //      <button
                //        style={{ width: "170px" }}
                //        onClick={() => navigate("/lab-admin/lab")}
                //      >
                //        Lab Management
                //      </button>
                //    </Col>
                //  </Row>
              }
              {
                <Row>
                  <Col className="pb-3">
                    <button
                      style={{ width: "170px" }}
                      outline
                      color="primary"
                      onClick={() => navigate("/lab-admin/lab-cat")}
                    >
                      Category
                    </button>
                  </Col>
                </Row>
              }

              <Row>
                <Col className="pb-3">
                  <button
                    style={{ width: "170px" }}
                    onClick={() => navigate("/lab-admin/lab-results")}
                  >
                    Results
                  </button>
                </Col>
              </Row>

              {
                // <Row>
                //    <Col className="pb-3">
                //      <button
                //        style={{ width: "170px" }}
                //        onClick={() => navigate("/phmcy-admin/sku")}
                //      >
                //        Stock Management
                //      </button>
                //    </Col>
                //  </Row>
                //  <Row>
                //    <Col className="pb-3">
                //      <button
                //        style={{ width: "170px" }}
                //        onClick={() => navigate("/phmcy-admin/procedures")}
                //      >
                //        Procedures
                //      </button>
                //    </Col>
                //  </Row>
                //  <Row>
                //    <Col className="ps-3">
                //      <button
                //        style={{ width: "170px" }}
                //        onClick={() => navigate("/billEntry")}
                //      >
                //        Out - Patient
                //      </button>
                //    </Col>
                //  </Row>
                //  <Row>
                //    <Col className="p-3">
                //      <button
                //        style={{ width: "170px" }}
                //        onClick={() => navigate("/phmcy-admin/ip")}
                //      >
                //        In - Patient
                //      </button>
                //    </Col>
                //  </Row>
              }
            </Col>
            <Col md={10}>
              <Outlet />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal>
        <ModalHeader></ModalHeader>
      </Modal>
    </Container>
  );
};

export default Lab;
