import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <footer
      className="pt-2 "
      style={{
        backgroundColor: "rgba(220, 220, 220, 0.5)",
        backdropFilter: "blur(10px)",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Container>
        <Row className=" d-flex justify-content-center">
          <Col
            md={5}
            className="text-center"
            // style={{ color: "rgba(73, 0, 255, 0.7)" }}
          >
            <p>
              &copy; {new Date().getFullYear()} PAUPET SOFT. All Rights
              Reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
