import axios from "axios";
import React, { useEffect, useState } from "react";
import { Edit, Search, Slash, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Table,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { getInventoryItems } from "../../../../redux/inventory";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import PurchaseForm from "./Purchase/PurchaseForm";
import PurchaseEntry from "./Purchase/Bills/PurchaseEntry";

const RemovedItems = () => {
  const itemsList = useSelector((state) => state.itemsList);

  const { supplierData, purchaseData } = itemsList;
  const [removedItemsData, setRemovedItemsData] = useState("");
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;

  const [formData, setFormData] = useState("");
  const [modal, setModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [customerDataDownload, setCustomerDataDownload] = useState("");
  const toggle = () => setModal(!modal);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CRI_API}items`, {
        params: { branch: userData.branch },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((res) => {
        setRemovedItemsData(res.data);
        setCustomerDataDownload(() =>
          res.data.map((item) => {
            const obj = item.item;
            return {
              Date: new Date(item.date_removed).toLocaleDateString(),
              RemovedBy: item.removedBy,
              Item: obj.item,
              BarCode: obj.barcode,
              Batch: obj.batch,
              Expiry: obj.expiry,
              HSN: obj.hsn,
              Invoice_Id: obj.invoice_id,
              MRP: obj.mrp,
              ProductId: obj.productId,
              Quantity: obj.quantity,
              Tax: obj.tax,
              UnitPrice: obj.unitPrice,
            };
          })
        );
      });
  }, [userData]);

  const exportToExcel = (data) => {
    // console.log(data);
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "my-file.xlsx");
  };

  const editInventoryHandler = (Data, toggle) => {
    setFormData(Data);

    toggle();
  };

  //const deleteHandler = (item) => {
  // const batch = prompt("Please enter the batch to confirm delete");
  // if (batch && batch.toLocaleLowerCase() === item.batch.toLocaleLowerCase()) {
  //   {
  //     axios
  //       .delete(`${process.env.REACT_APP_CRI_API}delete-items`, {
  //         params: { branch: userData.branch },
  //         headers: {
  //           Authorization: `Bearer ${userData.token}`,
  //         },
  //       })
  //       .then((res) => {
  //
  //       })
  //       .catch((err) => {
  //         if (
  //           (err.response.data &&
  //             err.response.data.err &&
  //             err.response.data.err.name === "TokenExpiredError") ||
  //           err.response.data.message === "Unauthorized"
  //         ) {
  //           navigate("/");
  //           sessionStorage.removeItem("token");
  //           sessionStorage.removeItem("srchData");
  //           sessionStorage.removeItem("prescriptionHistory");
  //           sessionStorage.removeItem("rights");
  //           navigate("/cri-crm");
  //           return err;
  //         }
  //       });
  //   }
  // } else if (batch) {
  //   alert("Batch not matching");
  // }
  //  };

  return (
    <Container fluid>
      <Row>
        <Col md={3}></Col>
        <Col md={3} className="pt-4">
          {" "}
          <InputGroup className="mb-4">
            <Input
              // color="primary"
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search Medicine Name"
            />
            <InputGroupText>
              {" "}
              <Search />
            </InputGroupText>
          </InputGroup>
        </Col>
        <Col className="pt-4">
          <button onClick={() => exportToExcel(customerDataDownload)}>
            Download
          </button>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {!removedItemsData && <Spinner color="primary" />}
          {removedItemsData && removedItemsData.length && (
            <Table responsive striped>
              <thead>
                <tr className="table-secondary">
                  <th>Sl No.</th>
                  <th>Removed Date</th>
                  <th style={{ width: "20%" }}>Item Name</th>

                  <th>HSN/SAC</th>
                  <th>Unit Price</th>
                  <th>MRP</th>
                  <th>Quantity</th>
                  <th>Batch</th>
                  <th>Minimum Stock</th>
                  <th>Maximum Stock</th>
                  {
                    // <th>Supplier</th>
                  }
                  <th>Expiry Date</th>
                  <th>Removed By</th>
                  {
                    // <th>Restore</th>
                    //  <th>Delete</th>
                  }
                </tr>
              </thead>
              <tbody>
                {
                  // remove redudunt stocks display here
                }
                {removedItemsData

                  .filter(
                    (itemObj) =>
                      !searchInput ||
                      itemObj.item.item
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                  )
                  .map((itemO, id) => {
                    const { item, removedBy, date_removed } = itemO;
                    const itemClass =
                      Number(item.quantity) <= Number(item.reorderPoint) + 5
                        ? "table-danger"
                        : "";
                    const selectedSupplier =
                      supplierData.length &&
                      purchaseData.length &&
                      supplierData.find((sup) =>
                        purchaseData.find(
                          (x) => sup.supplierId === x.supplier.supplierId
                        )
                      );
                    const supplierName = selectedSupplier
                      ? selectedSupplier.supplierName
                      : "";
                    return (
                      <tr key={id} className={itemClass}>
                        <td>{id + 1}</td>
                        <td>{new Date(date_removed).toLocaleDateString()}</td>
                        <td>{item.item}</td>
                        <td>{item.hsn}</td>
                        <td>{item.unitPrice}</td>
                        <td>{item.mrp}</td>
                        <td>{item.quantity}</td>
                        <td>{item.batch}</td>
                        <td>{item.minStockLevel}</td>
                        <td>{item.maxStockLevel}</td>
                        {
                          // <td>{supplierName}</td>
                        }
                        <td>{item.expiry}</td>
                        <td>{removedBy}</td>
                        {
                          // <td>
                          //  (userData.rights === "Admin" ||
                          //    userData.rights === "Manager" ||
                          //    userData.rights === "Accounts") && (
                          //    <Edit
                          //      color="green"
                          //      style={{ cursor: "pointer" }}
                          //      onClick={() => {
                          //        editInventoryHandler(item, toggle);
                          //      }}
                          //    />
                          //  )
                          //    </td>
                          //    <td>
                          //      {(userData.rights === "Admin" ||
                          //        userData.rights === "Manager" ||
                          //        userData.rights === "Accounts") && (
                          //        <Slash
                          //          color="red"
                          //          style={{ cursor: "pointer" }}
                          //          onClick={() => deleteHandler(item)}
                          //        />
                          //      )}
                          //    </td>
                        }
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle} fullscreen>
        <ModalHeader toggle={toggle}>Edit Inventory</ModalHeader>
        <ModalBody>
          <PurchaseEntry
            editInventoryData={formData}
            setShowModal={setShowModal}
            togglePurchaseEntry={toggle}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showModal} centered>
        <ModalBody style={{ textAlign: "center", padding: "20px" }}>
          <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
          <p
            style={{ marginTop: "10px", fontSize: "16px", fontWeight: "bold" }}
          >
            Saving...
          </p>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default RemovedItems;
