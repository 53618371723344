import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Container,
  Label,
  Row,
  Col,
} from "reactstrap";
import ci from "../../../images/cutis_new.jpeg";

export class PrintComponent extends Component {
  constructor(props) {
    super(props);
    const options = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "Asia/Kolkata", // Set the desired time zone (Indian Standard Time)
    };
  }

  componentDidMount() {
    console.log(this.props.data);
  }

  render() {
    return (
      <Container>
        <Card style={{ width: "100%" }} className="mb-1 mt-1">
          <CardHeader className="d-flex justify-content-start mt-1 px-2">
            <Row>
              <Col md="5" sm="9">
                <div style={{ height: "100px", wdith: "200px" }}>
                  <img src={ci} alt="image" style={{ height: "100%" }} />
                </div>
              </Col>
              <Col md={6} sm={9}>
                <div className="ps-3">
                  <span style={{ fontSize: "12px" }}>
                    <b>
                      {this.props.data.printData.addressData.address.first_line}
                    </b>
                  </span>
                  <br />
                  <span style={{ fontSize: "11px" }}>
                    {this.props.data.printData.addressData.address.second_line}
                  </span>
                  <br />
                  <span style={{ fontSize: "11px" }}>
                    {this.props.data.printData.addressData.address.third_line}
                  </span>
                  <br />
                  <span style={{ fontSize: "11px" }}>
                    {this.props.data.printData.addressData.address.fourth_line}
                  </span>
                </div>
              </Col>
            </Row>
          </CardHeader>

          <div className="d-flex justify-content-center px-2 mt-3">
            <h5>Consultation Record</h5>{" "}
          </div>
          <div className="d-flex justify-content-between pb-2 px-2">
            <div className="ms-2 px-2">
              Patient Name: {this.props.data.printData.selectedCm.fname}{" "}
              {this.props.data.printData.selectedCm.sname} <br />
              Contact No: {this.props.data.printData.selectedCm.contactno}
              <br />
              MRN: {this.props.data.printData.selectedCm.mrn}
            </div>
            <div className="me-2 px-2">
              Doctor: {this.props.data.printData.username}
              <br />
              Consultation on:{" "}
              {`${new Date(this.props.data.printData.date).getDate()} / ${
                new Date(this.props.data.printData.date).getMonth() + 1
              } /  ${new Date(
                this.props.data.printData.date
              ).getFullYear()} `}{" "}
              {new Date(this.props.data.printData.date).toLocaleTimeString(
                "en-US",
                this.options
              )}
            </div>
          </div>
          <CardBody className="px-2 mx-3">
            <Label for="diagnosis"> Diagnosis:</Label>
            <Input
              type="textarea"
              name="text"
              id="diagnosis"
              rows="5"
              value={this.props.data.printData.prescription}
              readOnly
            />
            <Label for="prescription"> Prescriptions:</Label>
            <Input
              type="textarea"
              name="text"
              rows="5"
              id="prescription"
              value={"sds"}
              readOnly
            />
            <Label for="specialAdvice"> Special advice:</Label>
            <Input
              type="textarea"
              name="text"
              rows="5"
              id="specialadvice"
              value={"sds"}
              readOnly
            />
          </CardBody>
        </Card>
      </Container>
    );
  }
}
