import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const ReportChart = ({ data, total, chartName, selectedDate }) => {
  const CustomAxisTick = ({ x, y, payload }) => {
    // Customize the appearance of the tick based on the payload (data) of the tick
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {payload.value}
        </text>
      </g>
    );
  };
  const renderCustomBarLabel = ({ x, y, width, value }) => {
    if (isNaN(value)) return null;
    const labelValue = isNaN(value) ? "N/A" : value.toString(); // Convert NaN to 'N/A' or any other default value
    return (
      <text
        x={x + width / 2}
        y={y}
        fill="#666"
        textAnchor="middle"
        dy={-3}
      >{`value: ${labelValue}`}</text>
    );
  };

  const TodaysPurchaseInvoiceData =
    data.length &&
    chartName === "Today's Purchase" &&
    data.map((item) => {
      if (item.supplier || item.paymentDetails)
        return {
          Supplier: item.supplier.name,
          Total: parseFloat(item.paymentDetails.amount_paid),
        };
      return item;
    });
  const SalesInvoiceData =
    data.length &&
    chartName === "Sales Report" &&
    data.map((item) => {
      if (item)
        return {
          Date: new Date(item.invoice_date).toLocaleDateString(),
          Total: parseFloat(item.total_amount_paid),
        };
      return item;
    });

  const PetientRegistrationData = [
    {
      date: new Date(selectedDate).toLocaleDateString(),
      patients: data.length,
      pv: 2400,
      amt: 2400,
    },
  ];
  const TodaysAppointmentData = [
    {
      date:
        new Date(selectedDate).toLocaleDateString() ||
        new Date().toLocaleDateString(),
      patients: data.length,
      pv: 2400,
      amt: 2400,
    },
  ];

  const TodaysPatientInvoiceData = [
    {
      date: new Date(selectedDate).toLocaleDateString(),
      Total: parseFloat(total),
      pv: 2400,
      amt: 2400,
    },
  ];

  const TodaysPharmacyInvoiceData = [
    {
      date: new Date(selectedDate).toLocaleDateString(),
      Total: parseFloat(total),
      pv: 2400,
      amt: 2400,
    },
  ];

  if (chartName === "Patient Registration") {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={PetientRegistrationData}>
          <XAxis dataKey="date" stroke="#8884d8" tick={<CustomAxisTick />} />
          <YAxis domain={[0, PetientRegistrationData[0].patients + 1]} />
          <Tooltip wrapperStyle={{ width: 100, backgroundColor: "#ccc" }} />
          <Legend
            width={100}
            wrapperStyle={{
              top: 40,
              right: 20,
              backgroundColor: "#f5f5f5",
              border: "1px solid #d5d5d5",
              borderRadius: 3,
              lineHeight: "40px",
            }}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <Bar
            dataKey="patients"
            fill="#8884d8"
            barSize={30}
            label={renderCustomBarLabel}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
  if (chartName === "Today's Appointment") {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart width={600} height={300} data={TodaysAppointmentData}>
          <XAxis dataKey="date" stroke="#8884d8" tick={<CustomAxisTick />} />
          <YAxis />
          <Tooltip wrapperStyle={{ width: 100, backgroundColor: "#ccc" }} />
          <Legend
            width={100}
            wrapperStyle={{
              top: 40,
              right: 20,
              backgroundColor: "#f5f5f5",
              border: "1px solid #d5d5d5",
              borderRadius: 3,
              lineHeight: "40px",
            }}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <Bar
            dataKey="patients"
            fill="#8884d8"
            barSize={30}
            label={renderCustomBarLabel}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
  if (chartName === "Today's Patient Invoice") {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart width={600} height={300} data={TodaysPatientInvoiceData}>
          <XAxis dataKey="date" stroke="#8884d8" tick={<CustomAxisTick />} />
          <YAxis />
          <Tooltip wrapperStyle={{ width: 100, backgroundColor: "#ccc" }} />
          <Legend
            width={100}
            wrapperStyle={{
              top: 40,
              right: 20,
              backgroundColor: "#f5f5f5",
              border: "1px solid #d5d5d5",
              borderRadius: 3,
              lineHeight: "40px",
            }}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <Bar
            dataKey="Total"
            fill="#8884d8"
            barSize={30}
            label={renderCustomBarLabel}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
  if (chartName === "Today's Pharmacy Invoice") {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart width={600} height={300} data={TodaysPharmacyInvoiceData}>
          <XAxis dataKey="date" stroke="#8884d8" tick={<CustomAxisTick />} />
          <YAxis />
          <Tooltip wrapperStyle={{ width: 100, backgroundColor: "#ccc" }} />
          <Legend
            width={100}
            wrapperStyle={{
              top: 40,
              right: 20,
              backgroundColor: "#f5f5f5",
              border: "1px solid #d5d5d5",
              borderRadius: 3,
              lineHeight: "40px",
            }}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <Bar
            dataKey="Total"
            fill="#8884d8"
            barSize={30}
            label={renderCustomBarLabel}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
  if (chartName === "Today's Purchase") {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart width={600} height={300} data={TodaysPurchaseInvoiceData}>
          <XAxis
            dataKey="Supplier"
            stroke="#8884d8"
            tick={<CustomAxisTick />}
          />
          <YAxis />
          <Tooltip wrapperStyle={{ width: 100, backgroundColor: "#ccc" }} />
          <Legend
            width={100}
            wrapperStyle={{
              top: 40,
              right: 20,
              backgroundColor: "#f5f5f5",
              border: "1px solid #d5d5d5",
              borderRadius: 3,
              lineHeight: "40px",
            }}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <Bar
            dataKey="Total"
            fill="#8884d8"
            barSize={30}
            label={renderCustomBarLabel}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }

  if (chartName === "Sales Report") {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart width={600} height={300} data={SalesInvoiceData}>
          <XAxis dataKey="Date" stroke="#8884d8" tick={<CustomAxisTick />} />
          <YAxis />
          <Tooltip wrapperStyle={{ width: 100, backgroundColor: "#ccc" }} />
          <Legend
            width={100}
            wrapperStyle={{
              top: 40,
              right: 20,
              backgroundColor: "#f5f5f5",
              border: "1px solid #d5d5d5",
              borderRadius: 3,
              lineHeight: "40px",
            }}
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <Bar
            dataKey="Total"
            fill="#8884d8"
            barSize={30}
            label={renderCustomBarLabel}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
};

export default ReportChart;
