// main Page - Purchase with Supplier details and invoice details
import React, { useEffect, useRef, useState } from "react";
import { IndianStates } from "../../../../../Data/Customer/api";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getbranchData } from "../../../../../redux/authentication";
//import axios from "axios";
import {
  // getInventoryItems,
  getInventorySupplier,
} from "../../../../../redux/inventory";
import PurchaseEntry from "./Bills/PurchaseEntry";
const formatDate = (dte) => {
  //22-04-2024 updated checked ok
  const now = new Date(dte);
  const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds (5 hours 30 minutes)
  const istTime = new Date(now.getTime() + istOffset);
  //   console.log(istTime.toLocaleDateString()); ok 22/04/2024
  const formattedDate = istTime.toISOString().split("T")[0];
  //  console.log(formattedDate); ok 2024-04-22

  return formattedDate;
};

const PurchaseForm = ({
  editFormData,
  // editPurchaseFormData,
  togglePurchaseEntry,
  mode,
}) => {
  const itemsList = useSelector((state) => state.itemsList);
  const auth = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const { userData } = auth;
  const { supplierData, purchaseData } = itemsList;
  const [error, setError] = useState("");
  const [errorDate, setErrorDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [datewithoutHours, setDatewithoutHours] = useState("");
  const registrationType = [
    { value: "", label: "Select Registration Type" },
    { value: "consumer", label: "Consumer" },
    { value: "regular", label: "Regular" },
    { value: "composition", label: "Composition" },
    { value: "unregistered", label: "Unregistered" },
  ];
  const taxOptions = [
    { value: "", label: "Select tax" },
    { value: "none", label: "None" },
    { value: "exempted", label: "Exempted" },
    { value: "0", label: "GST @ 0%" },
    { value: "0.1", label: "GST @ 0.1%" },
    { value: "0.25", label: "GST @ 0.25%" },
    { value: "1.5", label: "GST @ 1.5%" },
    { value: "3", label: "GST @ 3%" },
    { value: "5", label: "GST @ 5%" },
    { value: "6", label: "GST @ 6%" },
    { value: "12", label: "GST @ 12%" },
    { value: "13.8", label: "GST @ 13.8%" },
    { value: "18", label: "GST @ 18%" },
    { value: "14_12", label: "GST @ 14% + cess @ 12%" },
    { value: "28", label: "GST @ 28%" },
    { value: "28_12", label: "GST @ 28% + cess @ 12%" },
    { value: "28_60", label: "GST @ 28% + cess @ 60%" },
  ];

  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));
  const [selectedRtnDate, setSelectedRtnDate] = useState(
    formatDate(new Date())
  );
  const validateDate = (dateString) => {
    if (typeof dateString !== "string") {
      return false;
    }
    const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
    const match = dateString.match(regex);

    if (!match) {
      return false; // Date string does not match the expected format
    }

    const year = parseInt(match[1], 10);
    const month = parseInt(match[2], 10) - 1; // Zero-based month
    const day = parseInt(match[3], 10);

    // Check if the date is valid
    const date = new Date(year, month, day);
    if (
      date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day
    ) {
      return true; // Valid date
    }

    return false; // Invalid date
  };

  const formObj = {
    supplier: {
      supplierId: "",
      address: "",
      state: "",
      contactNo: "",
      partyName: "",
    },
    invoice: "",
    paymentDetails: {
      invoice_id: "",
      invoice_date: datewithoutHours,
      payment_mode: "",
      amount_paid: "",
      GSTIN: "",
      registrationType: "",
    },
    additionalDetails: {
      notes: "",
      returnReason: "",
      credit: "",
      returnDate: "",
    },
    createdBy: userData.user,
  };

  const [formData, setFormData] = useState({ ...formObj });

  // for display purpose

  function isValidDate(dateString) {
    // Try to create a new Date object from the input string
    const date = new Date(dateString);

    // Check if the date is valid and the input string was successfully parsed
    return !isNaN(date.getTime());
  }
  const validateForm = () => {
    let isValid = true;

    if (formData.item.trim() === "") {
      isValid = false;
      // Handle validation error for item field
    }
    if (formData.unitPrice.trim() === "" || isNaN(formData.unitPrice)) {
      isValid = false;
      // Handle validation error for unitPrice field
    }
    if (formData.quantity === "" || isNaN(formData.quantity)) {
      isValid = false;
    }
    if (formData.mrp.trim() === "" || isNaN(formData.mrp)) {
      isValid = false;
    }

    if (!isValidDate(formData.expiry)) {
      isValid = false;
    }

    // Add more validation logic for other fields

    return isValid;
  };

  const clearForm = () => {
    setFormData({ ...formObj });
  };

  function toTitleCase(text) {
    return text.replace(/\w\S*/g, function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  }

  useEffect(() => {
    if (supplierData.length && formData.supplier.supplierId) {
      const supplier = supplierData.find(
        (item) => item.supplierId === formData.supplier.supplierId
      );

      setFormData((prevFormData) => ({
        ...prevFormData,
        supplier: {
          ...prevFormData.supplier,
          address: supplier.supplierAddress,
          GSTIN: supplier.GSTIN,
          contactNo: supplier.supplierContact,
          state: toTitleCase(supplier.state),
        }, // to match with options value
      }));
    }
  }, [supplierData, formData.supplier.supplierId]);

  useEffect(() => {
    if (validateDate(selectedDate)) {
      const nDate = new Date(selectedDate);
      const withoutHours = new Date(nDate.setHours(0, 0, 0, 0)).toISOString();
      setFormData({
        ...formData,
        paymentDetails: {
          ...formData.paymentDetails,
          invoice_date: withoutHours,
        },
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    if (
      validateDate(selectedRtnDate) &&
      formData.additionalDetails.returnReason
    ) {
      const nDate = new Date(selectedRtnDate);
      const withoutHours = new Date(nDate.setHours(0, 0, 0, 0)).toISOString();
      setFormData((prev) => {
        return {
          ...prev,
          additionalDetails: {
            ...prev.additionalDetails, // Use 'prev' to ensure you're using the previous state
            returnDate: withoutHours, // Update the 'returnDate' field
          },
        };
      });
    }
  }, [selectedRtnDate, formData.additionalDetails.returnReason]);

  useEffect(() => {
    if (mode === "Return Mode") {
      setSelectedRtnDate(formatDate(new Date()));
    }
  }, [mode]);

  useEffect(() => {
    //console.log(editFormData);
    if (editFormData) {
      setFormData(editFormData);
      setSelectedDate(() =>
        formatDate(editFormData.paymentDetails.invoice_date)
      );
      // checking for inventory editFormData matching purchase data invoice id
      const item = purchaseData.find(
        (item) => item.paymentDetails?.invoice_id === editFormData.invoice_id
      );
      if (item) {
        // console.log(item);
        setSelectedDate(() => formatDate(item.paymentDetails.invoice_date));
        // inventory item
        // console.log(item);
      }
    }
  }, [editFormData, purchaseData]);

  useEffect(() => {
    if (
      formData.paymentDetails.invoice_id &&
      editFormData &&
      mode !== "Return Mode"
    ) {
      // checking for inventory editFormData matching purchase data invoice id
      const item = purchaseData.find(
        (item) =>
          item.paymentDetails?.invoice_id === formData.paymentDetails.invoice_id
      );
      if (item) {
        // inventory item
        setFormData(item);
      }
    }
  }, [formData.paymentDetails]);

  useEffect(() => {
    // console.log(userData.token);
    if (userData.token) {
      dispatch(
        getbranchData({ token: userData.token, branch: userData.branch })
      );
      dispatch(
        getInventorySupplier({ token: userData.token, branch: userData.branch })
      );
    }
  }, [userData.token]);

  return (
    <Container fluid>
      <Card className="mb-5 mt-2">
        <CardHeader>
          <Row className="d-flex justify-content-center">
            <Col md={3} style={{ textAlign: "center" }}>
              <h4>
                {mode === "Return Mode" ? "Debit Entry" : "Purchase Entry"}
              </h4>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {
            // form here
          }
          <Form>
            <Row>
              <Col md={5}>
                <FormGroup>
                  <Label for="supplier">Supplier</Label>
                  <Input
                    type="select"
                    name="supplierId"
                    id="supplier"
                    disabled={mode === "Return Mode"}
                    value={formData.supplier?.supplierId}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        supplier: {
                          ...formData.supplier,
                          supplierId: e.target.value,
                        },
                      });
                    }}
                    placeholder="Enter supplier"
                  >
                    <option value="">select supplier</option>
                    {supplierData.length &&
                      supplierData.map((item) => {
                        return (
                          <option value={item.supplierId}>
                            {item.supplierName}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="invoice">Invoice No</Label>
                  <Input
                    type="text"
                    name="invoice_id"
                    id="invoice"
                    disabled={mode === "Return Mode"}
                    value={formData.paymentDetails?.invoice_id}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        paymentDetails: {
                          ...formData.paymentDetails,
                          invoice_id: e.target.value,
                        },
                      });
                    }}
                    placeholder="Enter invoice No."
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="invoice-date">Invoice Date</Label>
                  <Input
                    type="date"
                    name="invoice_date"
                    id="invoice-date"
                    disabled={mode === "Return Mode"}
                    value={selectedDate}
                    onChange={(e) => {
                      //handleDate(e);
                      setSelectedDate(e.target.value);
                    }}
                    placeholder="Enter invoice Date"
                  />
                  {errorDate && (
                    <span style={{ color: "red" }}>{errorDate}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <FormGroup>
                  <Label for="party">Party Name</Label>
                  <Input
                    type="text"
                    name="partyName"
                    disabled={mode === "Return Mode"}
                    id="party"
                    value={formData.supplier?.partyName}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        supplier: {
                          ...formData.supplier,
                          partyName: e.target.value,
                        },
                      });
                    }}
                    placeholder="Enter party name"
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="taxInput">GSTIN</Label>
                  <Input
                    type="text"
                    name="GSTIN"
                    id="taxInput"
                    disabled={mode === "Return Mode"}
                    value={formData.supplier?.GSTIN}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        supplier: {
                          ...formData.supplier,
                          GSTIN: e.target.value,
                        },
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label for="tax">Address</Label>
                  <Input
                    type="address"
                    name="address"
                    disabled={mode === "Return Mode"}
                    id="tax"
                    value={formData.supplier?.address}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        supplier: {
                          ...formData.supplier,
                          address: e.target.value,
                        },
                      });
                    }}
                    placeholder="Enter Address"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="batch">State</Label>
                  <Input
                    type="select"
                    name="state"
                    id="state"
                    disabled={mode === "Return Mode"}
                    value={formData.supplier?.state}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        supplier: {
                          ...formData.supplier,
                          state: e.target.value,
                        },
                      });
                    }}
                    placeholder="Enter batch"
                  >
                    {<option value="">Select State</option>}
                    {IndianStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="contact">Contact No</Label>
                  <Input
                    type="text"
                    name="contactNo"
                    id="contact"
                    disabled={mode === "Return Mode"}
                    value={formData.supplier?.contactNo}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        supplier: {
                          ...formData.supplier,
                          contactNo: e.target.value,
                        },
                      });
                    }}
                    placeholder="Enter contact No."
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="hsn"> GST Registration Type</Label>
                  <Input
                    type="select"
                    name="registrationType"
                    id="hsn"
                    disabled={mode === "Return Mode"}
                    value={formData.paymentDetails?.registrationType}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        paymentDetails: {
                          ...formData.paymentDetails,
                          registrationType: e.target.value,
                        },
                      });
                    }}
                    placeholder="Enter Registration Type"
                  >
                    {registrationType.map((option, id) => (
                      <option key={id} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col md="3">
                <Label>Amount Paid</Label>
                <Input
                  value={formData.paymentDetails?.amount_paid}
                  disabled={mode === "Return Mode"}
                  // onChange={(e) => setDiscount(e.target.value)}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Validate that the input is a valid number
                    if (isNaN(value)) {
                      setError("Please enter a valid number");
                    } else {
                      setError("");
                      //   setAmountPaid(value);
                      setFormData({
                        ...formData,
                        paymentDetails: {
                          ...formData.paymentDetails,
                          amount_paid: e.target.value,
                        },
                      });
                    }
                  }}
                />
                {error && <span style={{ color: "red" }}>{error}</span>}
              </Col>
              <Col md="3">
                <Label>Payment Mode</Label>
                <Input
                  id="paymode"
                  type="text"
                  list="paymentmode"
                  name="input1"
                  disabled={mode === "Return Mode"}
                  value={formData.paymentDetails?.payment_mode}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      paymentDetails: {
                        ...formData.paymentDetails,
                        payment_mode: e.target.value,
                      },
                    })
                  }
                />
                <datalist id="paymentmode">
                  <option value="UPI" />
                  <option value="Cash" />
                  <option value="Credit Card" />
                  <option value="Debit Card" />
                  <option value="Cheque" />
                  <option value="Net Banking" />
                </datalist>
              </Col>
              {mode === "Return Mode" && (
                <Col md="2">
                  <Label>Credit Amount</Label>
                  <Input
                    id="credit"
                    type="text"
                    name="credit"
                    disabled={mode !== "Return Mode"}
                    value={formData.additionalDetails?.credit}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        additionalDetails: {
                          ...formData.additionalDetails,
                          credit: e.target.value,
                        },
                      })
                    }
                  />
                </Col>
              )}
              {mode === "Return Mode" && (
                <>
                  <Col md="2">
                    <Label>Return Reason</Label>
                    <Input
                      id="return"
                      type="text"
                      name="credit"
                      disabled={mode !== "Return Mode"}
                      value={formData.additionalDetails?.returnReason}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          additionalDetails: {
                            ...formData.additionalDetails,
                            returnReason: e.target.value,
                          },
                        })
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="invoice-date">Return Date</Label>
                      <Input
                        type="date"
                        name="invoice_rtn_date"
                        id="nvoice_rtn_date"
                        value={selectedRtnDate}
                        onChange={(e) => {
                          //handleDate(e);
                          setSelectedRtnDate(e.target.value);
                        }}
                        placeholder="Enter invoice Date"
                      />
                      {errorDate && (
                        <span style={{ color: "red" }}>{errorDate}</span>
                      )}
                    </FormGroup>
                  </Col>
                </>
              )}
            </Row>
          </Form>

          <PurchaseEntry
            purchaseInvoiceFormData={formData}
            selectedRtnDate={selectedRtnDate}
            mode={mode}
            clearSupplierForm={clearForm}
            setShowModal={setShowModal}
            togglePurchaseEntry={togglePurchaseEntry}
          />
        </CardBody>
      </Card>
      <Modal isOpen={showModal} toggle={setShowModal} centered>
        <ModalBody style={{ textAlign: "center", padding: "20px" }}>
          <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
          <p
            style={{ marginTop: "10px", fontSize: "16px", fontWeight: "bold" }}
          >
            Saving...
          </p>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default PurchaseForm;
