import axios from "axios";
import React, { useEffect, useState } from "react";
import { Edit, Link, Search, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Badge,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  InputGroup,
  InputGroupText,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { getInventoryItems } from "../../../../../redux/inventory";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
//import PurchaseEntry from "./Purchase/Bills/PurchaseEntry";
import { getProcedures } from "../../../../../Data/Customer/api";
import LabEntry from "./LabEntry";
import { getLabItems } from "../../../../../redux/lab";
import LabCategory from "./LabCategory";

const LabManagement = (props) => {
  const itemsList = useSelector((state) => state.itemsList);
  const lab = useSelector((state) => state.lab);
  const [editData, setEditData] = useState("");
  const dispatch = useDispatch();
  const { labData } = lab;
  const { itemsData, supplierData, purchaseData } = itemsList;
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;
  const [itemName, setItemName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [alertCount, setAlertCount] = useState("");
  const [formData, setFormData] = useState("");
  const [modal, setModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [procedureDataDownload, setProcedureDataDownload] = useState("");
  const [procedures, setProcedures] = useState("");
  const toggle = () => setModal(!modal);

  const getLabItemsHandler = () => {
    dispatch(
      getLabItems({
        token: userData.token,
        branch: userData.branch,
      })
    );
    //.then((res) => {
    // console.log(res);
    //  setProcedures(res.data);
    //});
  };
  useEffect(() => {
    if (userData.branch && userData.token) getLabItemsHandler();
  }, [userData.branch, userData.token]);
  //testname, referencerange, units, method, notes
  useEffect(() => {
    // what format , check
    if (labData && labData.length) {
      setProcedureDataDownload(() =>
        labData.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) => t.testname === item.testname && t.method === item.method
            )
        )
      );
    }
    console.log(labData);
  }, [labData]);

  const exportToExcel = (data) => {
    // console.log(data);
    // Transform data to add Sl.No and remove _id
    const transformedData = data.map((item, index) => {
      const { _id, ...rest } = item;
      return { "Sl.No": index + 1, ...rest };
    });

    const ws = XLSX.utils.json_to_sheet(transformedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "my-file.xlsx");
  };

  const editCategoryHandler = (Data, toggle) => {
    setFormData(Data);
    setEditData(Data);
    toggle();
  };

  const deleteHandler = (item) => {
    const categoryName = prompt(
      "Please enter the category name to confirm delete"
    );
    if (
      categoryName &&
      categoryName.toLocaleLowerCase() === item.categoryName.toLocaleLowerCase()
    ) {
      axios
        .delete(`${process.env.REACT_APP_CRI_API}delete-lab`, {
          params: { id: item._id, branch: userData.branch },
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        })
        .then((res) => {
          if (res) {
            getLabItemsHandler();
          }
        })
        .catch((err) => {
          if (
            (err.response.data &&
              err.response.data.err &&
              err.response.data.err.name === "TokenExpiredError") ||
            err.response.data.message === "Unauthorized"
          ) {
            navigate("/");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("srchData");
            sessionStorage.removeItem("prescriptionHistory");
            sessionStorage.removeItem("rights");
            navigate("/cri-crm");
            return err;
          }
        });
    } else if (categoryName) {
      alert("Batch not matching");
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={3} className="pt-4">
          <button
            onClick={() => {
              setEditData(""); //  to avoid conflicts for new entry process
              toggle();
            }}
          >
            Add New Test
          </button>
        </Col>
        <Col md={3} className="pt-4">
          {" "}
          <InputGroup className="mb-4">
            <Input
              // color="primary"
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search Test Name"
            />
            <InputGroupText>
              {" "}
              <Search />
            </InputGroupText>
          </InputGroup>
        </Col>
        <Col className="pt-4">
          <button onClick={() => exportToExcel(procedureDataDownload)}>
            Download
          </button>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {!labData && <Spinner color="primary" />}
          {labData && labData.length && (
            <Table responsive striped>
              <thead>
                <tr className="table-secondary">
                  <th>Category</th>
                  <th style={{ width: "20%" }}>Test Name</th>
                  <th>Sub Test</th>
                  <th>Specimen</th>
                  <th>Reference Range</th>
                  <th>Units</th>
                  <th>Method</th>
                  <th>Notes</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {
                  // remove redudunt stocks display here
                }
                {labData.length &&
                  labData
                    .filter(
                      (item) =>
                        !searchInput ||
                        item.categoryName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase()) ||
                        item.notes
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                    )
                    .map((category) => (
                      <React.Fragment key={category._id}>
                        {category.tests.map((test, testIndex) => (
                          <React.Fragment key={testIndex}>
                            {test.subTests.map((subTest, subTestIndex) => (
                              <tr key={subTestIndex}>
                                {subTestIndex === 0 && (
                                  <td rowSpan={test.subTests.length}>
                                    {category.categoryName}
                                  </td>
                                )}
                                {subTestIndex === 0 && (
                                  <td rowSpan={test.subTests.length}>
                                    {test.testName}
                                  </td>
                                )}
                                <td>{subTest.subTestName}</td>
                                <td>{subTest.specimen}</td>
                                <td>{subTest.referenceRange}</td>
                                <td>{subTest.units}</td>
                                <td>{subTest.method}</td>
                                <td></td>
                                <td>
                                  {
                                    // (userData.rights === "Admin" ||
                                    // userData.rights === "Manager" ||
                                    // userData.rights === "Accounts")
                                    <Edit
                                      color="green"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        editCategoryHandler(category, toggle);
                                      }}
                                    />
                                  }
                                </td>
                                <td>
                                  {
                                    //(userData.rights === "Admin" ||
                                    // userData.rights === "Manager" ||
                                    // userData.rights === "Accounts") &&
                                    <Trash2
                                      color="red"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => deleteHandler(category)}
                                    />
                                  }
                                </td>
                              </tr>
                            ))}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle} fullscreen>
        <ModalHeader toggle={toggle}>
          {" "}
          {editData ? "Edit" : "New Procedure"}{" "}
        </ModalHeader>
        <ModalBody>
          <LabEntry
            formData={formData}
            setFormData={setFormData}
            getLabItemsHandler={getLabItemsHandler}
            toggle={toggle}
            editData={editData}
          />
        </ModalBody>
        <ModalFooter>
          <button onClick={toggle}>Close</button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showModal}>
        <ModalBody style={{ textAlign: "center", padding: "20px" }}>
          <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
          <p
            style={{ marginTop: "10px", fontSize: "16px", fontWeight: "bold" }}
          >
            Saving...
          </p>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default LabManagement;
