import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

const MultiSelectDropdown = ({
  setSelectedSalesOptions,
  setToggleActiveSelection,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    sessionStorage.getItem("selectedOptions")
      ? JSON.parse(sessionStorage.getItem("selectedOptions"))
      : []
  );

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const options = ["Pharmacy", "Procedure", "Consultation"];

  const handleCheckboxChange = (option) => {
    setToggleActiveSelection(true);
    setSelectedOptions((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter(
          (selectedOption) => selectedOption !== option
        );
      } else {
        return [...prevSelected, option];
      }
    });
  };

  useEffect(() => {
    if (selectedOptions) {
      setSelectedSalesOptions(selectedOptions);
      sessionStorage.setItem(
        "selectedOptions",
        JSON.stringify(selectedOptions)
      );
    }
  }, [selectedOptions]);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="pt-4 mt-2 ms-2"
    >
      <DropdownToggle
        caret
        style={{
          backgroundColor: "rgba(144, 0, 160, 0.9)", // Main color for the dropdown toggle button (e.g., green)
          color: "white",
        }}
      >
        Select Options
      </DropdownToggle>
      <DropdownMenu>
        {options.map((option, index) => (
          <DropdownItem key={index} toggle={false}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                />
                {option}
              </Label>
            </FormGroup>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default MultiSelectDropdown;
