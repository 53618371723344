import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  CardBody,
} from "reactstrap";
import ReportChart from "../../../../CRM-Calicut/Reports/ReportChart"; // Assuming ReportChart is a separate component for rendering charts
import axios from "axios";
import { useSelector } from "react-redux";
import MultiSelectDropdown from "./MultiSelect";
import { useNavigate } from "react-router-dom";
//import SalesRegister from "./SalesRegister";

const SalesReport = ({
  supplierData,
  setExcelFilteredSales,
  setTotalExcelFilteredInvoices,
  setTotalExcelDiscount,
  setInvoicesData,
}) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;
  const itemsList = useSelector((state) => state.itemsList);
  const { itemsData } = itemsList;
  const [filterType, setFilterType] = useState(
    sessionStorage.getItem("filterType")
      ? JSON.parse(sessionStorage.getItem("filterType"))
      : ""
  );
  const [selectedDate, setSelectedDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [dateError, setDateError] = useState("");
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [totalFilteredInvoices, setTotalFilteredInvoices] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [selectedSalesOptions, setSelectedSalesOptions] = useState("");
  const [toggleActiveSelection, setToggleActiveSelection] = useState(false);
  // Validate date inputs
  const validateDate = (date) => {
    const pattern = /^\d{4}-\d{2}-\d{2}$/;
    return pattern.test(date);
  };

  const validate = () => {
    let isValid = true;

    if (!validateDate(fromDate) && !validateDate(selectedDate)) {
      setFromDateError("Please enter a valid From Date (YYYY-MM-DD).");

      isValid = false;
    } else {
      setFromDateError("");
      setDateError("");
    }

    if (!validateDate(toDate) && !validateDate(selectedDate)) {
      setToDateError("Please enter a valid To Date (YYYY-MM-DD).");

      isValid = false;
    } else {
      setToDateError("");
    }
    return isValid;
  };

  const clearError = () => {
    setDateError("");
    setFromDateError("");
    setToDateError("");
  };

  // format date to show - updated 11-sep-2024
  const formatDate = (dte) => {
    //22-04-2024 updated checked ok
    const now = new Date(dte);
    const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds (5 hours 30 minutes)
    const istTime = new Date(now.getTime() + istOffset);

    const formattedDate = istTime.toISOString().split("T")[0];

    return formattedDate;
  };

  useEffect(() => {
    if (sessionStorage.getItem("selectedDate")) {
      const dttepm = formatDate(
        new Date(JSON.parse(sessionStorage.getItem("selectedDate")))
      );
      setSelectedDate(dttepm);
    }
  }, []);
  useEffect(() => {
    setTotalExcelDiscount(totalDiscount);
  }, [totalDiscount]);

  useEffect(() => {
    setTotalExcelFilteredInvoices(totalFilteredInvoices);
  }, [totalFilteredInvoices]);

  const filterInvoiceHandler = (res) => {
    const filterInvoices = res.map((invoice) => {
      // Filter items without productId
      const itemsWithoutProductId = invoice.invoice_sheet.filter(
        (row) => row[Object.keys(row)].productId === null
      );

      const totalPatientAmountPaid = itemsWithoutProductId.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(item[Object.keys(item)].amount),
        0
      );
      // Check if totalPatientAmountPaid is a number before using toFixed
      const totalPatientAmountPaidFormatted =
        typeof totalPatientAmountPaid === "number"
          ? totalPatientAmountPaid.toFixed(2)
          : totalPatientAmountPaid;
      // Calculate total amount Pharmacy
      const totalPatientAmount = itemsWithoutProductId.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(item[Object.keys(item)].amount),
        0
      );

      // Check if totalPatientAmount is a number before using toFixed
      const totalPatientAmountFormatted =
        typeof totalPatientAmount === "number"
          ? totalPatientAmount.toFixed(2)
          : totalPatientAmount;

      const patientInvoice = {
        ...invoice,
        total_amount_paid: totalPatientAmountPaidFormatted,
        total_amount: totalPatientAmountFormatted,
        invoice_sheet: itemsWithoutProductId,
      };

      return patientInvoice;
    });
    // filter Pharmacy sales
    const filterPharmacyInvoices = res.map((invoice) => {
      // console.log(invoice);
      // Filter items with productId and calculate total amount paid
      const itemsWithProductId = invoice.invoice_sheet.filter(
        (row) => row[Object.keys(row)].productId !== null
      );

      const totalAmountPaid = itemsWithProductId.reduce(
        (total, item) =>
          parseFloat(total) + parseFloat(item[Object.keys(item)].amount),
        0
      );
      const totalAmountPaidFormatted =
        typeof totalAmountPaid === "number"
          ? totalAmountPaid.toFixed(2)
          : totalAmountPaid;
      // Calculate total amount Pharmacy
      const totalAmount = itemsWithProductId.reduce(
        (total, item) => total + item[Object.keys(item)].amount,
        0
      );
      const totalAmountFormatted =
        typeof totalAmount === "number" ? totalAmount.toFixed(2) : totalAmount;
      const pharmacyInvoice = {
        ...invoice,
        total_amount_paid: totalAmountPaidFormatted,
        total_amount: totalAmountFormatted,
        invoice_sheet: itemsWithProductId,
      };

      return pharmacyInvoice;
    });
  };

  const filterHandler = () => {
    setTotalFilteredInvoices("");
    // console.log(selectedSalesOptions);
    setFilteredInvoices("");
    if (validate()) {
      let newSelectedDate;
      let date;
      let fromDateWithoutHours;
      let toDateWithoutHours;
      if (selectedDate) {
        newSelectedDate = new Date(selectedDate);
        date = new Date(newSelectedDate.setHours(0, 0, 0, 0)).toISOString();
      }

      if (fromDate) {
        newSelectedDate = new Date(fromDate);
        fromDateWithoutHours = new Date(
          newSelectedDate.setHours(0, 0, 0, 0)
        ).toISOString();
      }
      if (toDate) {
        newSelectedDate = new Date(toDate);
        toDateWithoutHours = new Date(
          newSelectedDate.setHours(0, 0, 0, 0)
        ).toISOString();
      }

      axios
        .get(`${process.env.REACT_APP_CRI_API}get-invoices`, {
          params: {
            branch: userData.branch,
            date: date,
            fromDate: fromDateWithoutHours,
            toDate: toDateWithoutHours,
          },
          headers: { Authorization: `Bearer ${userData.token}` },
          "Content-Type": "application/json",
        })
        .then((res) => {
          setInvoicesData(res.data);
          if (selectedItem) {
            const filterPharmacyInvoices = res.data.map((invoice) => {
              // Filter items with productId and calculate total amount paid
              const itemsWithProductId = invoice.invoice_sheet.filter(
                (row) => row[Object.keys(row)].item === selectedItem
              );

              const totalAmountPaid = itemsWithProductId.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(item[Object.keys(item)].amount),
                0
              );
              const totalAmountPaidFormatted =
                typeof totalAmountPaid === "number"
                  ? totalAmountPaid.toFixed(2)
                  : totalAmountPaid;
              // Calculate total amount Pharmacy
              const totalAmount = itemsWithProductId.reduce(
                (total, item) => total + item[Object.keys(item)].amount,
                0
              );
              const totalAmountFormatted =
                typeof totalAmount === "number"
                  ? totalAmount.toFixed(2)
                  : totalAmount;
              const pharmacyInvoice = {
                ...invoice,
                total_amount_paid: totalAmountPaidFormatted,
                total_amount: totalAmountFormatted,
                invoice_sheet: itemsWithProductId,
              };

              return pharmacyInvoice;
            });

            if (filterPharmacyInvoices && filterPharmacyInvoices.length > 0) {
              setFilteredInvoices(() =>
                filterPharmacyInvoices.filter(
                  (item) => item.invoice_sheet.length > 0
                )
              );
              setTotalFilteredInvoices(() => {
                const totalAmountPaidArray = filterPharmacyInvoices
                  .map((item) => item.total_amount_paid)
                  .filter((value) => !isNaN(value));

                const totalAmountPaid = totalAmountPaidArray.reduce(
                  (total, amount) => parseFloat(total) + parseFloat(amount),
                  0
                );

                return totalAmountPaid;
              });
              setTotalDiscount(() => {
                const totalDiscountArray = filterPharmacyInvoices
                  .map((item) => item.discount)
                  .filter(
                    (value) =>
                      value !== undefined &&
                      value !== null &&
                      value !== "" &&
                      !isNaN(value)
                  );

                const totalDiscount = totalDiscountArray.reduce(
                  (total, discount) => parseFloat(total) + parseFloat(discount),
                  0
                );
                // console.log(filterInvoices[0]?.pharmacy);
                return totalDiscount;
              });
            }
            return;
          } else if (
            selectedSalesOptions.includes("Pharmacy") &&
            selectedSalesOptions.includes("Procedure") &&
            selectedSalesOptions.includes("Consultation")
          ) {
            if (res.data) {
              setFilteredInvoices(() => res.data);
              setTotalFilteredInvoices(() => {
                const totalAmountPaidArray = res.data.map(
                  (item) => item.total_amount_paid
                );
                //  console.log(totalAmountPaidArray, filterInvoices);

                const totalAmountPaid = totalAmountPaidArray.reduce(
                  (total, amount) => parseFloat(total) + parseFloat(amount),
                  0
                );
                return totalAmountPaid;
              });
              setTotalDiscount(() => {
                const totalDiscountArray = res.data
                  .map((item) => item.discount)
                  .filter(
                    (value) =>
                      value !== undefined &&
                      value !== null &&
                      value !== "" &&
                      !isNaN(value)
                  );

                const totalDiscount = totalDiscountArray.reduce(
                  (total, discount) => parseFloat(total) + parseFloat(discount),
                  0
                );
                // console.log(filterInvoices[0]?.pharmacy);
                return totalDiscount;
              });
            }
            return;
          } else if (
            selectedSalesOptions.includes("Pharmacy") &&
            selectedSalesOptions.includes("Procedure")
          ) {
            if (res.data) {
              const filterInvoices = res.data.map((invoice) => {
                // Filter items with productId - pharmacy or item without  consult - procedure

                const itemsWithProductId = invoice.invoice_sheet.filter(
                  (row) => {
                    const rowData = row[Object.keys(row)];
                    return (
                      rowData.productId !== null ||
                      !rowData.item.toLowerCase().includes("consult")
                    );
                  }
                );

                //  const totalAmountPaid = itemsWithProductId.reduce(
                //    (total, item) =>
                //      parseFloat(total) +
                //      parseFloat(item[Object.keys(item)].amount),
                //    0
                //  );
                const totalAmountPaid = itemsWithProductId.reduce(
                  (total, item) => {
                    const row = item[Object.keys(item)[0]]; // Get the first key of each object
                    //   if (row.productId) {
                    return total + parseFloat(row.amount || 0); // Add the amount if productId is present
                    //  }
                    //   return total; // Otherwise, skip this item
                  },
                  0
                );
                const totalAmountPaidFormatted =
                  typeof totalAmountPaid === "number"
                    ? totalAmountPaid.toFixed(2)
                    : totalAmountPaid;

                // Calculate total amount
                const totalAmount = itemsWithProductId.reduce((total, item) => {
                  const row = item[Object.keys(item)[0]]; // Get the first key of each object
                  //   if (row.productId) {
                  return total + parseFloat(row.amount || 0); // Add the amount if productId is present
                  //  }
                  //   return total; // Otherwise, skip this item
                }, 0);

                const totalAmountFormatted =
                  typeof totalAmount === "number"
                    ? totalAmount.toFixed(2)
                    : totalAmount;
                // Return modified invoice object

                return {
                  ...invoice,
                  total_amount_paid: totalAmountPaidFormatted,
                  total_amount: totalAmountFormatted,
                  invoice_sheet: itemsWithProductId,
                };
              });
              // console.log(filterInvoices);
              setFilteredInvoices(filterInvoices);
              setTotalFilteredInvoices(() => {
                const totalAmountPaidArray = filterInvoices.map(
                  (item) => item.total_amount_paid
                );
                //  console.log(totalAmountPaidArray, filterInvoices);

                const totalAmountPaid = totalAmountPaidArray.reduce(
                  (total, amount) => parseFloat(total) + parseFloat(amount),
                  0
                );
                return totalAmountPaid;
              });
              setTotalDiscount(() => {
                const totalDiscountArray = filterInvoices
                  .map((item) => item.discount)
                  .filter(
                    (value) =>
                      value !== undefined &&
                      value !== null &&
                      value !== "" &&
                      !isNaN(value)
                  );
                const totalDiscount = totalDiscountArray.reduce(
                  (total, discount) => parseFloat(total) + parseFloat(discount),
                  0
                );
                // console.log(filterInvoices[0]?.pharmacy);
                return totalDiscount;
              });
            }
            return;
          } else if (
            selectedSalesOptions.includes("Procedure") &&
            selectedSalesOptions.includes("Consultation")
          ) {
            const filterInvoices = res.data.map((invoice) => {
              // Filter items with productId and calculate total amount paid
              //     const itemsWithProductId = invoice.invoice_sheet.filter(
              //       (row) => row[Object.keys(row)].productId !== null
              //     );

              // Filter items without productId
              const itemsWithoutProductId = invoice.invoice_sheet.filter(
                (row) => row[Object.keys(row)].productId === null
              );
              // console.log(invoice);

              const totalAmountPaid = itemsWithoutProductId.reduce(
                (total, item) => {
                  const row = item[Object.keys(item)[0]]; // Get the first key of each object

                  return total + parseFloat(row.amount || 0); // Add the amount if productId is present
                },
                0
              );

              const totalAmountPaidFormatted =
                typeof totalAmountPaid === "number"
                  ? totalAmountPaid.toFixed(2)
                  : totalAmountPaid;

              // Calculate total amount
              const totalAmount = itemsWithoutProductId.reduce(
                (total, item) => {
                  const row = item[Object.keys(item)[0]]; // Get the first key of each object

                  return total + parseFloat(row.amount || 0); // Add the amount if productId is present
                },
                0
              );

              const totalAmountFormatted =
                typeof totalAmount === "number"
                  ? totalAmount.toFixed(2)
                  : totalAmount;
              // Return modified invoice object

              return {
                ...invoice,
                total_amount_paid: totalAmountPaidFormatted,
                total_amount: totalAmountFormatted,
                invoice_sheet: itemsWithoutProductId,
              };
            });

            setFilteredInvoices(filterInvoices);
            setTotalFilteredInvoices(() => {
              const totalAmountPaidArray = filterInvoices.map(
                (item) => item.total_amount_paid
              );
              //  console.log(totalAmountPaidArray, filterInvoices);

              const totalAmountPaid = totalAmountPaidArray.reduce(
                (total, amount) => parseFloat(total) + parseFloat(amount),
                0
              );
              return totalAmountPaid;
            });
            setTotalDiscount(() => {
              const totalDiscountArray = filterInvoices
                .map((item) => item.discount)
                .filter(
                  (value) =>
                    value !== undefined &&
                    value !== null &&
                    value !== "" &&
                    !isNaN(value)
                );
              const totalDiscount = totalDiscountArray.reduce(
                (total, discount) => parseFloat(total) + parseFloat(discount),
                0
              );
              // console.log(filterInvoices[0]?.pharmacy);
              return totalDiscount;
            });
            return;
          } else if (
            selectedSalesOptions.includes("Pharmacy") &&
            selectedSalesOptions.includes("Consultation")
          ) {
            const filterInvoices = res.data.map((invoice) => {
              // Filter items with productId and consult
              const itemsWithProductIdandConsult = invoice.invoice_sheet.filter(
                (row) =>
                  row[Object.keys(row)].productId !== null ||
                  row[Object.keys(row)].item.toLowerCase().includes("consult")
              );

              // Filter items without productId
              const itemsWithoutProductId = invoice.invoice_sheet.filter(
                (row) => row[Object.keys(row)].productId === null
              );
              // console.log(invoice);

              const totalAmountPaid = itemsWithProductIdandConsult.reduce(
                (total, item) => {
                  const row = item[Object.keys(item)[0]]; // Get the first key of each object

                  return total + parseFloat(row.amount || 0); // Add the amount if productId is present
                },
                0
              );

              const totalAmountPaidFormatted =
                typeof totalAmountPaid === "number"
                  ? totalAmountPaid.toFixed(2)
                  : totalAmountPaid;

              // Calculate total amount
              const totalAmount = itemsWithProductIdandConsult.reduce(
                (total, item) => {
                  const row = item[Object.keys(item)[0]]; // Get the first key of each object

                  return total + parseFloat(row.amount || 0); // Add the amount if productId is present
                },
                0
              );

              const totalAmountFormatted =
                typeof totalAmount === "number"
                  ? totalAmount.toFixed(2)
                  : totalAmount;
              // Return modified invoice object

              return {
                ...invoice,
                total_amount_paid: totalAmountPaidFormatted,
                total_amount: totalAmountFormatted,
                invoice_sheet: itemsWithProductIdandConsult,
              };
            });

            setFilteredInvoices(filterInvoices);
            setTotalFilteredInvoices(() => {
              const totalAmountPaidArray = filterInvoices.map(
                (item) => item.total_amount_paid
              );
              //  console.log(totalAmountPaidArray, filterInvoices);

              const totalAmountPaid = totalAmountPaidArray.reduce(
                (total, amount) => parseFloat(total) + parseFloat(amount),
                0
              );
              return totalAmountPaid;
            });
            setTotalDiscount(() => {
              const totalDiscountArray = filterInvoices
                .map((item) => item.discount)
                .filter(
                  (value) =>
                    value !== undefined &&
                    value !== null &&
                    value !== "" &&
                    !isNaN(value)
                );
              const totalDiscount = totalDiscountArray.reduce(
                (total, discount) => parseFloat(total) + parseFloat(discount),
                0
              );
              // console.log(filterInvoices[0]?.pharmacy);
              return totalDiscount;
            });
            return;
          } else if (selectedSalesOptions.includes("Pharmacy")) {
            const filterInvoices = res.data.map((invoice) => {
              // Filter items with productId and calculate total amount paid
              const itemsWithProductId = invoice.invoice_sheet.filter(
                (row) => row[Object.keys(row)].productId !== null
              );

              // Filter items without productId
              const itemsWithoutProductId = invoice.invoice_sheet.filter(
                (row) => row[Object.keys(row)].productId === null
              );
              // console.log(invoice);

              const totalAmountPaid = itemsWithProductId.reduce(
                (total, item) => {
                  const row = item[Object.keys(item)[0]]; // Get the first key of each object
                  if (row.productId) {
                    return total + parseFloat(row.amount || 0); // Add the amount if productId is present
                  }
                  return total; // Otherwise, skip this item
                },
                0
              );
              //  console.log(totalAmountPaid);
              const totalAmountPaidFormatted =
                typeof totalAmountPaid === "number"
                  ? totalAmountPaid.toFixed(2)
                  : totalAmountPaid;

              // Calculate total amount
              const totalAmount = itemsWithProductId.reduce((total, item) => {
                const row = item[Object.keys(item)[0]]; // Get the first key of each object
                if (row.productId) {
                  return total + parseFloat(row.amount || 0); // Add the amount if productId is present
                }
                return total; // Otherwise, skip this item
              }, 0);

              const totalAmountFormatted =
                typeof totalAmount === "number"
                  ? totalAmount.toFixed(2)
                  : totalAmount;
              // Return modified invoice object

              return {
                ...invoice,
                total_amount_paid: totalAmountPaidFormatted,
                total_amount: totalAmountFormatted,
                invoice_sheet: itemsWithProductId,
              };
            });

            setFilteredInvoices(filterInvoices);
            setTotalFilteredInvoices(() => {
              const totalAmountPaidArray = filterInvoices.map(
                (item) => item.total_amount_paid
              );
              //  console.log(totalAmountPaidArray, filterInvoices);

              const totalAmountPaid = totalAmountPaidArray.reduce(
                (total, amount) => parseFloat(total) + parseFloat(amount),
                0
              );
              return totalAmountPaid;
            });
            setTotalDiscount(() => {
              const totalDiscountArray = filterInvoices
                .map((item) => item.discount)
                .filter(
                  (value) =>
                    value !== undefined &&
                    value !== null &&
                    value !== "" &&
                    !isNaN(value)
                );

              const totalDiscount = totalDiscountArray.reduce(
                (total, discount) => parseFloat(total) + parseFloat(discount),
                0
              );
              // console.log(filterInvoices[0]?.pharmacy);
              return totalDiscount;
            });
            return;
          } else if (selectedSalesOptions.includes("Procedure")) {
            const filterInvoices = res.data.map((invoice) => {
              // Filter items with productId and calculate total amount paid
              const itemsWithProductId = invoice.invoice_sheet.filter((row) => {
                const rowData = row[Object.keys(row)];
                return (
                  rowData.productId === null &&
                  rowData.item &&
                  !rowData.item.toLowerCase().includes("consult")
                );
              });

              // Filter items without productId
              const itemsWithoutProductId = invoice.invoice_sheet.filter(
                (row) => row[Object.keys(row)].productId === null
              );

              const totalAmountPaid = itemsWithProductId.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(item[Object.keys(item)].amount),
                0
              );
              const totalAmountPaidFormatted =
                typeof totalAmountPaid === "number"
                  ? totalAmountPaid.toFixed(2)
                  : totalAmountPaid;

              // Calculate total amount
              const totalAmount = itemsWithProductId.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(item[Object.keys(item)].amount),
                0
              );

              const totalAmountFormatted =
                typeof totalAmount === "number"
                  ? totalAmount.toFixed(2)
                  : totalAmount;
              // Return modified invoice object

              return {
                ...invoice,
                total_amount_paid: totalAmountPaidFormatted,
                total_amount: totalAmountFormatted,
                invoice_sheet: itemsWithProductId,
              };
            });

            setFilteredInvoices(filterInvoices);
            setTotalFilteredInvoices(() => {
              const totalAmountPaidArray = filterInvoices.map(
                (item) => item.total_amount_paid
              );
              //  console.log(totalAmountPaidArray, filterInvoices);

              const totalAmountPaid = totalAmountPaidArray.reduce(
                (total, amount) => parseFloat(total) + parseFloat(amount),
                0
              );
              return totalAmountPaid;
            });
            setTotalDiscount(() => {
              const totalDiscountArray = filterInvoices
                .map((item) => item.discount)
                .filter(
                  (value) =>
                    value !== undefined &&
                    value !== null &&
                    value !== "" &&
                    !isNaN(value)
                );

              const totalDiscount = totalDiscountArray.reduce(
                (total, discount) => parseFloat(total) + parseFloat(discount),
                0
              );
              // console.log(filterInvoices[0]?.pharmacy);
              return totalDiscount;
            });
            return;
          } else if (selectedSalesOptions.includes("Consultation")) {
            const filterInvoices = res.data.map((invoice) => {
              // Filter items with productId and calculate total amount paid
              const itemsWithProductId = invoice.invoice_sheet.filter((row) => {
                const rowData = row[Object.keys(row)];
                return (
                  rowData.productId === null &&
                  rowData.item &&
                  rowData.item.toLowerCase().includes("consult")
                );
              });

              // Filter items without productId
              const itemsWithoutProductId = invoice.invoice_sheet.filter(
                (row) => row[Object.keys(row)].productId === null
              );

              const totalAmountPaid = itemsWithProductId.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(item[Object.keys(item)].amount),
                0
              );
              const totalAmountPaidFormatted =
                typeof totalAmountPaid === "number"
                  ? totalAmountPaid.toFixed(2)
                  : totalAmountPaid;

              // Calculate total amount
              const totalAmount = itemsWithProductId.reduce(
                (total, item) =>
                  parseFloat(total) +
                  parseFloat(item[Object.keys(item)].amount),
                0
              );

              const totalAmountFormatted =
                typeof totalAmount === "number"
                  ? totalAmount.toFixed(2)
                  : totalAmount;
              // Return modified invoice object

              return {
                ...invoice,
                total_amount_paid: totalAmountPaidFormatted,
                total_amount: totalAmountFormatted,
                invoice_sheet: itemsWithProductId,
              };
            });

            setFilteredInvoices(filterInvoices);
            setTotalFilteredInvoices(() => {
              const totalAmountPaidArray = filterInvoices.map(
                (item) => item.total_amount_paid
              );
              //  console.log(totalAmountPaidArray, filterInvoices);

              const totalAmountPaid = totalAmountPaidArray.reduce(
                (total, amount) => parseFloat(total) + parseFloat(amount),
                0
              );
              return totalAmountPaid;
            });
            setTotalDiscount(() => {
              const totalDiscountArray = filterInvoices
                .map((item) => item.discount)
                .filter(
                  (value) =>
                    value !== undefined &&
                    value !== null &&
                    value !== "" &&
                    !isNaN(value)
                );

              const totalDiscount = totalDiscountArray.reduce(
                (total, discount) => parseFloat(total) + parseFloat(discount),
                0
              );
              // console.log(filterInvoices[0]?.pharmacy);
              return totalDiscount;
            });
            return;
          }
          //  const word = "consult";

          //  if (value.toLowerCase().includes(word.toLowerCase()))
        })
        .catch((err) => {
          console.log(err);
          if (
            (err.response &&
              err.response.data &&
              err.response.data.err.name === "TokenExpiredError") ||
            err.response.data.message === "Unauthorized"
          ) {
            navigate("/");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("srchData");
            sessionStorage.removeItem("prescriptionHistory");
            sessionStorage.removeItem("rights");
            navigate("/cri-crm");
            return err;
          }
          console.log(err);
          return err;
        });
    }
  };

  useEffect(() => {
    if (selectedSalesOptions && !toggleActiveSelection) {
      filterHandler();
    }
  }, [selectedSalesOptions, toggleActiveSelection]);

  useEffect(() => {
    if (filteredInvoices.length) {
      const data = filteredInvoices;

      setExcelFilteredSales(() =>
        data.map((item) => ({
          ...item,
          invoice_date: new Date(item.invoice_date).toLocaleDateString(),
          invoice_id: item.invoice_id,
          amount: item.total_amount,
          amount_paid: item.total_amount_paid,
          payment_mode: `${
            Array.isArray(item.payment_mode)
              ? item.payment_mode
                  .map((pm) => `${pm.mode}: ${pm.amount}`)
                  .join(", ")
              : item.payment_mode
          }`,
          items: item.invoice_sheet
            .map((obj) => {
              const key = Object.keys(obj)[0]; // Assuming there's only one key in obj
              const itemData = obj[key];
              if (itemData.item) {
                return {
                  item: itemData.item,
                  rate: itemData.rate,
                  tax: itemData.tax,
                  mrp: itemData.mrp || itemData.amount,
                  quantity: itemData.quantity || itemData.qty,
                };
              }
            })
            .filter((item) => item)
            .map(
              (x) =>
                `${x.item} - Rate : ${x.rate}, Qty: ${x.quantity} - Tax: ${x.tax} Amount: ${x.mrp},`
            )
            .join(", "),
        }))
      );
    }
  }, [filteredInvoices]);

  return (
    <Row>
      <Col className="text-center" md={12}>
        <Card className="mt-3 pt-3">
          <CardTitle>
            <b>Sales Report</b>
            <div className="d-flex">
              {" "}
              <FormGroup className="me-3 ps-2">
                <Label for="filterType">Filter Type:</Label>
                <Input
                  type="select"
                  name="filterType"
                  id="filterType"
                  value={filterType}
                  onChange={(e) => {
                    setFilterType(e.target.value);
                    sessionStorage.setItem(
                      "filterType",
                      JSON.stringify(e.target.value)
                    );
                  }}
                >
                  <option value="">Select</option>
                  <option value="yearly">Yearly</option>
                  <option value="monthly">Monthly</option>
                  <option value="date">Date</option>
                </Input>
              </FormGroup>
              {filterType === "date" && (
                <FormGroup>
                  <Label for="selectedDate">Select Date:</Label>
                  <Input
                    type="date"
                    name="selectedDate"
                    id="selectedDate"
                    value={selectedDate}
                    onChange={(e) => {
                      clearError();

                      setSelectedDate(e.target.value);
                      setFromDate("");
                      setToDate("");
                      sessionStorage.setItem(
                        "selectedDate",
                        JSON.stringify(e.target.value)
                      );
                      sessionStorage.removeItem("fromDate");
                      sessionStorage.removeItem("toDate");
                    }}
                    invalid={dateError !== ""}
                  />
                </FormGroup>
              )}
              {filterType !== "date" && (
                <>
                  <FormGroup>
                    <Label for="fromDate">From Date:</Label>
                    <Input
                      type="date"
                      name="fromDate"
                      id="fromDate"
                      value={fromDate}
                      onChange={(e) => {
                        setFromDate(e.target.value);
                        sessionStorage.setItem(
                          "fromDate",
                          JSON.stringify(e.target.value)
                        );
                        clearError();
                      }}
                      invalid={fromDateError !== ""}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="toDate">To Date:</Label>
                    <Input
                      type="date"
                      name="toDate"
                      id="toDate"
                      value={toDate}
                      onChange={(e) => {
                        setToDate(e.target.value);
                        sessionStorage.setItem(
                          "toDate",
                          JSON.stringify(e.target.value)
                        );
                        clearError();
                      }}
                      invalid={toDateError !== ""}
                    />
                  </FormGroup>
                </>
              )}
              {
                <FormGroup>
                  <Label for="item">Item:</Label>
                  <Input
                    type="select"
                    name="item"
                    id="item"
                    value={selectedItem}
                    onChange={(e) => {
                      setSelectedItem(e.target.value);
                      clearError();
                    }}
                  >
                    <option value="">Select Item</option>
                    {itemsData.length &&
                      itemsData.map((item) => (
                        <option key={item.productId} value={item.item}>
                          {item.item}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              }
              <FormGroup>
                <MultiSelectDropdown
                  setSelectedSalesOptions={setSelectedSalesOptions}
                  setToggleActiveSelection={setToggleActiveSelection}
                />
              </FormGroup>
              <button
                outline
                size="sm"
                color="primary"
                onClick={filterHandler}
                className=" ms-3"
                style={{
                  width: "auto",
                  height: "40px",
                  borderRadius: "10px",
                  marginTop: "30px",
                }}
              >
                Search
              </button>
            </div>
          </CardTitle>
          <CardBody>
            <ReportChart
              data={filteredInvoices}
              chartName="Sales Report"
              total={totalFilteredInvoices}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SalesReport;
