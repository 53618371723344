import React, { useRef, useEffect } from "react";
import QRCode from "qrcode.react";
import "./Style.css"; // Import your CSS file

const QRCodeWithLogoComponent = ({ id, sid }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current.querySelector("canvas");
    const ctx = canvas.getContext("2d");
    const logo = new Image();
    logo.src = "https://example.com/logo.png"; // Replace with your logo URL
    logo.onload = () => {
      const logoSize = 50; // Adjust the size of the logo
      const x = (canvas.width - logoSize) / 2;
      const y = (canvas.height - logoSize) / 2;
      ctx.drawImage(logo, x, y, logoSize, logoSize);
    };
    console.log(id, sid);
  }, []);

  return (
    <div ref={canvasRef} className="qr-code-container">
      <QRCode
        value="https://example.com"
        size={126}
        bgColor="#ffffff"
        fgColor="#000000"
        level="H"
      />
    </div>
  );
};

export default QRCodeWithLogoComponent;
