import axios from "axios";
import { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { Table, Button, Input, Row, Col } from "reactstrap";

const LabCategoryEntry = ({ getLabItemsHandler, toggle, editData }) => {
  const auth = useSelector((state) => state.authentication);
  const itemsList = useSelector((state) => state.itemsList);
  const { itemsData } = itemsList;
  // const dispatch = useDispatch();
  const { userData, branchData } = auth;
  const [errors, setErrors] = useState([]);

  const [category, setCategory] = useState([
    {
      categoryName: "",
      tests: [
        {
          testName: "",
          specimen: "",
          referenceRange: "",
          units: "",
          method: "",
          notes: "",
          subTests: [
            {
              subTestName: "",
              specimen: "",
              referenceRange: "",
              units: "",
              method: "",
              notes: "",
            },
          ],
        },
      ],
      createdBy: userData.user,
    },
  ]);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    if (editData) {
      setCategory([editData]);
    }
  }, [editData]);

  const handleCategoryChange = (categoryIndex, field, value) => {
    setCategory((prevCategory) => {
      const newCategory = [...prevCategory];
      newCategory[categoryIndex] = {
        ...newCategory[categoryIndex],
        [field]: value,
      };
      return newCategory;
    });
  };

  const handleTestChange = (categoryIndex, testIndex, field, value) => {
    setCategory((prevCategory) => {
      const newCategory = prevCategory.map((cat, index) => {
        if (index !== categoryIndex) {
          return cat;
        }

        const newTests = (cat.tests || []).map((test, tIndex) => {
          if (tIndex !== testIndex) {
            return test;
          }
          return { ...test, [field]: value };
        });

        return { ...cat, tests: newTests };
      });

      return newCategory;
    });
  };

  const handleSubTestChange = (
    categoryIndex,
    testIndex,
    subTestIndex,
    field,
    value
  ) => {
    setCategory((prevCategory) => {
      const newCategory = prevCategory.map((cat, cIndex) => {
        if (cIndex !== categoryIndex) {
          return cat;
        }

        const newTests = (cat.tests || []).map((test, tIndex) => {
          if (tIndex !== testIndex) {
            return test;
          }

          const updatedSubTests = (test.subTests || []).map(
            (subTest, sIndex) => {
              if (sIndex !== subTestIndex) {
                return subTest;
              }
              return { ...subTest, [field]: value };
            }
          );

          return { ...test, subTests: updatedSubTests };
        });

        return { ...cat, tests: newTests };
      });

      return newCategory;
    });
  };

  const addRow = () => {
    setCategory([
      ...category,
      {
        categoryName: "",
        createdBy: userData.user,
        tests: [],
      },
    ]);
  };

  const addTestRow = (categoryIndex) => {
    setCategory((prevCategory) => {
      const newCategory = prevCategory.map((cat, index) => {
        if (index !== categoryIndex) {
          return cat;
        }
        return {
          ...cat,
          tests: [
            ...(cat.tests || []),
            {
              testName: "",
              specimen: "",
              referenceRange: "",
              units: "",
              method: "",
              notes: "",
              subTests: [],
            },
          ],
        };
      });
      return newCategory;
    });
  };

  const addSubTestRow = (categoryIndex, testIndex) => {
    setCategory((prevCategory) => {
      const newCategory = prevCategory.map((cat, cIndex) => {
        if (cIndex !== categoryIndex) {
          return cat;
        }

        const newTests = (cat.tests || []).map((test, tIndex) => {
          if (tIndex !== testIndex) {
            return test;
          }

          const currentSubTests = test.subTests || [];

          const updatedSubTests = [
            ...currentSubTests,
            {
              subTestName: "",
              specimen: "",
              referenceRange: "",
              units: "",
              method: "",
              notes: "",
            },
          ];

          return { ...test, subTests: updatedSubTests };
        });

        return { ...cat, tests: newTests };
      });

      return newCategory;
    });
  };

  const removeCategory = (categoryIndex) => {
    setCategory((prevCategory) => {
      const newCategory = [...prevCategory];
      newCategory.splice(categoryIndex, 1);
      return newCategory;
    });
  };

  const removeTestRow = (categoryIndex, testIndex) => {
    setCategory((prevCategory) => {
      const newCategory = prevCategory.map((cat, index) => {
        if (index !== categoryIndex) {
          return cat;
        }
        return {
          ...cat,
          tests: cat.tests.filter((_, tIndex) => tIndex !== testIndex),
        };
      });
      return newCategory;
    });
  };

  const removeSubTestRow = (categoryIndex, testIndex, subTestIndex) => {
    setCategory((prevCategory) => {
      const newCategory = prevCategory.map((cat, cIndex) => {
        if (cIndex !== categoryIndex) {
          return cat;
        }

        const newTests = (cat.tests || []).map((test, tIndex) => {
          if (tIndex !== testIndex) {
            return test;
          }

          const updatedSubTests = (test.subTests || []).filter(
            (_, sIndex) => sIndex !== subTestIndex
          );

          return { ...test, subTests: updatedSubTests };
        });

        return { ...cat, tests: newTests };
      });

      return newCategory;
    });
  };

  const validateCategory = () => {
    const newErrors = [];

    category.forEach((category, categoryIndex) => {
      const categoryErrors = {};

      if (!category || !category.categoryName) {
        categoryErrors.categoryName = "Category Name is required";
      }

      if (category.tests) {
        category.tests.forEach((test, testIndex) => {
          if (!test || !test.testName) {
            categoryErrors[`test${testIndex}`] = "Test Name is required";
          }

          if (test.subTests) {
            test.subTests.forEach((subTest, subTestIndex) => {
              if (!subTest || !subTest.subTestName) {
                categoryErrors[`subTest${testIndex}_${subTestIndex}`] =
                  "SubTest Name is required";
              }
            });
          }
        });
      }

      if (Object.keys(categoryErrors).length > 0) {
        newErrors.push({
          categoryIndex,
          categoryErrors,
        });
      }
    });

    setErrors(newErrors);

    return newErrors.length === 0;
  };

  const submitHandler = () => {
    if (!validateCategory()) return;

    const apiUrl = editData
      ? `${process.env.REACT_APP_CRI_API}edit-lab-cat`
      : `${process.env.REACT_APP_CRI_API}add-lab-cat`;

    const requests = category.map((category) =>
      axios.post(apiUrl, category, {
        params: {
          branch: userData.branch,
          ...(editData && { id: category._id }),
        },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
    );

    axios
      .all(requests)
      .then((responses) => {
        responses.forEach(() => {
          getLabItemsHandler();
          toggle();
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setServerError(error.response.data.message);
        } else {
          setServerError("An unexpected error occurred. Please try again.");
        }
      });
  };

  return (
    <div>
      {serverError && <div className="text-danger mb-3">{serverError}</div>}
      <Table>
        <thead>
          <tr>
            <th>Category Name</th>
            <th>Tests</th>
          </tr>
        </thead>
        <tbody>
          {category.map((cat, categoryIndex) => (
            <Fragment key={cat._id || categoryIndex}>
              <tr>
                <td style={{ width: "250px" }}>
                  <Input
                    type="text"
                    value={cat.categoryName}
                    placeholder="Category"
                    onChange={(e) =>
                      handleCategoryChange(
                        categoryIndex,
                        "categoryName",
                        e.target.value
                      )
                    }
                  />
                  {errors.find((error) => error.categoryIndex === categoryIndex)
                    ?.categoryErrors?.categoryName && (
                    <div className="text-danger">
                      {
                        errors.find(
                          (error) => error.categoryIndex === categoryIndex
                        ).categoryErrors.categoryName
                      }
                    </div>
                  )}
                </td>
                <td>
                  <Table>
                    <thead>
                      <tr>
                        <th>Test Name</th>
                        <th>Specimen</th>
                        <th>Reference Range</th>
                        <th>Units</th>
                        <th>Method</th>
                        <th>Notes</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(cat.tests || []).map((test, testIndex) => (
                        <Fragment key={test._id || testIndex}>
                          <tr>
                            <td>
                              <Input
                                type="text"
                                placeholder="Test Name"
                                value={test.testName}
                                onChange={(e) =>
                                  handleTestChange(
                                    categoryIndex,
                                    testIndex,
                                    "testName",
                                    e.target.value
                                  )
                                }
                              />
                              {errors.find(
                                (error) => error.categoryIndex === categoryIndex
                              )?.categoryErrors?.[`test${testIndex}`] && (
                                <div className="text-danger">
                                  {
                                    errors.find(
                                      (error) =>
                                        error.categoryIndex === categoryIndex
                                    ).categoryErrors[`test${testIndex}`]
                                  }
                                </div>
                              )}
                            </td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Specimen"
                                value={test.specimen}
                                onChange={(e) =>
                                  handleTestChange(
                                    categoryIndex,
                                    testIndex,
                                    "specimen",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Reference Range"
                                value={test.referenceRange}
                                onChange={(e) =>
                                  handleTestChange(
                                    categoryIndex,
                                    testIndex,
                                    "referenceRange",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Units"
                                value={test.units}
                                onChange={(e) =>
                                  handleTestChange(
                                    categoryIndex,
                                    testIndex,
                                    "units",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Method"
                                value={test.method}
                                onChange={(e) =>
                                  handleTestChange(
                                    categoryIndex,
                                    testIndex,
                                    "method",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Input
                                type="text"
                                placeholder="Notes"
                                value={test.notes}
                                onChange={(e) =>
                                  handleTestChange(
                                    categoryIndex,
                                    testIndex,
                                    "notes",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Button
                                color="danger"
                                onClick={() =>
                                  removeTestRow(categoryIndex, testIndex)
                                }
                              >
                                Remove Test
                              </Button>
                              <Button
                                color="primary"
                                onClick={() =>
                                  addSubTestRow(categoryIndex, testIndex)
                                }
                              >
                                Add SubTest
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="7">
                              <Table>
                                <thead>
                                  <tr>
                                    <th>SubTest Name</th>
                                    <th>Specimen</th>
                                    <th>Reference Range</th>
                                    <th>Units</th>
                                    <th>Method</th>
                                    <th>Notes</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(test.subTests || []).map(
                                    (subTest, subTestIndex) => (
                                      <tr key={subTest._id || subTestIndex}>
                                        <td>
                                          <Input
                                            type="text"
                                            placeholder="SubTest Name"
                                            value={subTest.subTestName}
                                            onChange={(e) =>
                                              handleSubTestChange(
                                                categoryIndex,
                                                testIndex,
                                                subTestIndex,
                                                "subTestName",
                                                e.target.value
                                              )
                                            }
                                          />
                                          {errors.find(
                                            (error) =>
                                              error.categoryIndex ===
                                              categoryIndex
                                          )?.categoryErrors?.[
                                            `subTest${testIndex}_${subTestIndex}`
                                          ] && (
                                            <div className="text-danger">
                                              {
                                                errors.find(
                                                  (error) =>
                                                    error.categoryIndex ===
                                                    categoryIndex
                                                ).categoryErrors[
                                                  `subTest${testIndex}_${subTestIndex}`
                                                ]
                                              }
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            placeholder="Specimen"
                                            value={subTest.specimen}
                                            onChange={(e) =>
                                              handleSubTestChange(
                                                categoryIndex,
                                                testIndex,
                                                subTestIndex,
                                                "specimen",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            placeholder="Reference Range"
                                            value={subTest.referenceRange}
                                            onChange={(e) =>
                                              handleSubTestChange(
                                                categoryIndex,
                                                testIndex,
                                                subTestIndex,
                                                "referenceRange",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            placeholder="Units"
                                            value={subTest.units}
                                            onChange={(e) =>
                                              handleSubTestChange(
                                                categoryIndex,
                                                testIndex,
                                                subTestIndex,
                                                "units",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            placeholder="Method"
                                            value={subTest.method}
                                            onChange={(e) =>
                                              handleSubTestChange(
                                                categoryIndex,
                                                testIndex,
                                                subTestIndex,
                                                "method",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="text"
                                            placeholder="Notes"
                                            value={subTest.notes}
                                            onChange={(e) =>
                                              handleSubTestChange(
                                                categoryIndex,
                                                testIndex,
                                                subTestIndex,
                                                "notes",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <Button
                                            color="danger"
                                            onClick={() =>
                                              removeSubTestRow(
                                                categoryIndex,
                                                testIndex,
                                                subTestIndex
                                              )
                                            }
                                          >
                                            Remove SubTest
                                          </Button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </td>
                          </tr>
                        </Fragment>
                      ))}
                    </tbody>
                  </Table>
                  <Button
                    color="primary"
                    onClick={() => addTestRow(categoryIndex)}
                  >
                    Add Test
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </Table>
      <Row className="mt-3">
        <Col>
          {!editData && (
            <Button color="primary" onClick={addRow}>
              Add Category
            </Button>
          )}
          <Button color="success" className="ml-3" onClick={submitHandler}>
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default LabCategoryEntry;
