import axios from "axios";
import React, { useEffect, useState } from "react";
import { Edit, Search, Slash, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Badge,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  InputGroup,
  InputGroupText,
  Tooltip,
} from "reactstrap";
import { getInventoryItems } from "../../../../redux/inventory";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import PurchaseForm from "./Purchase/PurchaseForm";
import PurchaseEntry from "./Purchase/Bills/PurchaseEntry";
import PaginatedList from "./PaginatedList";

const StockManagementPage = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTip = () => setTooltipOpen(!tooltipOpen);
  const itemsList = useSelector((state) => state.itemsList);
  const dispatch = useDispatch();
  const { itemsData, supplierData, purchaseData } = itemsList;
  const auth = useSelector((state) => state.authentication);
  const { userData } = auth;
  const [paginatedList, setPaginatedList] = useState([]);
  const [itemName, setItemName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [alertCount, setAlertCount] = useState("");
  const [formData, setFormData] = useState("");
  const [modal, setModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();
  const [customerDataDownload, setCustomerDataDownload] = useState("");
  const toggle = () => setModal(!modal);
  const [expiryAlertCount, setExpiryAlertCount] = useState("");
  const branch = sessionStorage.getItem("branch")
    ? JSON.parse(sessionStorage.getItem("branch"))
    : "";
  const [token] = useState(
    userData ? userData.token : JSON.parse(sessionStorage.getItem("token"))
  );

  const oneMonthLater = new Date();

  useEffect(() => {
    if (itemsList && itemsData.length) {
      setAlertCount(() => {
        let count = 0;
        itemsData.forEach((item) => {
          if (Number(item.quantity) <= Number(item.reorderPoint) + 1) {
            count++;
          }
        });
        return count;
      });
      setExpiryAlertCount(() => {
        let countX = 0;
        //   oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

        itemsData.forEach((item) => {
          const expiryDate = new Date(item.expiry);
          //  expiryDate.setMonth(oneMonthLater.getMonth() + 1);
          // Check if expiry date is within one month from now
          if (expiryDate < oneMonthLater && item.quantity > 0) {
            countX++;
          }
        });
        return countX;
      });
    }
    //   Brand:item.brandName,
    //   Price:item.unitPrice,
    //   MRP:item.mrp,
    //   Batch:item.batch,
    //   Expiry:item.expiry,
    //   HSN:item.hsn,
    //   Manufacturer:item.manufacturer,
    //   Quantity:item.quantity,
    //   Location:item.location,
    //   Category:item.category,
    //   Min_Stock:item.minStockLevel,
    //   Max_Stock:item.maxStockLevel,
    //   Reorder_Point:item.reorderPoint,
    //   Reorder_Qty:item.reorderQuantity,
    //   Supplier:supplierData.find(sup => sup.supplierId === item.supplierId)?.supplierName,
    //   Barcode:item.barcode,
    //   Notes:item.notes,
    //   ProductId:item.productId,
    //   Tax:item.tax
    if (itemsData && itemsData.length && supplierData && supplierData.length) {
      setCustomerDataDownload(() =>
        itemsData.map((item, id) => {
          return {
            SL_No: id + 1,
            Item: item.item,
            Brand: item.brandName,
            Price: item.unitPrice,
            MRP: item.mrp,
            Batch: item.batch,
            Expiry: item.expiry,
            HSN: item.hsn,
            Manufacturer: item.manufacturer,
            Quantity: item.quantity,
            Location: item.location,
            Category: item.category,
            Min_Stock: item.minStockLevel,
            Max_Stock: item.maxStockLevel,
            Reorder_Point: item.reorderPoint,
            Reorder_Qty: item.reorderQuantity,
            Supplier: supplierData.find(
              (sup) => sup.supplierId === item.supplierId
            )?.supplierName,
            Barcode: item.barcode,
            Notes: item.notes,
            ProductId: item.productId,
            Tax: item.tax,
          };
        })
      );
    }
  }, [itemsList]);

  const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "my-file.xlsx");
  };

  const editInventoryHandler = (Data, toggle) => {
    setFormData(Data);
    toggle();
  };

  //const getInventoryPageHandler = () => {
  //  // branch, invoice_id, page, limit
  //  axios
  //    .get(`${process.env.REACT_APP_CRI_API}inventory-page`, {
  //      params: {
  //        branch,
  //        invoice_id: "",
  //        page: 1,
  //        limit: 10,
  //      },
  //      headers: {
  //        Authorization: `Bearer ${token}`,
  //      },
  //    })
  //    .then((res) => {
  //      console.log(res);
  //    });
  //};

  //useEffect(() => {
  //  getInventoryPageHandler();
  //}, []);

  const deleteHandler = (item) => {
    const batch = prompt("Please enter the batch to confirm delete");
    if (batch && batch.toLocaleLowerCase() === item.batch.toLocaleLowerCase()) {
      {
        axios
          .post(
            `${process.env.REACT_APP_CRI_API}add-items`,
            {
              item: item,
              date_removed: new Date(),
              status: "",
              removedBy: userData.user,
            },
            {
              params: { branch: userData.branch },
              headers: {
                Authorization: `Bearer ${userData.token}`,
              },
            }
          )
          .then((res) => {
            axios
              .delete(`${process.env.REACT_APP_CRI_API}delete-inventory`, {
                params: { id: item._id, branch: userData.branch },
                headers: {
                  Authorization: `Bearer ${userData.token}`,
                },
              })
              .then((res) => {
                if (res) {
                  dispatch(
                    getInventoryItems({
                      token: userData.token,
                      branch: userData.branch,
                    })
                  );
                }
              })
              .catch((err) => {
                if (
                  (err.response.data &&
                    err.response.data.err &&
                    err.response.data.err.name === "TokenExpiredError") ||
                  err.response.data.message === "Unauthorized"
                ) {
                  navigate("/");
                  sessionStorage.removeItem("token");
                  sessionStorage.removeItem("srchData");
                  sessionStorage.removeItem("prescriptionHistory");
                  sessionStorage.removeItem("rights");
                  navigate("/cri-crm");
                  return err;
                }
              });
          });
      }
    } else if (batch) {
      alert("Batch not matching");
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={2}>
          <span href="#" id="expiry">
            <Card className="m-3">
              <CardHeader>
                <Row className="d-flex justify-content-center">
                  <h6>Expiry Alert </h6>
                  <Badge
                    color="danger"
                    style={{
                      width: "30px",
                      position: "absolute",
                      right: "-10px",
                      top: "-10px",
                    }}
                  >
                    {expiryAlertCount}
                  </Badge>{" "}
                </Row>
              </CardHeader>
            </Card>
          </span>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            autohide={false}
            target="expiry"
            toggle={toggleTip}
          >
            {itemsData.length &&
              itemsData.map((item, id) => {
                const expiryDate = new Date(item.expiry);
                const isExpiringSoon = expiryDate < oneMonthLater;
                if (isExpiringSoon && item.quantity > 0)
                  return (
                    <p key={id}>
                      {item.item} {item.quantity} {item.batch}
                    </p>
                  );
              })}
          </Tooltip>
        </Col>
        <Col md={3}>
          <Card className="m-3">
            <CardHeader>
              <Row className="d-flex justify-content-center">
                <h6>Stock Reorder Alert </h6>
                <Badge
                  color="warning"
                  style={{
                    width: "30px",
                    position: "absolute",
                    right: "-10px",
                    top: "-10px",
                  }}
                >
                  {alertCount}
                </Badge>{" "}
              </Row>
            </CardHeader>
          </Card>
        </Col>
        <Col md={3} className="pt-4">
          {" "}
          <InputGroup className="mb-4">
            <Input
              // color="primary"
              type="text"
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search Medicine Name"
            />
            <InputGroupText>
              {" "}
              <Search />
            </InputGroupText>
          </InputGroup>
        </Col>
        <Col className="pt-4">
          <button onClick={() => exportToExcel(customerDataDownload)}>
            Download
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Stock List</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <PaginatedList
            setPaginatedList={setPaginatedList}
            searchInput={searchInput}
            setShowSpinner={setShowSpinner}
            endPoint="inventory-page"
            limit={100}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {(!paginatedList || showSpinner) && <Spinner color="primary" />}
          {paginatedList && !showSpinner && paginatedList.length && (
            <div className="table-container">
              <Table responsive striped>
                <thead>
                  <tr className="table-secondary">
                    <th>Sl No.</th>
                    <th style={{ width: "20%" }}>Item Name</th>
                    {
                      //<th>Brand Name</th>
                    }
                    <th>HSN/SAC</th>
                    <th>Unit Price</th>
                    <th>MRP</th>
                    <th>Quantity</th>
                    <th>Batch</th>
                    <th>Minimum Stock</th>
                    <th>Maximum Stock</th>
                    <th>Reorder Point</th>
                    <th>Expiry Date</th>
                    {
                      // <th>Supplier</th>
                    }
                    <th>Edit</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    // remove redudunt stocks display here
                  }
                  {paginatedList
                    //   .filter(
                    //     (item) =>
                    //       !searchInput ||
                    //       item.item
                    //         .toLowerCase()
                    //         .includes(searchInput.toLowerCase())
                    //   )
                    .map((item, id) => {
                      const expiryDate = new Date(item.expiry);
                      const isExpiringSoon = expiryDate < oneMonthLater;

                      const itemClass = isExpiringSoon
                        ? "table-danger"
                        : Number(item.quantity) <= Number(item.reorderPoint) + 1
                        ? "table-warning"
                        : "";

                      const selectedSupplier =
                        supplierData.length &&
                        purchaseData.length &&
                        supplierData.find(
                          (sup) => sup.supplierId === item.supplierId
                        );

                      const supplierName = selectedSupplier
                        ? selectedSupplier.supplierName
                        : "";

                      if (item.item)
                        return (
                          <tr key={id} className={itemClass}>
                            <td>{id + 1}</td>
                            <td>{item.item}</td>
                            {
                              //<td>{item.brandName}</td>
                            }
                            <td>{item.hsn}</td>
                            <td>{item.unitPrice}</td>
                            <td>{item.mrp}</td>
                            <td>{item.quantity}</td>
                            <td>{item.batch}</td>
                            <td>{item.minStockLevel}</td>
                            <td>{item.maxStockLevel}</td>

                            <td>{item.reorderPoint}</td>
                            <td>{item.expiry}</td>
                            {
                              // <td>
                              //   {
                              //     supplierData.find(
                              //       (sup) => sup.supplierId === item.supplierId
                              //     )?.supplierName
                              //   }
                              // </td>
                            }
                            <td>
                              {(userData.rights === "Admin" ||
                                userData.rights === "Manager" ||
                                userData.rights === "Accounts") && (
                                <Edit
                                  color="green"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    editInventoryHandler(item, toggle);
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {(userData.rights === "Admin" ||
                                userData.rights === "Manager" ||
                                userData.rights === "Accounts") && (
                                <Slash
                                  color="red"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => deleteHandler(item)}
                                />
                              )}
                            </td>
                          </tr>
                        );
                    })}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle} fullscreen>
        <ModalHeader toggle={toggle}>Edit Inventory</ModalHeader>
        <ModalBody>
          <PurchaseEntry
            editInventoryData={formData}
            setShowModal={setShowModal}
            togglePurchaseEntry={toggle}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showModal} centered>
        <ModalBody style={{ textAlign: "center", padding: "20px" }}>
          <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
          <p
            style={{ marginTop: "10px", fontSize: "16px", fontWeight: "bold" }}
          >
            Saving...
          </p>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default StockManagementPage;
