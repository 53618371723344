import axios from "axios";
import { useState, useEffect, Fragment, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  Button,
  Input,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Spinner,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import PatientDetailsForm from "./PatientDetailsForm";
import QRCodeWithLogoComponent from "./QRCodeComponent";
import PrintReport from "./PrintReport";
import ReactToPrint from "react-to-print";
import { useLocation, useNavigate } from "react-router-dom";

const LabCategoryEntry = ({ getLabItemsHandler, toggle, args }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location.state);
  const [loadingModal, setLoadingModal] = useState(false);
  const toggleLoadingModal = () => setLoadingModal(!loadingModal);
  const auth = useSelector((state) => state.authentication);
  const itemsList = useSelector((state) => state.itemsList);
  const lab = useSelector((state) => state.lab);
  const { labCategory } = lab;
  const { itemsData } = itemsList;
  // const dispatch = useDispatch();
  const { userData, branchData } = auth;
  const componentRef = useRef();
  const [address, setAddress] = useState("");
  const [errors, setErrors] = useState([]);
  const [labTechnician, setLabTechnician] = useState("");
  const [patientData, setPatientData] = useState("");
  const [doctor, setDoctor] = useState("");
  const [editData, setEditData] = useState(
    sessionStorage.getItem("editData")
      ? JSON.parse(sessionStorage.getItem("editData"))
      : ""
  );
  const [editMode, setEditMode] = useState(false);
  const [category, setCategory] = useState([
    {
      categoryName: "",
      tests: [
        {
          testName: "",
          results: "",
          specimen: "",
          referenceRange: "",
          units: "",
          method: "",
          notes: "",
          subTests: [
            {
              subTestName: "",
              results: "",
              specimen: "",
              referenceRange: "",
              units: "",
              method: "",
              notes: "",
            },
          ],
        },
      ],
      createdBy: userData.user,
    },
  ]);
  const [serverError, setServerError] = useState(null);
  const patientDetailsRef = useRef();

  useEffect(() => {
    if (branchData.length > 0) {
      const { first_line, second_line, third_line, fourth_line } =
        branchData[0].address;
      const { GSTIN } = branchData[0];
      // setGSTIN(GSTIN);

      setAddress({
        first_line,
        second_line,
        third_line,
        fourth_line,
      });
    }
  }, [branchData]);

  const patientDetailsClearHandler = () => {
    if (patientDetailsRef.current) {
      patientDetailsRef.current.clearHandler();
    }
  };

  useEffect(() => {
    if (data) {
      setEditData(data);
    }
  }, [data, editMode]);

  useEffect(() => {
    const isValidCategory = category.some(
      (cat) => cat.categoryName && cat.tests.some((test) => test.testName)
    );

    if (isValidCategory) {
      sessionStorage.setItem("labTestEntry", JSON.stringify(category));
      // console.log("isvalid");
    }
    if (editData?.results && !editMode) {
      sessionStorage.setItem("labTestEntry", JSON.stringify(editData.results));
      setCategory(editData.results);
      // console.log("results");
    }
    if ((editData.doctor || editData.labTechnician) && !editMode) {
      sessionStorage.setItem(
        "doc-lab",
        JSON.stringify({
          doctor: editData.doctor,
          labTechnician: editData.labTechnician,
        })
      );
    }
  }, [category, editData, editMode]);

  useEffect(() => {
    if (doctor || labTechnician) {
      sessionStorage.setItem(
        "doc-lab",
        JSON.stringify({ doctor, labTechnician })
      );
    }
  }, [doctor, labTechnician]);

  useEffect(() => {
    if (sessionStorage.getItem("labTestEntry")) {
      // console.log(JSON.parse(sessionStorage.getItem("labTestEntry")));
      setCategory(JSON.parse(sessionStorage.getItem("labTestEntry")));
      setDoctor(JSON.parse(sessionStorage.getItem("labTestEntry")).doctor);
      setLabTechnician(
        JSON.parse(sessionStorage.getItem("labTestEntry")).labTechnician
      );
    }
    if (sessionStorage.getItem("doc-lab")) {
      //  console.log(JSON.parse(sessionStorage.getItem("doc-lab")));
      setLabTechnician(
        JSON.parse(sessionStorage.getItem("doc-lab")).labTechnician
      );
      setDoctor(JSON.parse(sessionStorage.getItem("doc-lab")).doctor);
    }
    // console.log(JSON.parse(sessionStorage.getItem("labTestEntry")));
  }, []);

  const clearHandler = () => {
    if (window.confirm("Please comfirm to clear the form")) {
      patientDetailsClearHandler();
      sessionStorage.removeItem("labTestEntry");
      sessionStorage.removeItem("doc-lab");
      sessionStorage.removeItem("editData");
      setEditData("");
      setDoctor("");
      setLabTechnician("");
      setCategory([
        {
          categoryName: "",
          tests: [
            {
              testName: "",
              results: "",
              specimen: "",
              referenceRange: "",
              units: "",
              method: "",
              notes: "",
              subTests: [
                {
                  subTestName: "",
                  results: "",
                  specimen: "",
                  referenceRange: "",
                  units: "",
                  method: "",
                  notes: "",
                },
              ],
            },
          ],
          createdBy: userData.user,
        },
      ]);
    }
  };

  const handleCategoryChange = (categoryIndex, field, value) => {
    setCategory((prevCategory) => {
      const newCategory = [...prevCategory];
      const selectedCategory = newCategory[categoryIndex];

      // Check if there are no tests and add an empty test row if necessary
      //   if (!selectedCategory.tests || selectedCategory.tests.length === 0) {
      selectedCategory.tests = [
        {
          testName: "",
          specimen: "",
          referenceRange: "",
          units: "",
          method: "",
          notes: "",
          subTests: [],
        },
      ];
      //  }

      newCategory[categoryIndex] = {
        ...selectedCategory,
        [field]: value,
      };

      return newCategory;
    });
  };

  const handleTestChange = (categoryIndex, testIndex, field, value) => {
    const selectedCategory = category[categoryIndex];
    const selectedTestDetails = labCategory
      .find((labcat) => labcat.categoryName === selectedCategory.categoryName)
      ?.tests.find((test) => test.testName === value);

    setCategory((prevCategory) => {
      const newCategory = prevCategory.map((cat, index) => {
        if (index !== categoryIndex) {
          return cat;
        }
        const newTests = (cat.tests || []).map((test, tIndex) => {
          if (tIndex !== testIndex) {
            return test;
          }

          // Add new subtests if there are no existing subtests
          const newSubTests =
            selectedTestDetails?.subTests?.map((subTestDetails) => ({
              subTestName: subTestDetails.subTestName,
              specimen: subTestDetails.specimen || "",
              referenceRange: subTestDetails.referenceRange || "",
              units: subTestDetails.units || "",
              method: subTestDetails.method || "",
              notes: subTestDetails.notes || "",
            })) || [];

          if (field === "testName") {
            return {
              ...test,
              [field]: value,
              results: selectedTestDetails?.results || "",
              specimen: selectedTestDetails?.specimen || "",
              referenceRange: selectedTestDetails?.referenceRange || "",
              units: selectedTestDetails?.units || "",
              method: selectedTestDetails?.method || "",
              notes: selectedTestDetails?.notes || "",
              subTests: newSubTests.length ? newSubTests : [],
            };
          }
          return {
            ...test,
            [field]: value,
          };
        });

        return { ...cat, tests: newTests };
      });

      return newCategory;
    });
  };

  const handleSubTestChange = (
    categoryIndex,
    testIndex,
    subTestIndex,
    field,
    value
  ) => {
    const selectedCategory = category[categoryIndex];
    const selectedTest = selectedCategory.tests[testIndex];
    const selectedSubTestDetails = labCategory
      .find((labcat) => labcat.categoryName === selectedCategory.categoryName)
      ?.tests.find((test) => test.testName === selectedTest.testName)
      ?.subTests.find((subTest) => subTest.subTestName === value);

    setCategory((prevCategory) => {
      const newCategory = prevCategory.map((cat, index) => {
        if (index !== categoryIndex) {
          return cat;
        }
        const newTests = (cat.tests || []).map((test, tIndex) => {
          if (tIndex !== testIndex) {
            return test;
          }
          const newSubTests = (test.subTests || []).map((subTest, stIndex) => {
            if (stIndex !== subTestIndex) {
              return subTest;
            }
            if (field === "subTestName")
              return {
                ...subTest,
                [field]: value,
                specimen: selectedSubTestDetails?.specimen || "",
                referenceRange: selectedSubTestDetails?.referenceRange || "",
                units: selectedSubTestDetails?.units || "",
                method: selectedSubTestDetails?.method || "",
                notes: selectedSubTestDetails?.notes || "",
              };
            return {
              ...subTest,
              [field]: value,
            };
          });

          return { ...test, subTests: newSubTests };
        });

        return { ...cat, tests: newTests };
      });

      return newCategory;
    });
  };

  const addRow = () => {
    setCategory([
      ...category,
      {
        categoryName: "",
        createdBy: userData.user,
        tests: [],
      },
    ]);
  };

  const addTestRow = (categoryIndex) => {
    setCategory((prevCategory) => {
      const newCategory = prevCategory.map((cat, index) => {
        if (index !== categoryIndex) {
          return cat;
        }
        return {
          ...cat,
          tests: [
            ...(cat.tests || []),
            {
              testName: "",
              specimen: "",
              referenceRange: "",
              units: "",
              method: "",
              notes: "",
              subTests: [],
            },
          ],
        };
      });
      return newCategory;
    });
  };

  const addSubTestRow = (categoryIndex, testIndex) => {
    setCategory((prevCategory) => {
      const newCategory = prevCategory.map((cat, cIndex) => {
        if (cIndex !== categoryIndex) {
          return cat;
        }

        const newTests = (cat.tests || []).map((test, tIndex) => {
          if (tIndex !== testIndex) {
            return test;
          }

          const currentSubTests = test.subTests || [];

          const updatedSubTests = [
            ...currentSubTests,
            {
              subTestName: "",
              specimen: "",
              referenceRange: "",
              units: "",
              method: "",
              notes: "",
            },
          ];

          return { ...test, subTests: updatedSubTests };
        });

        return { ...cat, tests: newTests };
      });

      return newCategory;
    });
  };

  const removeCategory = (categoryIndex) => {
    setCategory((prevCategory) => {
      const newCategory = [...prevCategory];
      newCategory.splice(categoryIndex, 1);
      return newCategory;
    });
  };

  const removeTestRow = (categoryIndex, testIndex) => {
    setCategory((prevCategory) => {
      const newCategory = prevCategory.map((cat, index) => {
        if (index !== categoryIndex) {
          return cat;
        }
        return {
          ...cat,
          tests: cat.tests.filter((_, tIndex) => tIndex !== testIndex),
        };
      });
      return newCategory;
    });
  };

  const removeSubTestRow = (categoryIndex, testIndex, subTestIndex) => {
    setCategory((prevCategory) => {
      const newCategory = prevCategory.map((cat, cIndex) => {
        if (cIndex !== categoryIndex) {
          return cat;
        }

        const newTests = (cat.tests || []).map((test, tIndex) => {
          if (tIndex !== testIndex) {
            return test;
          }

          const updatedSubTests = (test.subTests || []).filter(
            (_, sIndex) => sIndex !== subTestIndex
          );

          return { ...test, subTests: updatedSubTests };
        });

        return { ...cat, tests: newTests };
      });

      return newCategory;
    });
  };

  const validateCategory = () => {
    const newErrors = [];

    category.forEach((category, categoryIndex) => {
      const categoryErrors = {};

      if (!category || !category.categoryName) {
        categoryErrors.categoryName = "Category Name is required";
      }

      if (category.tests) {
        category.tests.forEach((test, testIndex) => {
          if (!test || !test.testName) {
            categoryErrors[`test${testIndex}`] = "Test Name is required";
          }

          if (test.subTests) {
            test.subTests.forEach((subTest, subTestIndex) => {
              if (!subTest || !subTest.subTestName) {
                categoryErrors[`subTest${testIndex}_${subTestIndex}`] =
                  "SubTest Name is required";
              }
            });
          }
        });
      }

      if (Object.keys(categoryErrors).length > 0) {
        newErrors.push({
          categoryIndex,
          categoryErrors,
        });
      }
    });

    setErrors(newErrors);

    return newErrors.length === 0;
  };
  // patientData,
  // doctor,
  // labTechnician,
  // results,
  // createdBy,
  const postData = {
    patientData,
    doctor,
    labTechnician,
    results: category,
    createdBy: userData.user,
  };
  const submitHandler = () => {
    toggleLoadingModal();
    if (!validateCategory()) return;

    const apiUrl = editData
      ? `${process.env.REACT_APP_CRI_API}edit-lab-results`
      : `${process.env.REACT_APP_CRI_API}add-lab-results`;

    axios
      .post(apiUrl, postData, {
        params: {
          branch: userData.branch,
          ...(editData && { id: editData._id, SID: editData.SID }),
        },
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      })
      .then((response) => {
        setServerError("");
        setEditData(response.data);
        sessionStorage.setItem("editData", JSON.stringify(response.data));
        // console.log(response);
        setEditMode(false);
        alert("Edit Successful");
        navigate("/lab-admin/lab-results");

        // getLabR();
        // toggle();
      })
      .catch((error) => {
        if (
          (error.response &&
            error.response.data &&
            error.response.data.err.name === "TokenExpiredError") ||
          error.response.data.message === "Unauthorized"
        ) {
          navigate("/");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("srchData");
          sessionStorage.removeItem("prescriptionHistory");
          sessionStorage.removeItem("rights");
          navigate("/cri-crm");
          return error;
        }
        console.error("Error:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setServerError(error.response.data.message);
        } else {
          setServerError("An unexpected error occurred. Please try again.");
        }
      });
  };

  return (
    <div>
      <PatientDetailsForm
        setPatientData={setPatientData}
        savedResults={editData}
        ref={patientDetailsRef}
        setEditMode={setEditMode}
        editMode={editMode}
      />

      <Form className="p-3 pt-0">
        <FormGroup>
          <Row className="border-bottom py-1">
            <Col xs="4" className="font-weight-bold">
              <Label for="name" className="m-0">
                Doctor Name / Lab Technician :
              </Label>
            </Col>
            <Col xs="4">
              <Input
                type="text"
                name="doctor"
                id="doctor"
                placeholder="Doctor Name"
                value={doctor}
                readOnly={!editMode && editData}
                onChange={(e) => setDoctor(e.target.value)}
                className="mb-1"
              />
            </Col>
            <Col>
              <Input
                type="text"
                name="labTechnician"
                id="labTechnician"
                value={labTechnician}
                readOnly={!editMode && editData}
                placeholder="Lab Technician"
                onChange={(e) => setLabTechnician(e.target.value)}
                className="mb-1"
              />
            </Col>
          </Row>
        </FormGroup>
      </Form>
      {serverError && <div className="text-danger mb-3">{serverError}</div>}
      <Table>
        <thead>
          <tr>
            <th>Category Name</th>
          </tr>
        </thead>
        <tbody>
          {category.map((cat, categoryIndex) => (
            <Fragment key={cat._id || categoryIndex}>
              <tr>
                <td>
                  <Input
                    style={{ maxWidth: "250px" }}
                    type="select"
                    readOnly={!editMode && editData}
                    disabled={!editMode && editData}
                    value={cat.categoryName || ""}
                    onChange={(e) =>
                      handleCategoryChange(
                        categoryIndex,
                        "categoryName",
                        e.target.value
                      )
                    }
                  >
                    <option value="">Select Category</option>
                    {labCategory.map((cat) => (
                      <option key={cat.categoryName} value={cat.categoryName}>
                        {cat.categoryName}
                      </option>
                    ))}
                  </Input>

                  {errors.find((error) => error.categoryIndex === categoryIndex)
                    ?.categoryErrors?.categoryName && (
                    <div className="text-danger">
                      {
                        errors.find(
                          (error) => error.categoryIndex === categoryIndex
                        ).categoryErrors.categoryName
                      }
                    </div>
                  )}
                </td>
                <td>
                  {" "}
                  <Button
                    color="danger"
                    onClick={() => removeCategory(categoryIndex)}
                  >
                    Delete Category
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <Table>
                    <tbody>
                      {(cat.tests || []).map((test, testIndex) => (
                        <Fragment key={test._id || testIndex}>
                          <thead>
                            <tr>
                              <th>Test Name</th>
                              <th>Results</th>
                              <th>Specimen</th>
                              <th>Reference Range</th>
                              <th>Units</th>
                              <th>Method</th>
                              <th>Notes</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Input
                                  type="select"
                                  placeholder="Test Name"
                                  value={test.testName}
                                  readOnly={!editMode && editData}
                                  disabled={!editMode && editData}
                                  onChange={(e) =>
                                    handleTestChange(
                                      categoryIndex,
                                      testIndex,
                                      "testName",
                                      e.target.value,
                                      cat
                                    )
                                  }
                                >
                                  <option value="">Select Test</option>
                                  {labCategory
                                    .find(
                                      (labcat) =>
                                        labcat.categoryName === cat.categoryName
                                    )
                                    ?.tests.map((test) => (
                                      <option
                                        key={test.testName}
                                        value={test.testName}
                                      >
                                        {test.testName}
                                      </option>
                                    ))}
                                </Input>
                                {errors.find(
                                  (error) =>
                                    error.categoryIndex === categoryIndex
                                )?.categoryErrors?.[`test${testIndex}`] && (
                                  <div className="text-danger">
                                    {
                                      errors.find(
                                        (error) =>
                                          error.categoryIndex === categoryIndex
                                      ).categoryErrors[`test${testIndex}`]
                                    }
                                  </div>
                                )}
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="results"
                                  readOnly={!editMode && editData}
                                  value={test.results}
                                  onChange={(e) =>
                                    handleTestChange(
                                      categoryIndex,
                                      testIndex,
                                      "results",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Specimen"
                                  value={test.specimen}
                                  readOnly={!editMode && editData}
                                  onChange={(e) =>
                                    handleTestChange(
                                      categoryIndex,
                                      testIndex,
                                      "specimen",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Reference Range"
                                  value={test.referenceRange}
                                  readOnly={!editMode && editData}
                                  onChange={(e) =>
                                    handleTestChange(
                                      categoryIndex,
                                      testIndex,
                                      "referenceRange",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Units"
                                  value={test.units}
                                  readOnly={!editMode && editData}
                                  onChange={(e) =>
                                    handleTestChange(
                                      categoryIndex,
                                      testIndex,
                                      "units",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Method"
                                  readOnly={!editMode && editData}
                                  value={test.method}
                                  onChange={(e) =>
                                    handleTestChange(
                                      categoryIndex,
                                      testIndex,
                                      "method",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  type="text"
                                  placeholder="Notes"
                                  value={test.notes}
                                  readOnly={!editMode && editData}
                                  onChange={(e) =>
                                    handleTestChange(
                                      categoryIndex,
                                      testIndex,
                                      "notes",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <Button
                                  color="danger"
                                  onClick={() =>
                                    removeTestRow(categoryIndex, testIndex)
                                  }
                                >
                                  Remove Test
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    addSubTestRow(categoryIndex, testIndex)
                                  }
                                >
                                  Add SubTest
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                          {test.subTests && test.subTests.length > 0 && (
                            <tr>
                              <td colSpan="7">
                                <Table>
                                  <thead>
                                    <tr>
                                      <th>SubTest Name</th>
                                      <th>Results</th>
                                      <th>Specimen</th>
                                      <th>Reference Range</th>
                                      <th>Units</th>
                                      <th>Method</th>
                                      <th>Notes</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {test.subTests.map(
                                      (subTest, subTestIndex) => (
                                        <tr key={subTest._id || subTestIndex}>
                                          <td>
                                            <Input
                                              type="select"
                                              placeholder="SubTest Name"
                                              value={subTest.subTestName}
                                              readOnly={!editMode && editData}
                                              disabled={!editMode && editData}
                                              onChange={(e) =>
                                                handleSubTestChange(
                                                  categoryIndex,
                                                  testIndex,
                                                  subTestIndex,
                                                  "subTestName",
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value="">
                                                Select SubTest
                                              </option>
                                              {labCategory
                                                .find(
                                                  (labcat) =>
                                                    labcat.categoryName ===
                                                    cat.categoryName
                                                )
                                                ?.tests.find(
                                                  (t) =>
                                                    t.testName === test.testName
                                                )
                                                ?.subTests?.map((subTest) => (
                                                  <option
                                                    key={subTest.subTestName}
                                                    value={subTest.subTestName}
                                                  >
                                                    {subTest.subTestName}
                                                  </option>
                                                ))}
                                            </Input>
                                            {errors.find(
                                              (error) =>
                                                error.categoryIndex ===
                                                categoryIndex
                                            )?.categoryErrors?.[
                                              `subTest${testIndex}_${subTestIndex}`
                                            ] && (
                                              <div className="text-danger">
                                                {
                                                  errors.find(
                                                    (error) =>
                                                      error.categoryIndex ===
                                                      categoryIndex
                                                  ).categoryErrors[
                                                    `subTest${testIndex}_${subTestIndex}`
                                                  ]
                                                }
                                              </div>
                                            )}
                                          </td>
                                          <td>
                                            <Input
                                              type="text"
                                              placeholder="Results"
                                              value={subTest.results}
                                              readOnly={!editMode && editData}
                                              onChange={(e) =>
                                                handleSubTestChange(
                                                  categoryIndex,
                                                  testIndex,
                                                  subTestIndex,
                                                  "results",
                                                  e.target.value
                                                )
                                              }
                                            />

                                            {errors.find(
                                              (error) =>
                                                error.categoryIndex ===
                                                categoryIndex
                                            )?.categoryErrors?.[
                                              `subTest${testIndex}_${subTestIndex}`
                                            ] && (
                                              <div className="text-danger">
                                                {
                                                  errors.find(
                                                    (error) =>
                                                      error.categoryIndex ===
                                                      categoryIndex
                                                  ).categoryErrors[
                                                    `subTest${testIndex}_${subTestIndex}`
                                                  ]
                                                }
                                              </div>
                                            )}
                                          </td>
                                          <td>
                                            <Input
                                              type="text"
                                              placeholder="Specimen"
                                              value={subTest.specimen}
                                              readOnly={!editMode && editData}
                                              disabled={!editMode && editData}
                                              onChange={(e) =>
                                                handleSubTestChange(
                                                  categoryIndex,
                                                  testIndex,
                                                  subTestIndex,
                                                  "specimen",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="text"
                                              placeholder="Reference Range"
                                              value={subTest.referenceRange}
                                              readOnly={!editMode && editData}
                                              onChange={(e) =>
                                                handleSubTestChange(
                                                  categoryIndex,
                                                  testIndex,
                                                  subTestIndex,
                                                  "referenceRange",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="text"
                                              placeholder="Units"
                                              value={subTest.units}
                                              readOnly={!editMode && editData}
                                              onChange={(e) =>
                                                handleSubTestChange(
                                                  categoryIndex,
                                                  testIndex,
                                                  subTestIndex,
                                                  "units",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="text"
                                              placeholder="Method"
                                              value={subTest.method}
                                              readOnly={!editMode && editData}
                                              onChange={(e) =>
                                                handleSubTestChange(
                                                  categoryIndex,
                                                  testIndex,
                                                  subTestIndex,
                                                  "method",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Input
                                              type="text"
                                              placeholder="Notes"
                                              value={subTest.notes}
                                              readOnly={!editMode && editData}
                                              onChange={(e) =>
                                                handleSubTestChange(
                                                  categoryIndex,
                                                  testIndex,
                                                  subTestIndex,
                                                  "notes",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <Button
                                              color="danger"
                                              onClick={() =>
                                                removeSubTestRow(
                                                  categoryIndex,
                                                  testIndex,
                                                  subTestIndex
                                                )
                                              }
                                            >
                                              Remove SubTest
                                            </Button>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </Table>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      ))}
                    </tbody>
                  </Table>
                  <Button
                    color="primary"
                    onClick={() => addTestRow(categoryIndex)}
                  >
                    Add Test
                  </Button>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </Table>

      <Row className="mt-3">
        <Col>
          {
            <Button color="primary" onClick={addRow}>
              Add Category
            </Button>
          }
          <Button color="success" className="ml-3" onClick={submitHandler}>
            {editData ? "Edit Save" : "Save"}
          </Button>
          <Button color="success" className="ml-3" onClick={clearHandler}>
            Clear
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={2}>
          <QRCodeWithLogoComponent id={editData._id} sid={editData.SID} />
        </Col>{" "}
        <Col>
          {
            <ReactToPrint
              //   onBeforePrint={handlePrintButtonClick}
              trigger={() => <button className="glass-button">Print</button>}
              content={() => componentRef.current}
            />
          }
        </Col>
      </Row>
      <Row>
        <div style={{ display: "none" }}>
          <PrintReport
            ref={componentRef}
            branch={userData.branch}
            address={address}
            formData={editData}
          />
        </div>
      </Row>
      <Modal
        isOpen={loadingModal}
        toggle={toggleLoadingModal}
        centered
        {...args}
      >
        <ModalHeader toggle={toggleLoadingModal}>Processing </ModalHeader>
        <ModalBody className="text-center">
          <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
          <p className="mt-2">Please wait while your data is being saved...</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleLoadingModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LabCategoryEntry;
