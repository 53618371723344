// PrintBill.js
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, Row, Table } from "reactstrap";
import ci from "../../../../../../images/cutis_new.jpeg";
import { ReactComponent as Rupees } from "../../../../../../images/currency-rupee.svg";
import { numberToWords } from "../../../../CRM-Calicut/utils/numberWords";

const PrintBill = React.forwardRef(
  (
    {
      data,
      address,
      invoice_id,
      date,
      invoiceSheet,
      handleInputChange,
      description,
      total,
      payment_mode,
      setPayment_mode,
      submitHandler,
      navigate,
      branch,
      buttonHide,
      tax,
      netAmountPay,
      GSTIN,
      discount,
      receivedAmt,
      balanceAmount,
      totalQTY,
      totalTax,
      taxableData,
      formData,
      supplier,
    },
    ref
  ) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      //  second: "numeric",
      timeZone: "Asia/Kolkata", // Set the desired time zone (Indian Standard Time)
    };

    const printDate = (
      <span style={{ fontSize: "10px" }}>
        {`${new Date(date).getDate()} / ${
          new Date(date).getMonth() + 1
        } /  ${new Date(date).getFullYear()} `}{" "}
        {new Date(date).toLocaleTimeString("en-US", options)}
      </span>
    );

    const formatDate = (date) => {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    };
    //  useEffect(() => {
    //    if (buttonHide.display === "none") {
    //      setPrintVisible(true);
    //    }
    //  }, [buttonHide]);

    function formatCurrency(amount) {
      return amount.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      });
    }

    return (
      <Container className="p-0 pt-2">
        <div ref={ref} style={{ border: "solid thin black", margin: "40px" }}>
          <Row
            className="d-flex justify-content-start mx-0 ps-2"
            style={{
              borderBottom: "1px solid black",
            }}
          >
            <Col
              md={5}
              sm={5}
              xs={5}
              className="d-flex flex-column align-items-start px-0 mt-2"
            >
              <div className="d-flex align-items-center">
                {
                  //branch && branch === "CLT" &&
                  <img
                    src={ci}
                    alt="Clinic Logo"
                    className="img-fluid"
                    style={{
                      maxWidth: "100%",
                      opacity: "60%",
                    }}
                  />
                }
              </div>

              <div
                className="px-2 ps-5"
                style={{ fontSize: "9px", textAlign: "left" }}
              >
                <b>GSTIN:</b> {GSTIN}
                <br />
                <b>Mobile:</b> +91 8137-900741
              </div>
            </Col>

            <Col
              md={4}
              sm={4}
              xs={4}
              className="pt-2 pe-1 pb-2"
              style={{
                fontSize: "9px",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  color: "rgba(139, 15, 122)",
                  fontSize: "12px",
                  display: "block",
                  marginBottom: "5px",
                }}
              >
                <b>{address.first_line}</b>
              </span>
              <span style={{ fontSize: "9px" }}>{address.second_line}</span>
              <br />
              <span style={{ fontSize: "9px" }}>
                {address.third_line}, {address.fourth_line}
              </span>
            </Col>

            <Col
              md={3}
              sm={3}
              xs={3}
              className="pt-3 ms-0"
              style={{
                fontSize: "9px",

                overflow: "hidden",
                textAlign: "left",
              }}
            >
              <b>TAX INVOICE</b>
              <div
                className=" d-inline-block"
                style={{
                  display: "inline-block",
                  border: "1px solid grey",
                  color: "grey",
                  marginTop: "5px",
                }}
              >
                ORIGINAL FOR RECIPIENT
              </div>
              <br />
              Invoice No: {invoice_id}
              <br />
              <div style={{ display: "inline-block", whiteSpace: "nowrap" }}>
                Date: {printDate}
              </div>
            </Col>
          </Row>

          <Row
            className="pt-3 text-md ps-5 d-flex justify-content-between"
            style={{ fontSize: "11px" }}
          >
            <Col sm={5} xs={5} style={{ textAlign: "left" }}>
              <b>BILL TO: {supplier?.supplierName}</b> <br />
              Contact No:{" "}
            </Col>

            <Col sm={4} xs={4} style={{ textAlign: "left" }}>
              <b>SHIP TO:</b>{" "}
            </Col>
          </Row>

          <Row className="d-flex justify-content-center">
            <Col>
              <Table bordered>
                <thead>
                  <tr
                    style={{
                      fontSize: "11px",

                      //  opacity: "70%",
                    }}
                  >
                    <th
                      style={{
                        width: "8%",
                        textAlign: "center",
                        border: "none",

                        backgroundColor: "#D8D6D6",
                      }}
                    >
                      SL No.
                    </th>
                    <th
                      style={{
                        width: "40%",
                        textAlign: "center",
                        border: "none",

                        backgroundColor: "#D8D6D6",
                      }}
                    >
                      ITEM/SERVICES
                    </th>
                    <th
                      style={{
                        width: "10%",
                        textAlign: "center",
                        border: "none",

                        backgroundColor: "#D8D6D6",
                      }}
                    >
                      HSN
                    </th>

                    <th
                      style={{
                        width: "15%",
                        border: "none",

                        backgroundColor: "#D8D6D6",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      QTY
                    </th>

                    <th
                      style={{
                        width: "10%",
                        border: "none",

                        backgroundColor: "#D8D6D6",
                        textAlign: "center",
                      }}
                    >
                      Unit Price
                    </th>
                    <th
                      style={{
                        width: "10%",
                        border: "none",

                        backgroundColor: "#D8D6D6",
                        textAlign: "center",
                      }}
                    >
                      Tax
                    </th>
                    <th
                      style={{
                        width: "17%",
                        border: "none",

                        backgroundColor: "#D8D6D6",
                        textAlign: "center",
                      }}
                    >
                      MRP
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceSheet &&
                    invoiceSheet.map((row, index) => {
                      const taxper = (
                        parseFloat(row[`Row${index + 1}`]?.cgst) +
                        parseFloat(row[`Row${index + 1}`]?.sgst)
                      ).toFixed(2);
                      console.log(row);
                      return (
                        <tr
                          key={index}
                          style={{
                            borderTop: "none",
                            borderBottom: "none",
                            fontSize: "11px",
                          }}
                        >
                          <td style={{ textAlign: "center" }}>
                            {row[`Row${index + 1}`]?.item && index + 1}{" "}
                          </td>
                          <td>{row[`Row${index + 1}`]?.item}</td>

                          <td style={{ textAlign: "center" }}>
                            {" "}
                            {row[`Row${index + 1}`]?.hsn
                              ? row[`Row${index + 1}`].hsn
                              : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {row[`Row${index + 1}`]?.quantity
                              ? row[`Row${index + 1}`].quantity
                              : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {row[`Row${index + 1}`]?.unitPrice
                              ? row[`Row${index + 1}`].unitPrice
                              : ""}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {row[`Row${index + 1}`]?.tax ? (
                              <span>
                                {row[`Row${index + 1}`].tax}% <br />
                              </span>
                            ) : (
                              ""
                            )}{" "}
                          </td>
                          <td>
                            {row[`Row${index + 1}`]?.mrp
                              ? row[`Row${index + 1}`].mrp
                              : ""}
                          </td>
                        </tr>
                      );
                    })}
                  <tr scope="row" style={{ fontSize: "11px" }}>
                    <td
                      colSpan={2}
                      style={{
                        backgroundColor: "#D8D6D6",
                        border: "none",
                      }}
                    ></td>
                    <td
                      style={{
                        textAlign: "end",
                        fontWeight: "bold",
                        paddingTop: "10px",

                        backgroundColor: "#D8D6D6",
                        border: "none",
                      }}
                    >
                      TOTAL
                    </td>
                    <td
                      style={{
                        textAlign: "center",

                        backgroundColor: "#D8D6D6",
                        border: "none",
                      }}
                    >
                      {totalQTY}
                    </td>
                    <td
                      style={{
                        backgroundColor: "#D8D6D6",
                        border: "none",
                      }}
                    ></td>
                    <td
                      style={{
                        textAlign: "center",

                        backgroundColor: "#D8D6D6",
                        border: "none",
                      }}
                    >
                      {" "}
                      <Rupees style={{ maxWidth: "11px", maxHeight: "11px" }} />
                      {totalTax}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        backgroundColor: "#D8D6D6",
                        border: "none",
                      }}
                    >
                      {" "}
                      {
                        //  formatCurrency(total)
                      }
                    </td>
                  </tr>

                  {
                    //  <tr scope="row" style={{ fontSize: "11px" }}>
                    //     <td
                    //       colSpan={6}
                    //       style={{
                    //         textAlign: "right",
                    //         fontWeight: "bold",
                    //         paddingTop: "10px",
                    //       }}
                    //     ></td>
                    //     <td style={{ textAlign: "right" }}></td>
                    //   </tr>
                    //
                    // <tr scope="row" style={{ fontSize: "11px" }}>
                    //    <td
                    //      colSpan={6}
                    //      style={{
                    //        textAlign: "right",
                    //        fontWeight: "bold",
                    //        paddingTop: "10px",
                    //      }}
                    //    ></td>
                    //    <td style={{ textAlign: "right" }}>
                    //      {" "}
                    //      {
                    //        //formatCurrency(netAmountPay)
                    //      }
                    //    </td>
                    //  </tr>
                  }
                  <tr style={{ fontSize: "11px" }}>
                    <td
                      colSpan={6}
                      style={{
                        textAlign: "right",
                        fontWeight: "bold",
                        paddingTop: "10px",
                      }}
                    >
                      Credit Amount
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <Rupees style={{ maxWidth: "11px", maxHeight: "11px" }} />
                      {data?.additionalDetails?.credit !== NaN
                        ? data?.additionalDetails?.credit
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
);

export default PrintBill;
