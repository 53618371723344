import React from "react";
import { Container, Row, Col } from "reactstrap";
const Header = ({ children }) => {
  return (
    <header
      className="pt-3 fixed-top"
      style={{
        backgroundColor: "rgba(220, 220, 220, 0.5)",
        backdropFilter: "blur(10px)",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Container>
        <div className="d-flex">{children}</div>
      </Container>
    </header>
  );
};

export default Header;
