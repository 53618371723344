import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { PrintComponent } from "./PrintComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
const Print = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <div
      // style={{ display: "none" }} // This make ComponentToPrint show   only while printing
      >
        <PrintComponent ref={componentRef} data={data} />
      </div>
      <Container>
        <Row className="d-flex justify-content-between pt-2 pb-3 mb-3 px-3">
          <Col sm={2}>
            <Button onClick={() => navigate(-1)} color="primary">
              Back
            </Button>
          </Col>
          <Col sm={2}>
            <Button onClick={handlePrint} color="primary">
              Print
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Print;
